import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Helpers, Loading } from "../../components";
import { Teachers, Static } from "../../services";
import { MDBDataTable } from "mdbreact";
import { FormattedMessage } from "react-intl";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ReactPaginate from "react-paginate";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { MaintenanceContext } from '../../Maintenance';
moment.locale("id");

const statics = new Static();
const teachers = new Teachers();
const helpers = new Helpers();

export default function Teacher(props) {
  useEffect(() => {
    (async () => getTeacherList())();
  }, []);

  const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

  const [to, setTo] = useState(0);
  const user = JSON.parse(window.localStorage.getItem("user-data"));
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    id: "",
    name: "",
    nik: "",
    date_of_birth: new Date(),
    created_by: user.username,
    updated_by: user.username,
    file: null,
  });
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 5,
    search: "",
    total: 0,
    totalPage: 1,
  });

  const [teacherList, setTeacherList] = useState([]);
  const [mode, setMode] = useState("Add");

  const getTeacherList = () => {
    setLoading(true);

    teachers
      .list(pagination)
      .then((res) => {
        console.log(res);
        pagination.limit = res.limit;
        pagination.total = res.total;
        pagination.totalPage =
          res.total % res.limit === 0
            ? res.total / res.limit
            : Math.floor(res.total / res.limit) + 1;

        res.result.map((item, i) => {
          let nums = parseInt(pagination.total - pagination.limit);
          let numRows = (pagination.page - 1) * (pagination.total - nums) + i;

          res.result[i].no = numRows + 1;
          res.result[i].date_of_birth = helpers.formatDate(
            item.date_of_birth,
            "LL"
          );
          res.result[i].option = (
            <>
              <button
                className="btn btn-warning btn-sm mr-1"
                data-toggle="modal"
                data-target="#addModal"
                onClick={() => getTeacherInfo(item)}
              >
                <i className="lnr lnr-pencil"></i>
                <FormattedMessage id="component.edit.button" />
              </button>
              <button
                className="btn btn-danger btn-sm"
                data-toggle="modal"
                data-target="#deleteModal"
                onClick={() => getTeacherInfo(item)}
              >
                <i className="lnr lnr-trash"></i>
                <FormattedMessage id="component.delete.button" />
              </button>
            </>
          );
          res.result[i].created_at = helpers.formatDate(item.created_at);
        });

        setPagination(pagination);
        setTeacherList(res.result);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);

        if (err.code === 404) {
          setTeacherList([]);
          setPagination({
            page: 0,
            limit: 0,
            search: '',
            total: 0,
            totalPage: 1
          });
        }

        if (err.status === 503) {
          setIsMaintenance(true)
        }
        setLoading(false);
      });
  };

  const handleDownloadTemplate = () => {
    statics.getImportTemplate("teachers.csv");
  };

  const handlePayload = (e, state = "name") => {
    let value = "";
    switch (state) {
      case "date_of_birth":
        value = moment(e._d).format("YYYY-MM-DD");
        break;
      case "file":
        value = e.target.files[0];
        break;
      default:
        value = e.target.value;
        break;
    }
    setPayload({
      ...payload,
      [state]: value,
    });
  };

  const createTeacher = () => {
    setLoading(true);
    teachers
      .create(payload)
      .then((res) => {
        if (res.code === 201) {
          document.getElementById("addModal").click();
          toast.success(
            <FormattedMessage id="component.text.add-successful" />
          );

          reset();

          getTeacherList();
        }
      })
      .catch((err) => {
        toast.error(
          <div>
            {<FormattedMessage id="component.text.add-failed" />}
            <br />
            <br />
            {JSON.stringify(err)}
          </div>
        );
        setLoading(false);
      });
  };

  const updateTeacher = () => {
    setLoading(true);
    teachers
      .update(payload)
      .then((res) => {
        if (res.status.code === 200) {
          document.getElementById("addModal").click();
          toast.success(
            <FormattedMessage id="component.text.edit-successful" />
          );

          reset();
          getTeacherList();
        }
      })
      .catch((err) => {
        toast.error(
          <div>
            {<FormattedMessage id="component.text.edit-failed" />}
            <br />
            <br />
            {JSON.stringify(err)}
          </div>
        );
        setLoading(false);
      });
  };

  const deleteTeacher = () => {
    setLoading(true);
    teachers
      .delete(payload.id)
      .then((res) => {
        if (res.status.code === 200) {
          document.getElementById("deleteModal").click();
          toast.success(
            <FormattedMessage id="component.text.delete-successful" />
          );

          reset();
          getTeacherList();
        }
      })
      .catch((err) => {
        toast.error(
          <div>
            {<FormattedMessage id="component.text.delete-failed" />}
            <br />
            <br />
            {JSON.stringify(err)}
          </div>
        );
        setLoading(false);
      });
  };

  const getTeacherInfo = (teacherInfo) => {
    setPayload({
      ...payload,
      id: teacherInfo.id,
      name: teacherInfo.name,
      nik: teacherInfo.nik,
      date_of_birth: teacherInfo.date_of_birth,
      updated_by: payload.created_by,
    });
    setMode("Edit");
  };

  const reset = () => {
    setPayload({
      ...payload,
      name: "",
      nik: "",
      date_of_birth: new Date(),
      file: null,
    });
  };

  // handle pagination
  const _handleChangePage = (e) => {
    const { selected } = e;
    pagination.page = selected + 1;

    setPagination(pagination);
    getTeacherList();
  };

  const renderModalTitle = (mode) => {
    switch (mode) {
      case "Add":
        return <FormattedMessage id="component.add.button" />;
      case "Edit":
        return <FormattedMessage id="component.edit.button" />;
      default:
        return "Import";
    }
  };

  const renderModalSubmit = (mode) => {
    switch (mode) {
      case "Add":
        createTeacher();
        break;
      case "Edit":
        updateTeacher();
        break;
      default:
        importFile();
        break;
    }
  };

  const importFile = () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("file", payload.file);
    formData.append("created_by", payload.created_by);

    teachers
      .xxx(formData)
      .then((res) => {
        if (res.code === 201) {
          document.getElementById("addModal").click();
          toast.success(
            <FormattedMessage id="component.text.add-successful" />
          );

          reset();
          getTeacherList();
        }
      })
      .catch((err) => {
        toast.error(
          <div>
            {<FormattedMessage id="component.text.add-failed" />}
            <br />
            <br />
            {JSON.stringify(err)}
          </div>
        );
        setLoading(false);
      });
  };

  const _handleSearch = (v) => {
    if (to) {
      clearTimeout(to);
    }
    setTo(
      setTimeout(() => {
        pagination.search = v;
        setPagination(pagination);
        getTeacherList();
      }, 500)
    );
  };
  const data = {
    columns: [
      {
        label: <FormattedMessage id="form.number" defaultMessage="No" />,
        field: "no",
        sort: "asc",
        width: 150,
      },
      {
        label: <FormattedMessage id="form.teacher.name" />,
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: <FormattedMessage id="form.teacher.nik" />,
        field: "nik",
        sort: "asc",
        width: 270,
      },
      {
        label: <FormattedMessage id="form.teacher.date_of_birth" />,
        field: "date_of_birth",
        sort: "asc",
        width: 270,
      },
      {
        label: <FormattedMessage id="s" defaultMessage="Teacher code" />,
        field: "code",
        sort: "asc",
        width: 270,
      },
      {
        label: <FormattedMessage id="form.created_at" />,
        field: "created_at",
        sort: "asc",
        width: 200,
      },
      {
        label: <FormattedMessage id="form.options" />,
        field: "option",
        sort: "asc",
        width: 100,
      },
    ],
    rows: teacherList,
  };

  const { page, totalPage } = pagination;
  return (
    <div className="main">
      <ToastContainer autoClose={5000} hideProgressBar pauseOnHover={false} />
      <div className="main-content">
        {user.role === "Admin" && (
          <div className="container-fluid">
            {ismaintenance ? (
    <div className="text-center">
        <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
        <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
    </div>
)  : (
              <>
            <h3 className="page-title">
              <FormattedMessage id="menu.teacher" />
            </h3>
            <div className="row">
              <div className="col-md-12">
                <div className="panel">
                  <div className="panel-header mt-2 mr-2">
                    <div className="row">
                      <div className="col-md-6"></div>

                      <div className="col-md-6">
                        <button
                          className="btn btn-primary pr-3"
                          data-toggle="modal"
                          data-target="#addModal"
                          style={{ float: "right" }}
                          onClick={() => setMode("Add")}
                        >
                          <FormattedMessage id="component.add.button" />
                        </button>
                        <button
                          className="btn btn-success mr-2"
                          data-toggle="modal"
                          data-target="#addModal"
                          style={{ float: "right" }}
                          onClick={() => setMode("Import")}
                        >
                          {" "}
                          Import
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">
                    <Loading visible={loading} />
                    <div className="row mt-4">
                      <div className="col-md-3" style={{ float: "right" }}>
                        Search:{" "}
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => _handleSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <MDBDataTable
                      searching={false}
                      // onSearch={(e) => console.warn(e)}
                      className="table mt-5"
                      data={data}
                      sortable
                      noBottomColumns
                      entriesOptions={[5, 10, 20, 50]}
                      displayEntries={true}
                      paging={false}
                      noRecordsFoundLabel={
                        <FormattedMessage id="component.text.no-records" />
                      }
                    />
                    <ReactPaginate
                      // className="d-flex justify-content-center"
                      breakLabel={"..."}
                      pageCount={totalPage}
                      marginPagesDisplayed={1}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      initialPage={page}
                      onPageChange={_handleChangePage}
                    />
                  </div>
                </div>
              </div>
            </div>
            </>
              )}
          </div>
        )}
        </div>
      <Modal
        title={renderModalTitle(mode)}
        label={mode}
        form={
          mode === "Import" ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  <a
                    href={`${process.env.REACT_APP_URL_SERVICE}/static/teachers.csv`}
                    download
                    className="btn btn-success"
                    style={{ float: "left" }}
                  >
                    Download template
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-2">
                  <label className="fom-label">
                    <FormattedMessage id="component.text.choose-file" />
                  </label>
                  <input
                    required
                    type="file"
                    className="form-control"
                    accept=".csv"
                    onChange={(e) => handlePayload(e, "file")}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="required">
                    <FormattedMessage id="form.teacher.name" />
                  </label>
                  <input
                    type="text"
                    value={payload.name}
                    className="form-control"
                    required
                    onChange={(e) => handlePayload(e)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="required">
                    <FormattedMessage id="form.teacher.nik" />
                  </label>
                  <input
                    type="text"
                    value={payload.nik}
                    className="form-control"
                    required
                    onChange={(e) => handlePayload(e, "nik")}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="required">
                    <FormattedMessage id="form.teacher.date_of_birth" />
                  </label>
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    libInstance={moment}
                    className="form-control"
                  >
                    <DatePicker
                      fullWidth
                      disableFuture={true}
                      clearable={true}
                      inputVariant="outlined"
                      value={payload.date_of_birth}
                      autoOk={true}
                      format="Do MMM yyyy"
                      required
                      onChange={(e) => handlePayload(e, "date_of_birth")}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          )
        }
        onSubmit={(e) => {
          e.preventDefault();
          renderModalSubmit(mode);
        }}
        onClose={() => reset()}
        onDelete={() => deleteTeacher()}
        disabled={loading}
      />
    </div>
  );
}
