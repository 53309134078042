export default {
    'menu.teacher': 'Teacher Management',
    'menu.student': 'Student Management',
    'menu.classroom': 'Class Management',
    'menu.session': 'Session Management',
    'menu.subject': 'Subject Management',
    'menu.religion': 'Religion Management',
    'menu.user': 'User Management',
    'menu.configuration': 'Configuration',
    'menu.personality': 'Personality',
    'menu.language': 'Language',
    'menu.change-password': 'Change Password',
    'menu.logout': 'Logout',
    // Wali kelas
    'menu.personality-mark': 'Personality Marks',
    'menu.student-report': 'Student Report',
    // Guru
    'menu.input-score': 'Input Score',
    'menu.attendance': 'Attendance',
    'menu.termly-result': 'Termly Result',
    // Siswa
    'menu.my-report': 'My Report',
    'menu.backup': 'Backup',
    
    'menu.principal-configur': 'Principal Configuration',
}