import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from "react-intl";

export default class Sidebar extends Component {
    
    renderElement(){
        const user = JSON.parse(window.localStorage.getItem('user-data'));
        if(user.role === 'Admin'){
           return <>
                        <li><NavLink to="/dashboard-admin" activeClassName="active"><i className="lnr lnr-home" /> <span>Dashboard</span></NavLink></li>
                        <li><NavLink to="/teacher" activeClassName="active"><i className="lnr lnr-user" /> <span><FormattedMessage id="menu.teacher"/></span></NavLink></li>
                        <li><NavLink to="/student" activeClassName="active"><i className="lnr lnr-users" /> <span><FormattedMessage id="menu.student"/></span></NavLink></li>
                        <li><NavLink to="/classes" activeClassName="active"><i className="lnr lnr-apartment" /> <span><FormattedMessage id="menu.classroom"/></span></NavLink></li>
                        <li><NavLink to="/session" activeClassName="active"><i className="lnr lnr-calendar-full" /> <span><FormattedMessage id="menu.session"/></span></NavLink></li>
                        <li><NavLink to="/subject" activeClassName="active"><i className="lnr lnr-list" /> <span><FormattedMessage id="menu.subject"/></span></NavLink></li>
                        <li><NavLink to="/religion" activeClassName="active"><i className="lnr lnr-frame-contract" /> <span><FormattedMessage id="menu.religion" defaultMessage="Religion Management"/></span></NavLink></li>
                        <li><NavLink to="/users" activeClassName="active"><i className="lnr lnr-users" /> <span><FormattedMessage id="menu.user"/></span></NavLink></li>
                        <li><NavLink to="/configuration" activeClassName="active"><i className="lnr lnr-cog" /> <span><FormattedMessage id="menu.configuration"/></span></NavLink></li>
                        <li><NavLink to="/personality" activeClassName="active"><i className="lnr lnr-user" /> <span><FormattedMessage id="menu.personality"/></span></NavLink></li>
           </>
        }
        else if(user.role === 'Guru Mapel') {
            return <>
                        <li><NavLink to="/dashboard-teacher" activeClassName="active"><i className="lnr lnr-home" /> <span>Dashboard</span></NavLink></li>
                        <li>
							<a href="#subPages" data-toggle="collapse" className="collapsed"><i className="lnr lnr-pencil"></i> <span>Input Score</span> <i className="icon-submenu lnr lnr-chevron-left"></i></a>
							<div id="subPages" className="collapse ">
								<ul className="nav">
                                    <li><NavLink to="/scoring" activeClassName="active collapsed"><i className="lnr lnr-file-empty" /> <span>Scoring</span></NavLink></li>
								</ul>
							</div>
						</li>
            </>
        }
        else if(user.role === 'Wali Kelas') {
            return <>
                     <li><NavLink to="/dashboard-homeroom" activeClassName="active"><i className="lnr lnr-home" /> <span>Dashboard</span></NavLink></li>
                     <li>
							<a href="#subPages" data-toggle="collapse" className="collapsed"><i className="lnr lnr-pencil"></i> <span>Input Score</span> <i className="icon-submenu lnr lnr-chevron-left"></i></a>
							<div id="subPages" className="collapse ">
								<ul className="nav">
                                    <li><NavLink to="/scoring" activeClassName="active collapsed"><i className="lnr lnr-file-empty" /> <span>Scoring</span></NavLink></li>
                                    <li><NavLink to="/personality-mark" activeClassName="active collapsed"><i className="lnr lnr-users" /> <span>Termly</span></NavLink></li>
                                    <li><NavLink to="/student-report" activeClassName="active collapsed"><i className="lnr lnr-users" /> <span>Student Report</span></NavLink></li>
								</ul>
							</div>
                     </li>
            </>
        }else if((user.role === 'Siswa' || user.role === 'Orang Tua')) {
            return <>
                     <li><NavLink to="/dashboard-student" activeClassName="active"><i className="lnr lnr-home" /> <span>Dashboard</span></NavLink></li>  
                     <li><NavLink to="/my-report" activeClassName="active"><i className="lnr lnr-pencil" /> <span>{user.role === 'Siswa' ? 'My Report' : 'My Student Report'}</span></NavLink></li>                     
                </>
        }else {
            return 'Null'
        }
     }
    render() {
         
         
        return (
                <div id="sidebar-nav" className="sidebar">
                    <div className="sidebar-scroll">
                        <nav>
                        <ul className="nav">     
                            { this.renderElement() }
                        </ul>
                        </nav>
                    </div>
                </div>
        )
    }
}
