const TYPE = {
    USER_ROLE: [
        {
            label: 'Admin',
            value: 'Admin'
        },
        {
            label: 'Teacher',
            value: 'Guru Mapel'
        },
        {
            label: 'Teacher Class',
            value: 'Wali Kelas'
        },
        {
            label: 'Student',
            value: 'Siswa'
        },
        {
            label: 'Parent',
            value: 'Orang Tua'
        }
    ],
    SCORE_LIST: [
        {
            label: 'Never/Rarely',
            value: 1
        },
        {
            label: 'Sometimes',
            value: 2
        },
        {
            label: 'Most of Times',
            value: 3
        },
        {
            label: 'Always',
            value: 4
        }
    ],
    EXAM_LIST: [
        {
            prop: 'mid_term',
            label: 'Mid term',
            value: 2
        },
        {
            prop: 'end_term',
            label: 'End term',
            value: 3,
        },
        {
            prop: 'grade',
            label: 'Grade',
            value: 5,
        },
    ],
    TERM_LIST: [
        {
            label: '1',
            value: 1
        },
        {
            label: '2',
            value: 2
        }
    ],
    FINAL_RESULT: [
        {
            key: 'promoted_to',
            label: 'Promoted To',            
            value: 1
        },
        {
            key: 'detained_in',
            label: 'Detained In',            
            value: 3
        },
    ],
    CONFIGURATION: {
        SETTING_PRINCIPAL: 'a7a887dc-ccc6-4c4f-a441-ae7a677cea3a'
    }
};

export default TYPE;
