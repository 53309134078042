import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { Students, Classrooms, Sessions, ReportHistory } from '../../services';
import { FormattedMessage } from "react-intl";
import { Loading } from '../../components';
import { MaintenanceContext } from '../../Maintenance';


import 'react-toastify/dist/ReactToastify.css';

const students = new Students();
const classrooms = new Classrooms();
const sessions = new Sessions();
const reportHistory = new ReportHistory();

export default function MyReport(props) {
    useEffect(() => {
        (async () => getDetailStudent())();
        (async () => getSessionList())();
        
    }, []);
    
    const user = JSON.parse(window.localStorage.getItem('user-data'));
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

    const [loadingStudent, setLoadingStudent] = useState(false);
    const [loadingSession, setLoadingSession] = useState(false);

    const [studentList, setStudentList] = useState([]);
    const [tmpStudentList, setTmpStudentList] = useState([]);
    const [sessionList, setSessionList] = useState([]);

    const [term, setTerm] = useState('3');
    const [session, setSession] = useState('');
    const [reportHistoryID, setReportHistoryID] = useState('');

    const getDetailStudent = () => {
        const studentCode = user.role === 'Siswa' ? user.username : user.student_data.code;    

        students.detail(studentCode)
        .then(res => {
            console.log(res);

            const classroomID = res.result.classroom.id;
            getStudentByClassroom(classroomID, studentCode);
        })
        .catch(err => {
            console.error(err);
            if (err.status === 503) {
                setIsMaintenance(true)
            }
        })
    }
    const getStudentByClassroom = (classroomId, studentCode) => {
        setLoadingStudent(true);
        students.getStudentByClassroom(classroomId)
        .then(res => {
            console.log(res);            
            const student = res.result.filter(s => s.code === studentCode);
            console.warn('studnet: ', student);
            setStudentList(student);
            setTmpStudentList(student);
            setLoadingStudent(false);
        })
        .catch(err => {
            console.error(err);

            if (err.code === 404) {
                setStudentList([]);
            }
            setLoadingStudent(false);
        });
    };
    const getSessionList = () => {
        setLoadingSession(true);
        sessions.list({ limit: 100 })
        .then(res => {
            console.log(res);
            const session = res.result.slice(0, -1);

            setSessionList(session);
            setLoadingSession(false);
        })
        .catch(err => {
            console.error(err);

            if (err.code === 404) {
                setStudentList([]);
            }
            if (err.status === 503) {
                setIsMaintenance(true)
            }
            setLoadingSession(false);
        });
    };
    
    
    const doPrint = (url, type = 'mark') => {
        let history = session !== '' ? `?history=${reportHistoryID}` : '';
        
        if (type === 'personality') {
            window.open(`${url}/term/${term}${history}`);
        }
        if (term === '3' || term === '4') {
            window.open(`${url}/mid-term/${term}${history}`);
        } else if (term === '1') {
            window.open(`${url}/term/${term}${history}`);

        }
        window.open(`${url}/${term}${history}`);
    }

    const _handleChangeSession = (s) => {
        setSession(s);
        if (s === '') {
            setStudentList(tmpStudentList);
            return
        }

        setLoadingStudent(true);
        reportHistory.studentList(user.student_data.id, s)
        .then(res => {
            console.log('report history: ', res);

            setReportHistoryID(res.result.id);
            setStudentList(tmpStudentList);
            setLoadingStudent(false);
        })
        .catch(err => {
            console.error(err);

            setLoadingStudent(false);
            setStudentList([]);
        })
        

    }

    return (
        <div className="main">
            <div className="main-content">
                {(user.role === 'Siswa' || user.role === 'Orang Tua') && (
                    
                <div className="container-fluid">
                    {ismaintenance ? (
    <div className="text-center">
        <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
        <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
    </div>
)  : (
                    <>
                    <h3 className="page-title">
                        <FormattedMessage id="menu.my-report"/>
                    </h3>
                    <div className="row">        
                        <div className="col-md-3">
                            <div className="form-group">
                                <p style={{textAlign : 'left', fontWeight: '700', fontSize : '15'}}>
                                    <FormattedMessage id="component.text.session"/>: 
                                </p>
                                <div className="form-group">
                                    <select 
                                        className="form-control"                                    
                                        onChange={(e) => {
                                            _handleChangeSession(e.target.value);
                                        }} >
                                        <option value="">Now</option>
                                        {
                                            loadingSession
                                            ? <option value="" disabled>Waiting for data...</option>
                                            : sessionList.length > 0 && sessionList.map((item, index) => (
                                                <option value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>    
                            </div>
                        </div>                
                        <div className="col-md-3">
                            <p style={{textAlign : 'left', fontWeight: '700', fontSize : '15'}}>
                                <FormattedMessage id="component.text.term"/>: 
                            </p>                           
                            <div className="form-group">
                                <select 
                                    className="form-control"                                    
                                    onChange={(e) => {
                                        setTerm(e.target.value)
                                    }} >
                                    <FormattedMessage id="component.text.mid-term">
                                        {(message) => <option value='3'>{message} 1</option>}
                                    </FormattedMessage>
                                    <FormattedMessage id="component.text.term">
                                        {(message) => <option value='1'>{message} 1</option>}
                                    </FormattedMessage>
                                    <FormattedMessage id="component.text.mid-term">
                                        {(message) => <option value='4'>{message} 2</option>}
                                    </FormattedMessage>
                                    <FormattedMessage id="component.text.term">
                                        {(message) => <option value='2'>{message} 2</option>}
                                    </FormattedMessage>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="panel">
                                    
                                <div className="panel-body">    
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th><FormattedMessage id='form.number' defaultMessage='No'/></th>
                                                <th><FormattedMessage id='form.student.name'/></th>
                                                <th><FormattedMessage id='form.student.address'/></th>
                                                <th><FormattedMessage id='form.classroom.name'/></th>
                                                <th><FormattedMessage id='component.text.assessment'/></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loadingStudent && (<tr><td colspan="5"><Loading visible={loadingStudent}/>   </td></tr>)}
                                            {!loadingStudent && studentList && studentList.length > 0 && studentList.map((student, i) => (
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{student.name}</td>
                                                    <td>{student.address}</td>
                                                    <td>{student.classroom.name}</td>
                                                    <td>
                                                    <button 
                                                        className="btn btn-info btn-sm mr-1" 
                                                        onClick={() => doPrint(`/student-report/subject/${student.id}`)}> 
                                                            <FormattedMessage id="component.text.assessment-subject"/>
                                                    </button>   
                                                    {(term === '1' || term === '2') && (
                                                        <button 
                                                            className="btn btn-primary btn-sm mr-1" 
                                                            onClick={() => doPrint(`/student-report/personality/${student.id}`, 'personality')}> 
                                                                <FormattedMessage id="component.text.assessment-personality"/>
                                                        </button>     
                                                    )}  
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>   
                        </div>
                    </div>
                    </> 
                    )}
                </div>
                    )}
            </div>
        </div>
    )
}
