import React, {useContext} from 'react';
import { MaintenanceContext } from '../../Maintenance';
import { FormattedMessage } from "react-intl";

export default function Dashboard(props) {
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);
    const user = JSON.parse(window.localStorage.getItem('user-data'));
    console.log("maintenance", ismaintenance)
    return (
        <div>
            
            <div className="main">
                    
                    <div className="main-content">
                {user.role === 'Admin' ? (
                    <div className="container-fluid">
                            {(
                            <>
                            <div className="panel panel-profile">
                                <div className="clearfix">
                                    <div className="profile-left">
                                        <div className="profile-header">
                                            <div className="overlay" />
                                                <div className="profile-main">
                                                    <img src="assets/img/user.png" className="img-circle" alt="Avatar" />
                                                    <h3 className="name">{user.username}</h3>
                                                    <span className="online-status status-available">{user.username} ({user.role})</span>
                                                </div>
                                                <div className="profile-detail">
                                                    <div className="profile-info">
                                                    
                                                    </div>
                                                </div>
                        
                                            </div>
                                        </div>
                                    <div className="profile-right">
                                        <h4 className="heading text-center"><FormattedMessage id='component.text.information'/></h4>
                                        {/* AWARDS */}
                                        <div className="awards">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="award-item">
                                                        <div className="hexagon">
                                                        <span className="lnr lnr-user award-icon" />
                                                        </div>
                                                        <span><FormattedMessage id="menu.teacher"/></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="award-item">
                                                        <div className="hexagon">
                                                        <span className="lnr lnr-users award-icon" />
                                                        </div>
                                                        <span><FormattedMessage id="menu.student"/></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="award-item">
                                                        <div className="hexagon">
                                                        <span className="lnr lnr-apartment award-icon" />
                                                        </div>
                                                        <span><FormattedMessage id="menu.classroom"/></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="award-item">
                                                        <div className="hexagon">
                                                        <span className="lnr lnr-list award-icon" />
                                                        </div>
                                                        <span><FormattedMessage id="menu.subject"/></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* END AWARDS */}
                                    </div>
                                        {/* END RIGHT COLUMN */}
                                </div>
                            </div>
                            </> 
                            )}
                        </div>
                ) : null }
                    </div>
            {/* END MAIN CONTENT */}
            </div>
        </div>
    )
}
