import { LOCALES } from '../constants';
import pages from './id-ID/pages';
import component from './id-ID/component';
import menu from './id-ID/menu';
import form from './id-ID/form';

export default {
  [LOCALES.INDONESIAN]: {
    'hello': 'Hello',
    ...pages,
    ...component,
    ...menu,
    ...form
  },
};
