export default {
    // general
    'form.options': 'Options',
    'form.created_at': 'Created At',
    'form.number': 'No',
    // teacher
    'form.teacher.name': 'Teacher Name',
    'form.teacher.nik': 'NIK',
    'form.teacher.date_of_birth': 'Date of birth',
    // student
    'form.student.name': 'Student Name',
    'form.student.address': 'Address',
    'form.student.classroom': 'Classroom',
    'form.student.roll_no': 'Roll no',
    'form.student.gender': 'Gender',
    'form.student.gender.male': 'Male',
    'form.student.gender.female': 'Female',
    'form.student.telp_mob': 'Telp Mob',
    'form.student.telp_res': 'Telp res',
    'form.student.house': 'House',
    'form.student.code': 'Student Code',
    'form.parent.code': 'Parent Code',
    'form.parent.name': 'Parent Name',
    'form.student-access.start': 'Access Start',
    'form.student-access.end': 'Access End',
    
    // classroom
    'form.classroom.name': 'Classroom Name',
    'form.classroom.total-student': 'Student Total',
    // session
    'form.session.name': 'Session Name',
    // subject
    'form.subject.name': 'Subject Name',
    'form.subject.list': 'Subject List',
    'form.subject.setting': 'Subject settings',
    // user
    'form.user.activate': 'Activate',
    'form.user.deactivate': 'Deavtivate',
    'form.user.active': 'Active',
    'form.user.deactive': 'Deactive',
    'form.user.role': 'Role',
    'form.user.new-password': 'New Password',
    // configuration
    'form.configuration.input-score': 'Set a date for entering test scores',
    'form.configuration.from': 'From',
    'form.configuration.finish': 'To',
    // personality
    'form.personality': 'Personality',
    'form.personality.score': 'Score',
    'form.personality.current.score': 'Current Score',
    // personality mark
    'form.personalityMark.attendance': 'Attendance',
    'form.personalityMark.maxattendance': 'Max Attendance',
    'form.personalityMark.number': 'No',
    'form.personalityMark.attendance-count': 'Attendance Count',
    // religion
    'form.religion.name': 'Religion Name',
    'form.override.subject.name': 'Subject Display Name',

    'form.classComment': 'Class Teacher Comment',
    'form.exculComment': 'Co Curricular Activities',
    'form.termly.session': 'Session',
    
    'form.finalResult': 'Final Result Score',
    'form.to': 'To',
    
    'form.promotedTo': 'Promoted To',
    'form.compartmentIn': 'Compartment In',
    'form.detainedIn': 'Detained In',

        
    'form.person-heading-preview': 'Class Teachers Report on Personal Qualities',
    'form.person-heading-preview-term': 'Term',
    'form.person-th1': 'Student Personal Quality',
    'form.person-th2': 'Remark by Class Teacher',
    'form.person-remark-sub': 'Remark Options : I Always, II Most of Times, III Sometimes, IV Never/Rarely',
    'form.person-level-sub': 'The Levels of Performance indivated by different Grades',
    'form.person-table-grade': 'Grade',
    'form.person-table-performance': 'Performance Level',
    'form.person-table-numeric': 'Numerical Value of Grade',
    
    'form.person-table-grade-a+': 'Excelent',
    'form.person-table-grade-a': 'Very Good',
    'form.person-table-grade-b': 'Good',
    'form.person-table-grade-c': 'Average',
    'form.person-table-grade-d': 'Fair',
    
    'form.principal-name': 'Principal Name',
    'form.foundation-name': 'Foundation Name',
}