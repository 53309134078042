import React, { Component } from 'react';
import Loader from "react-loader-spinner";
import { FormattedMessage } from "react-intl";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default class Loading extends Component {
    render() {
        return (
            <div style={{ textAlign: 'center' }}>
              {this.props.visible && (
                <>
                  <Loader
                    type="Circles"
                    color="grey"
                    height={50}
                    width={50} 
                    visible={this.props.visible}              
                  />
                  <FormattedMessage id="component.text.waiting-data"/>
                </>
              )}
            </div>
          );
    }
}