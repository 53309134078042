import React, { useState, useEffect } from 'react';
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
require('dotenv').config();

export default function Navbar(props) {
    const lsLocale = window.localStorage.getItem('locale');
    const [locale, setLocale] = useState(lsLocale || 'en-us');
    const history = useHistory();

    const [ visible, setVisible ] = useState(false);
    const user = JSON.parse(window.localStorage.getItem('user-data'));   
    const username = user.student_data ? user.student_data.name : user.teacher_data ? user.teacher_data.name : user.username; 

    const handleClick = e => {
        e.stopPropagation();
        e.preventDefault();
        
        setVisible(!visible);
        console.log(e);
    }
    const handleSetLocale = (locale) => {
        window.localStorage.setItem('locale', locale);
        
        history.go(0);
    }

    return (
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="brand">
          <a href="/#">{process.env.REACT_APP_NAME}</a>
        </div>
        <div className="container-fluid">
          <div className="navbar-btn">
            <button type="button" className="btn-toggle-fullwidth">
              <i className="lnr lnr-menu" />
            </button>
          </div>
          <div id="navbar-menu">
            <ul className="nav navbar-nav navbar-right">
              <li className="dropdown ">
                <a
                  href="#/"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <img
                    src="assets/img/user.png"
                    className="img-circle"
                    alt="Avatar"
                  />
                  <span>{username}</span>
                  <i className="icon-submenu lnr lnr-chevron-down" />
                </a>
                <ul className="dropdown-menu">
                  <li className={`dropdown-submenu ${visible ? "open" : ""}`}>
                    <a href="#/" onClick={handleClick}>
                      <i className="lnr lnr-cog" />
                      <span>
                        <FormattedMessage id="menu.language" />
                      </span>
                    </a>
                    <ul className="dropdown-menu">
                      <li
                        className={locale === "en-us" ? "active" : ""}
                        id="locale"
                      >
                        <a href="#" onClick={() => handleSetLocale("en-us")}>
                          <img
                            src={`${window.location.origin}/english-flag.png`}
                            width="20"
                            height="20"
                            style={{ marginRight: 5 }}
                          />
                          English
                        </a>
                      </li>
                      <li
                        className={locale === "id-id" ? "active" : ""}
                        id="locale"
                      >
                        <a
                          onClick={() => handleSetLocale("id-id")}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={`${window.location.origin}/indonesian-flag.png`}
                            width="20"
                            height="20"
                            style={{ marginRight: 5 }}
                          />
                          Bahasa Indonesia
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a onClick={() => history.push("change-password")}>
                      <i className="lnr lnr-eye" />{" "}
                      <span>
                        <FormattedMessage id="menu.change-password" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        window.localStorage.clear();
                        history.push("/");
                      }}
                    >
                      <i className="lnr lnr-exit" />{" "}
                      <span>
                        <FormattedMessage id="menu.logout" />
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
}
