export default {
    // general
    'form.options': 'Opsi',
    'form.created_at': 'Tanggal dibuat',
    'form.number': 'No',
    // teacher
    'form.teacher.name': 'Nama Guru',
    'form.teacher.nik': 'NIK',
    'form.teacher.date_of_birth': 'Tanggal Lahir',
    // student
    'form.student.name': 'Nama Siswa',
    'form.student.address': 'Alamat',
    'form.student.classroom': 'Kelas',
    'form.student.roll_no': 'No absen',
    'form.student.gender': 'Jenis kelamin',
    'form.student.gender.male': 'Laki-laki',
    'form.student.gender.female': 'Perempuan',
    'form.student.telp_mob': 'Nomor handphone',
    'form.student.telp_res': 'Nomor telp rumah',
    'form.student.house': 'House',
    'form.student.code': 'Kode Siswa',
    'form.parent.code': 'Kode Orang Tua',
    'form.parent.name': 'Nama Orang Tua',
    'form.student-access.start': 'Akses Mulai',
    'form.student-access.end': 'Akses Selesai',
    // classroom
    'form.classroom.name': 'Nama Kelas',
    'form.classroom.total-student': 'Total Siswa',
    // session
    'form.session.name': 'Nama Tahun Ajaran',
    // subject
    'form.subject.name': 'Nama Mata Pelajaran',
    'form.subject.list': 'Daftar Mata Pelajaran',
    'form.subject.setting': 'Pengaturan Mata Pelajaran',
    'form.subject.sort': 'Urutan',
    // user
    'form.user.activate': 'Aktifkan',
    'form.user.deactivate': 'Non aktifkan',
    'form.user.active': 'Aktif',
    'form.user.deactive': 'Non aktif',
    'form.user.role': 'Level',
    'form.user.new-password': 'Password Baru',
    // configuration
    'form.configuration.input-score': 'Atur tanggal memasukkan nilai ujian',
    'form.configuration.from': 'Dari tanggal',
    'form.configuration.finish': 'Sampai tanggal',
    // personality
    'form.personality': 'Sikap',
    'form.personality.score': 'Nilai',
    'form.personality.current.score': 'Nilai saat ini',
     // personality mark
    'form.personalityMark.attendance': 'Kehadiran',
    'form.personalityMark.maxattendance': 'Batas Kehadiran',
    'form.personalityMark.number': 'No',
    'form.personalityMark.attendance-count': 'Jumlah kehadiran',
     // religion
    'form.religion.name': 'Nama Agama',
    'form.override.subject.name': 'Nama Tampilan Mapel',
    
    'form.exculComment': 'Kegiatan Ko-Kurikuler',
    'form.classComment': 'komentar guru kelas',
    'form.termly.session': 'Sesi',

    'form.finalResult': 'Hasil Akhir',
    'form.to': 'Ke',

    'form.promotedTo': 'Naik kelas',
    'form.compartmentIn': 'Pindah kelas',
    'form.detainedIn': 'Tinggal kelas',
    
    'form.person-heading-preview': 'Laporan Guru Kelas tentang Kualitas Pribadi',
    'form.person-heading-preview-term': 'Semester',
    'form.person-th1': 'Kualitas Pribadi Siswa',
    'form.person-th2': 'Komentar oleh Guru Kelas',
    'form.person-remark-sub': 'Pilihan Keterangan: I Selalu, II Sebagian Besar Waktu, III Kadang, IV Tidak Pernah / Jarang',
    'form.person-level-sub': 'Tingkat Kinerja yang ditentukan oleh Kelas yang berbeda',
    'form.person-table-grade': 'Nilai',
    'form.person-table-performance': 'Tingkat Kinerja',
    'form.person-table-numeric': 'Nilai Numerik',
    
    'form.person-table-grade-a+': 'Sangat bagus',
    'form.person-table-grade-a': 'Baik sekali',
    'form.person-table-grade-b': 'Baik',
    'form.person-table-grade-c': 'Menengah',
    'form.person-table-grade-d': 'Cukup',

    'form.principal-name': 'Nama Kepala Sekolah',
    'form.foundation-name': 'Nama Ketua Yayasan',
}