import { LOCALES } from '../constants';
import pages from './en-US/pages';
import component from './en-US/component';
import menu from './en-US/menu';
import form from './en-US/form';

export default {
  [LOCALES.ENGLISH]: {
    'hello': 'Hello',
    ...pages,
    ...component,
    ...menu,
    ...form
  },
};
