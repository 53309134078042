require('dotenv').config();
export default class Static {
    
    constructor() {}
    getImportTemplate = (filename) => {       
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/static/${filename}`, {
                method: 'GET',
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
}