import React, { useState, useEffect, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Helpers, Loading } from '../../components';
import { Personalities } from '../../services';
import { MDBDataTable } from 'mdbreact';
import { FormattedMessage } from "react-intl";
import ReactPaginate from 'react-paginate';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { MaintenanceContext } from '../../Maintenance';

moment.locale('id');

const personalities = new Personalities();
const helpers = new Helpers();

export default function Personality(props) {

    useEffect(() => {
        (async () => getPersonalityList())();
    }, []);

    const user = JSON.parse(window.localStorage.getItem('user-data'));
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        id: '',
        label: '',
        created_by: user.username,
        updated_by: user.username,
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 5,
        search: '',
        total: 0,
        totalPage: 1
    });

    const [personalityList, setPersonalityList] = useState([]);
    const [mode, setMode] = useState('Add');

    const getPersonalityList = () => {
        setLoading(true);
        personalities.list(pagination)
        .then(res => {
            console.log(res);
            pagination.limit = res.limit;
            pagination.total = res.total;
            pagination.totalPage = res.total % res.limit === 0 ? (res.total / res.limit) : Math.floor(res.total / res.limit) + 1

            res.result.map( (item, i) => {
                let nums = parseInt(pagination.total - pagination.limit);
                let numRows = (pagination.page - 1) * (pagination.total - nums) + i;

                res.result[i].no = numRows + 1;
                res.result[i].option = 
                <>
                    <button 
                        className="btn btn-warning btn-sm mr-1" 
                        data-toggle="modal" 
                        data-target="#addModal" 
                        onClick={() => getPersonalityInfo(item)}> 
                            <i className="lnr lnr-pencil"></i> 
                            <FormattedMessage id="component.edit.button"/>
                    </button>
                    <button 
                        className="btn btn-danger btn-sm" 
                        data-toggle="modal" 
                        data-target="#deleteModal"
                        onClick={() => getPersonalityInfo(item)}> 
                            <i className="lnr lnr-trash"></i> 
                            <FormattedMessage id="component.delete.button"/>
                    </button>
                </>
                res.result[i].created_at = helpers.formatDate(item.created_at);
            });        

            setPagination(pagination);
            setPersonalityList(res.result);
            setLoading(false);
        })
        .catch(err => {
            console.error(err);

            if (err.code === 404) {
                setPersonalityList([]);
            }
            if (err.status === 503) {
                setIsMaintenance(true)
            }
            setLoading(false);
        });
    };

    const handlePayload = (e) => {
        setPayload({
            ...payload,
            label: e.target.value
        });
    }

    const createPersonality = () => {
        setLoading(true);
        personalities.create(payload)
        .then(res => {
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id="component.text.add-successful"/>);

                reset();

                getPersonalityList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }
    
    const updatePersonality = () => {
        setLoading(true);
        personalities.update(payload)
        .then(res => {
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                reset();
                getPersonalityList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }
    
    const deletePersonality = () => {
        setLoading(true);
        personalities.delete(payload.id)
        .then(res => {
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                reset();
                getPersonalityList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }

    const getPersonalityInfo = (personalityInfo) => {
        setPayload({
            ...payload,
            id: personalityInfo.id,
            label: personalityInfo.label,
            updated_by: payload.created_by
        });
        setMode('Edit');
    }

    const reset = () => {
        setPayload({
            id: '',
            label: '',
            created_by: user.username,
            updated_by: user.username,
        });
    }

    // handle pagination
    const _handleChangePage = e => {
        const { selected } = e;
        pagination.page = selected + 1;
        
        setPagination(pagination);
        getPersonalityList();

    }

    const renderModalTitle = mode => {
        switch(mode) {
            case 'Add':
                return <FormattedMessage id="component.add.button"/>;
            case 'Edit':
                return <FormattedMessage id="component.edit.button"/>;
            default:
                return '';
        }
    }

    const renderModalSubmit = mode => {
        switch(mode) {
            case 'Add':
                createPersonality()
                break;
            case 'Edit':
                updatePersonality()
                break;
            default:
                console.error('Function not found.')
                break;
        }
    }

    const data = {
        columns: [
          {
            label: <FormattedMessage id="form.number" defaultMessage="No"/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: <FormattedMessage id="form.personality.label" defaultMessage="Label"/>,
            field: 'label',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id="form.created_at"/>,
            field: 'created_at',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id="form.options"/>,
            field: 'option',
            sort: 'asc',
            width: 100
          },
        ],
        rows: personalityList
    };

    const { page, totalPage } = pagination;
    return (
        <div className="main">
            <ToastContainer
                autoClose={5000}
                hideProgressBar
                pauseOnHover={false} />
            <div className="main-content">
                {user.role === 'Admin' && (
                 
                    <div className="container-fluid">
                        {ismaintenance ? (
    <div className="text-center">
        <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
        <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
    </div>
)  : (
                            <>
                        <h3 className="page-title"><FormattedMessage id="menu.personality" /></h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-header mt-2 mr-2">
                                        <div className="row">
                                            <div className="col-md-6"></div>
                                            
                                            <div className="col-md-6">
                                                <button 
                                                    className="btn btn-primary pr-3" 
                                                    data-toggle="modal" 
                                                    data-target="#addModal" 
                                                    style={{float : 'right'}} 
                                                    onClick={() => setMode('Add')}> 
                                                    <FormattedMessage id="component.add.button"/>
                                                </button>
                                            </div>
                                        </div>                                    
                                    </div>
                                    <div className="panel-body">
                                        <Loading visible={loading}/>
                                        <MDBDataTable
                                            className="table mt-5"
                                            data={data}
                                            sortable
                                            noBottomColumns    
                                            entriesOptions={[5, 10, 20, 50]}
                                            displayEntries={true}
                                            paging={false}
                                            noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>}                                                                           
                                        />
                                        <ReactPaginate
                                            // className="d-flex justify-content-center"
                                            breakLabel={'...'}
                                            pageCount={totalPage}
                                            marginPagesDisplayed={1}
                                            containerClassName={'pagination'}
                                            activeClassName={'active'}
                                            initialPage={page}
                                            onPageChange={_handleChangePage}
                                        />
                                </div>
                            </div>                    
                        </div>
                    </div>
                    </> 
                    )}
                </div>
                )}
        </div>
        <Modal 
            title={renderModalTitle(mode)}
            label={mode}
            form={                
                (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="required"><FormattedMessage id="form.personality.label" defaultMessage="Label" /></label>
                                <input 
                                    required
                                    type="text" 
                                    value={payload.label}
                                    className="form-control"
                                    onChange={(e) => handlePayload(e)} />
                            </div>   
                        </div>                        
                    </div>
                )
            }
            onSubmit={(e) => {e.preventDefault();renderModalSubmit(mode);}}
            onClose={() => reset()}
            onDelete={() => deletePersonality()}
            disabled={loading}/>
            
    </div> 
    )
}
