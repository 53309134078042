import React, { useState, useEffect, useContext} from 'react';
import Select from 'react-select';
import {
  Students,
  Classrooms,
  Sessions,
  ReportHistory,
  Configurations,
} from "../../services";
import { FormattedMessage } from "react-intl";
import { Loading } from "../../components";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import { MaintenanceContext } from "../../Maintenance";

import "react-toastify/dist/ReactToastify.css";

const students = new Students();
const classrooms = new Classrooms();
const sessions = new Sessions();
const reportHistory = new ReportHistory();
const configuration = new Configurations();

export default function Student(props) {
  useEffect(() => {
    (async () => getPrincpalSchool())();
    (async () => getClassroomList())();
    (async () => getSessionList())();
  }, []);
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#ffffff",
      width: 840,
      height: 400,
    },
    container: {
      margin: "auto",
      marginTop: 30,
      marginBottom: 0,
      paddingBottom: 0,
      marginLeft: 190,
      height: "auto",
      display: "block",
      width: "auto",
    },
    container2: {
      margin: "auto",
      marginTop: 10,
      marginBottom: 0,
      paddingBottom: 0,
      marginLeft: 40,
      height: "auto",
      display: "block",
      width: "auto",
    },
    container3: {
      margin: "auto",
      marginTop: 100,
      marginBottom: 0,
      paddingBottom: 0,
      marginLeft: 0,
      height: "auto",
      display: "block",
      width: "auto",
    },
    table: {
      margin: "auto",
      marginTop: 10,
      marginBottom: 0,
      paddingBottom: 0,
      height: "auto",
      display: "block",
      width: "auto",
      border: "1px solid #000",
      borderWidth: 0.5,
    },
    table2: {
      margin: "auto",
      marginTop: 5,
      display: "block",
      width: "auto",
      height:"auto",
      border: "1px solid #000",
      borderWidth: 0.5,
    },
    signature: {
      margin: "auto",
      display: "block",
      width: "auto",
      height:"auto",
    },
    thRow: {
      flexDirection: "row",
    },
    thCol: {
      width: "auto",
      border: "1px solid #000",
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
      padding: 0,
    },
    thText: {
      fontSize: 4,
      fontWeight: 800,
      color: "#000",
    },
    tdRow: {
      flexDirection: "row",
    },
    tdCol: {
      width: "auto",
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
      padding: 0,
    },
    tdText: {
      padding: 0,
      fontSize: 4,
      color: "#000",
    },
    text: {
      padding: 0,
      fontSize: 4,
      color: "#000",
    },
    row: {
      flexDirection: "row",
      display: "block",
      width: 840,
    },
    col: {
      width: 200,
      padding: 0,
    },
    textRight: {
      padding: 0,
      fontSize: 4,
      color: "#000",
      textAlign: "right",
    },
    rowRight: {
      flexDirection: "row",
      display: "block",
      width: 750,
      textAlign: "right",
    },
    colRight: {
      width: 750,
      padding: 0,
      textAlign: "right",
    },
    thColNo: {
      width: 20,
      borderRightWidth: 0.5,
      padding: 0,
    },
    thColTotal: {
      width: 30,
      borderRightWidth: 0.5,
      padding: 0,
    },
    thColNoSub: {
      textAlign: "center",
      width: 20,
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
      padding: 0,
    },
    thColName: {
      width: 74,
      borderRightWidth: 0.5,
      padding: 0,
    },
    thColNameSub: {
      width: 74,
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
      padding: 0,
      textTransform: "uppercase",
    },
    thColNoNull: {
      width: 20,
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
      padding: 0,
    },
    thColNameNull: {
      width: 74,
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
      padding: 0,
    },
    thColTotalNull: {
      width: 30,
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
      padding: 0,
    },
    thColSubject: {
      textAlign: "center",
      width: 56,
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
      padding: 0,
    },
    tdColSubSubject: {
      textAlign: "center",
      width: 14,
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
    },
    tdColTotal: {
      textAlign: "center",
      width: 30,
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
    },
    tdSub: {
      padding: 0,
      fontSize: 2,
      color: "black",
    },
    thColStudent: {
      width: 64,
      padding: 0,
      borderRightWidth: 0.5,
    },
    tdColSubStudent: {
      textAlign: "center",
      width: 56,
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
    },
    tdColSubTeacher: {
      width: 56,
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
    },
    table3: {
      margin: "auto",
      marginTop: 100,
      display: "block",
      width: "auto",
      height: "auto",
      border: "1px solid #000",
      borderWidth: 1,
    },
    tableRowCustom2: {
      margin: "auto",
      flexDirection: "row",
    },

    tableColNo: {
      width: 50,
      border: "1px solid #000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderBottomWidth: 0,
      padding: 0,
    },
    tableColValue: {
      width: 55,
      border: "1px solid #000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderBottomWidth: 0,
      padding: 0,
    },
    tableColName: {
      width: 150,
      border: "1px solid #000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderBottomWidth: 0,
      padding: 0,
    },
    tableColTeacher: {
      width: 200,
      border: "1px solid #ccc",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderBottomWidth: 0,
      padding: 0,
    },
    tableColTeacherName: {
      width: 165,
      border: "1px solid #000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderBottomWidth: 0,
      padding: 0,
    },
    tableRow2: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCellNo: {
      textAlign: "center",
      marginTop: 0,
      padding: 1,
      fontSize: 10,
      fontWeight: 800,
      color: "black",
    },
    tableCellName: {
      marginTop: 0,
      padding: 1,
      fontSize: 10,
      fontWeight: 800,
      color: "black",
      textTransform: "uppercase",
    },
    tableCellValue1: {
      textAlign: "center",
      marginTop: 0,
      padding: 1,
      fontSize: 8,
      fontWeight: 800,
      color: "black",
    },
    tableCellValue2: {
      textAlign: "center",
      marginTop: 0,
      padding: 1,
      fontSize: 10,
      fontWeight: 800,
      color: "black",
    },
    tableCellTeacher: {
      marginTop: 0,
      padding: 1,
      fontSize: 10,
      fontWeight: 800,
      color: "black",
    },
    tableCellTeacherName: {
      marginTop: 0,
      padding: 1,
      fontSize: 10,
      fontWeight: 800,
      color: "black",
    },
    image: {
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      width: 500,
      height: 100,
      display: "block",
      marginLeft: 340,
      marginTop: 100,
    },
    rows: {
      flexDirection: "row",
      display: "block",
      width: "auto",
      padding: 0,
    },
    col2: {
      width: 80,
      padding: 0,
    },
    texts: {
      padding: 0,
      fontSize: 8,
      color: "#000",
      textAlign: "center",
    },
    sign: {
      borderWidth: 1,
      borderBottom: 0,
      borderLeft: 0,
      borderRight: 0,
      marginLeft: 15,
      marginRight: 17,
    },
    label: {
      justifyContent: "center",
      alignItems: "center",
      width: 500,
      height: 15,
      marginLeft: 340,
      marginTop: 10,
      padding: 0,
      backgroundColor: "#eee",
    },
    labelText: {
      fontSize: 6,
      float: "left",
    },
    mt05: {
      marginTop: "5",
    },
    mt1: {
      marginTop: "10",
    },
    mt2: {
      marginTop: "20",
    },
    mt3: {
      marginTop: "30",
    },
    mt33: {
      marginTop: "32",
    },
    mt4: {
      marginTop: "40",
    },
    mt5: {
      marginTop: "50",
    },
    mt6: {
      marginTop: "60",
    },
    mt7: {
      marginTop: "70",
    },
    mt8: {
      marginTop: "80",
    },
    mt9: {
      marginTop: "90",
    },
    mt10: {
      marginTop: "100",
    },
    mb05: {
      marginTop: "5",
    },
    mb1: {
      marginTop: "10",
    },
    mb2: {
      marginTop: "20",
    },
    mb3: {
      marginTop: "30",
    },
    mb4: {
      marginTop: "40",
    },
    mb5: {
      marginTop: "50",
    },
    mb6: {
      marginTop: "60",
    },
    mb7: {
      marginTop: "70",
    },
    mb8: {
      marginTop: "80",
    },
    mb9: {
      marginTop: "90",
    },
    mb10: {
      marginTop: "100",
    },
    tleft: {
      textAlign: "left",
    },
    tcenter: {
      textAlign: "center",
    },
    fleft: {
      float: "left",
    },
    fright: {
      float: "right",
    },
  });

  const user = JSON.parse(window.localStorage.getItem("user-data"));
  const { ismaintenance, setIsMaintenance } = useContext(MaintenanceContext);

  const [loadingClassroom, setLoadingClassroom] = useState(false);
  const [loadingConsolidated, setLoadingConsolidated] = useState(false);
  const [loadingStudent, setLoadingStudent] = useState(false);
  const [loadingSession, setLoadingSession] = useState(false);

  const [consolidatedList, setConsolidatedList] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [tmpStudentList, setTmpStudentList] = useState([]);
  const [classroomList, setClassroomList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [term, setTerm] = useState("3");
  const [classroomId, setClassroomId] = useState("");
  const [currentClass, setCurrentClass] = useState({});
  const [session, setSession] = useState("");
  const [school, setSchool] = useState("");

  const getStudentByClassroom = (classroomId) => {
    setLoadingStudent(true);
    students
      .getStudentByClassroom(classroomId)
      .then((res) => {
        console.log(res);

        setClassroomId(classroomId);
        setStudentList(res.result);
        setTmpStudentList(res.result);
        setLoadingStudent(false);
        setCurrentClass(classroomList.find((c) => c.id == classroomId));
      })
      .catch((err) => {
        console.error(err);

        if (err.code === 404) {
          setStudentList([]);
        }
        setLoadingStudent(false);
      });
  };
  const getPrincpalSchool = () => {
    configuration
      .principal()
      .then((res) => {
        setSchool(res.result.value.end_at);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getClassroomList = () => {
    setLoadingClassroom(true);
    classrooms
      .list({ limit: 100 })
      .then((res) => {
        console.log(res);
        res.result = res.result.filter(
          (item) => item.teacher.id === user.teacher_data.id
        );

        res.result.map((item, i) => {
          res.result[i].value = item.id;
          res.result[i].label = item.name;
        });
        setClassroomList(res.result);
        setLoadingClassroom(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.code === 404) {
          setClassroomList([]);
        }
        if (err.status === 503) {
          setIsMaintenance(true);
        }
        setLoadingClassroom(false);
      });
  };

  const getConsolidated = (term) => {
    setLoadingConsolidated(true);
    classrooms
      .consolidated(classroomId, term)
      .then((res) => {
        console.log(res);
        let holder = [];
        for (let i in res.result.subject_wise) {
          console.warn(res.result.subject_wise[i]);
          holder = [...holder, res.result.subject_wise[i]];
        }

        res.result.subject_wise = holder;

        // console.log("res reuslt: ", res.result.student_wise);
        setConsolidatedList(res.result);
        setLoadingConsolidated(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.code === 404) {
          setConsolidatedList([]);
        }

        setLoadingConsolidated(false);
      });
  };

  const getSessionList = () => {
    setLoadingSession(true);
    sessions
      .list({ limit: 100 })
      .then((res) => {
        console.log(res);
        const session = res.result.slice(0, -1);

        setSessionList(session);
        setLoadingSession(false);
      })
      .catch((err) => {
        console.error(err);

        if (err.code === 404) {
          setStudentList([]);
        }
        setLoadingSession(false);
      });
  };

  const doPrint = (url, type = "mark") => {    
    let history = session !== '' ? `?session=${session}` : '';

    if (type === "personality") {
      window.open(`${url}/term/${term}${history}`);
    }
    if (term === "3" || term === "4") {
      window.open(`${url}/mid-term/${term}${history}`);
    } else if (term === "1") {
      window.open(`${url}/term/${term}${history}`);
    }
    window.open(`${url}/${term}${history}`);
  };
  const handlePayload = (e, state) => {
    if (state === "term") {
      const val = parseInt(e);
      if ((val === 1 || val === 2) && session === "") {
        getConsolidated(val);
      }
    } else {
      getStudentByClassroom(e.id);
    }
  };
  const _handleChangeSession = (s) => {
    console.warn("s: ", s);
    setSession(s);
    if (s === "") {
      setSession(s);
      setStudentList(tmpStudentList);
      return;
    }

    // reportHistory
    //   .studentList("eb4d3d20-f61b-49bb-adfc-91ce6ab8645c", s)
    //   .then((res) => {
    //     console.log("report history: ", res);
    //     setStudentList(tmpStudentList);
    //   })
    //   .catch((err) => {
    //     console.error(err);

    //     setLoadingStudent(false);
    //     setStudentList([]);
    //   });
    setSession(s);
  };

  const MyDocument = () => (
    <Document>
      <Page size="A3" orientation="landscape" tyle={styles.page}>
        <Image style={styles.image} src="/assets/img/heading.jpeg" />
        <View style={styles.label}>
          <Text style={styles.labelText}>
            CONSOLIDATED SHEET - {term == 1 ? "TERM 1" : "ANNUAL"}
          </Text>
        </View>
        <View style={styles.container}>
          <View style={styles.rowRight}>
            <View style={styles.colRight}>
              <Text style={styles.textRight}>Total Mark</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.text}>
                Class: {currentClass?.name || "-"}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.text}>
                Class Teacher: {currentClass?.teacher?.name || "-"}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.thRow}>
            <View style={styles.thColNo}>
              <Text style={styles.thText}>Roll No</Text>
            </View>
            <View style={styles.thColName}>
              <Text style={styles.thText}>Name</Text>
            </View>
            {consolidatedList &&
              consolidatedList.student_wise?.length > 0 &&
              consolidatedList.student_wise[0].consolidated.student_subjects.map(
                (item, i) => (
                  <>
                    {item.subject.is_curricular === false && (
                      <>
                        <View style={styles.thColSubject}>
                          <Text style={styles.thText}>
                            {item.subject.name || ""}
                          </Text>
                        </View>
                      </>
                    )}
                  </>
                )
              )}
            <View style={styles.thColTotal}>
              <Text style={styles.thText}>Total Mark</Text>
            </View>
            <View style={styles.thColTotal}>
              <Text style={styles.thText}>Percentage</Text>
            </View>
          </View>
          <View style={styles.thRow}>
            <View style={styles.thColNoNull}>
              <Text style={styles.thText}></Text>
            </View>
            <View style={styles.thColNameNull}>
              <Text style={styles.thText}></Text>
            </View>

            {consolidatedList &&
              consolidatedList.student_wise?.length > 0 &&
              consolidatedList.student_wise[0].consolidated.student_subjects.map(
                (item, i) => (
                  <>
                    {item.subject.is_curricular === false && (
                      <>
                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdSub}>40 (MT1)</Text>
                        </View>

                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdSub}>20 (MT1)</Text>
                        </View>

                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdSub}>40 (MT1)</Text>
                        </View>

                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdSub}>Total</Text>
                        </View>
                      </>
                    )}
                  </>
                )
              )}
            <View style={styles.thColTotalNull}>
              <Text style={styles.thText}></Text>
            </View>
            <View style={styles.thColTotalNull}>
              <Text style={styles.thText}></Text>
            </View>
          </View>
          {consolidatedList &&
            consolidatedList.student_wise?.length > 0 &&
            consolidatedList.student_wise.map((item, _) => (
              <View style={styles.thRow}>
                <View style={styles.thColNoSub}>
                  <Text style={styles.thText}>{item.roll_no || "-"}</Text>
                </View>
                <View style={styles.thColNameSub}>
                  <Text style={styles.thText}>{item.name || "-"}</Text>
                </View>
                {item.consolidated.student_subjects.map((s, i) => (
                  <>
                    {s.subject.is_curricular === false && (
                      <>
                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdText}>
                            {(s.marks[term - 1] &&
                              s.marks[term - 1].mid_term_value) ||
                              "-"}
                          </Text>
                        </View>
                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdText}>
                            {(s.marks[term - 1] &&
                              s.marks[term - 1].end_term_assessment) ||
                              "-"}
                          </Text>
                        </View>
                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdText}>
                            {(s.marks[term - 1] &&
                              s.marks[term - 1].end_term) ||
                              "-"}
                          </Text>
                        </View>
                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdText}>
                            {(s.marks[term - 1] &&
                              s.marks[term - 1].final_term) ||
                              "-"}
                          </Text>
                        </View>
                      </>
                    )}
                  </>
                ))}
                <>
                  <View style={styles.tdColTotal}>
                    <Text style={styles.tdText}>
                      {/* <pre>

                        {JSON.stringify(item.consolidated.student_subjects.find(s => s.student.id === item.id).aggregate_marks, 0, 2)}
                      </pre> */}
                      {term == 1
                        ? item.consolidated.student_subjects.find(
                            (s) => s.student.id === item.id
                          ).aggregate_marks[term - 1]?.total_mid_term_value
                        : item.consolidated.student_subjects.find(
                            (s) => s.student.id === item.id
                          ).aggregate_marks[term - 1]?.total_final_term}
                    </Text>
                  </View>
                  <View style={styles.tdColTotal}>
                    <Text style={styles.tdText}>
                      {item.consolidated.student_subjects
                        .find((s) => s.student.id === item.id)
                        .aggregate_marks[term - 1]?.total_percentage.toFixed(2)}
                    </Text>
                  </View>
                </>
              </View>
            ))}
        </View>

        <View style={[styles.label, styles.mt3]}>
          <Text style={styles.labelText}>SUBJECT WISE SUMMARY</Text>
        </View>
        <View style={[styles.table2, styles.mt3]}>
          <View style={styles.thRow}>
            <View style={styles.thColStudent}>
              <Text style={styles.thText}></Text>
            </View>
            {consolidatedList &&
              consolidatedList.subject_wise?.length > 0 &&
              consolidatedList.subject_wise.map((item, _) => (
                <>
                  {item.subject.is_curricular === false && (
                    <>
                      <View style={styles.tdColSubStudent}>
                        <Text style={styles.tdText}>{item.subject.name}</Text>
                      </View>
                    </>
                  )}
                </>
              ))}
          </View>
          <View style={styles.thRow}>
            <View style={styles.thColStudent}>
              <Text style={styles.thText}>Total No. of Students Appeared</Text>
            </View>
            {consolidatedList &&
              consolidatedList.subject_wise?.length > 0 &&
              consolidatedList.subject_wise.map((item, _) => (
                <>
                  {item.subject.is_curricular === false && (
                    <>
                      <View style={styles.tdColSubStudent}>
                        <Text style={styles.tdText}>{item.total_students}</Text>
                      </View>
                    </>
                  )}
                </>
              ))}
          </View>
          <View style={styles.thRow}>
            <View style={styles.thColStudent}>
              <Text style={styles.thText}>Total No. of Students Passed</Text>
            </View>

            {consolidatedList &&
              consolidatedList.subject_wise?.length > 0 &&
              consolidatedList.subject_wise.map((item, _) => (
                <>
                  {item.subject.is_curricular === false && (
                    <>
                      <View style={styles.tdColSubStudent}>
                        <Text style={styles.tdText}>
                          {item.total_students_passed}
                        </Text>
                      </View>
                    </>
                  )}
                </>
              ))}
          </View>
          <View style={styles.thRow}>
            <View style={styles.thColStudent}>
              <Text style={styles.thText}>Total No. of Students Failed</Text>
            </View>

            {consolidatedList &&
              consolidatedList.subject_wise?.length > 0 &&
              consolidatedList.subject_wise.map((item, _) => (
                <>
                  {item.subject.is_curricular === false && (
                    <>
                      <View style={styles.tdColSubStudent}>
                        <Text style={styles.tdText}>
                          {item.total_students_failed}
                        </Text>
                      </View>
                    </>
                  )}
                </>
              ))}
          </View>
          <View style={styles.thRow}>
            <View style={styles.thColStudent}>
              <Text style={styles.thText}>Pass Percentage</Text>
            </View>

            {consolidatedList &&
              consolidatedList.subject_wise?.length > 0 &&
              consolidatedList.subject_wise.map((item, _) => (
                <>
                  {item.subject.is_curricular === false && (
                    <>
                      <View style={styles.tdColSubStudent}>
                        <Text style={styles.tdText}>
                          {item.pass_percentage}
                        </Text>
                      </View>
                    </>
                  )}
                </>
              ))}
          </View>
          <View style={styles.thRow}>
            <View style={styles.thColStudent}>
              <Text style={styles.thText}>Name of Subject Teacher</Text>
            </View>

            {consolidatedList &&
              consolidatedList.subject_wise?.length > 0 &&
              consolidatedList.subject_wise.map((item, _) => (
                <>
                  {item.subject.is_curricular === false && (
                    <>
                      <View style={styles.tdColSubStudent}>
                        <Text style={styles.tdText}>
                          {item.subject.teacher.name}
                        </Text>
                      </View>
                    </>
                  )}
                </>
              ))}
          </View>
        </View>
      
        <View style={styles.signature}>
          <View style={styles.rows}>
            <View style={[styles.col2, styles.sign]}>
              <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                {" "}
                Class Teacher{" "}
              </Text>
              <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                ({currentClass?.teacher?.name || "-"})
              </Text>
            </View>
            <View style={[styles.col2, styles.sign]}>
              <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                {" "}
                Principal{" "}
              </Text>
              <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                {" "}
                ({school || "-"})
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A3" orientation="landscape" tyle={styles.page}>
        <Image style={styles.image} src="/assets/img/heading.jpeg" />
        <View style={[styles.label, styles.mt3]}>
          <Text style={styles.labelText}>SUBJECT WISE SUMMARY</Text>
        </View>
        <View style={[styles.table2, styles.mt3]}>
          <View style={styles.thRow}>
            <View style={styles.thColStudent}>
              <Text style={styles.thText}></Text>
            </View>
            {consolidatedList &&
              consolidatedList.subject_wise?.length > 0 &&
              consolidatedList.subject_wise.map((item, _) => (
                <>
                  {item.subject.is_curricular === true && (
                    <>
                      <View style={styles.tdColSubStudent}>
                        <Text style={styles.tdText}>{item.subject.name}</Text>
                      </View>
                    </>
                  )}
                </>
              ))}
          </View>
          <View style={styles.thRow}>
            <View style={styles.thColStudent}>
              <Text style={styles.thText}>Total No. of Students Appeared</Text>
            </View>
            {consolidatedList &&
              consolidatedList.subject_wise?.length > 0 &&
              consolidatedList.subject_wise.map((item, _) => (
                <>
                  {item.subject.is_curricular === true && (
                    <>
                      <View style={styles.tdColSubStudent}>
                        <Text style={styles.tdText}>{item.total_students}</Text>
                      </View>
                    </>
                  )}
                </>
              ))}
          </View>
          <View style={styles.thRow}>
            <View style={styles.thColStudent}>
              <Text style={styles.thText}>Total No. of Students Passed</Text>
            </View>

            {consolidatedList &&
              consolidatedList.subject_wise?.length > 0 &&
              consolidatedList.subject_wise.map((item, _) => (
                <>
                  {item.subject.is_curricular === true && (
                    <>
                      <View style={styles.tdColSubStudent}>
                        <Text style={styles.tdText}>
                          {item.total_students_passed}
                        </Text>
                      </View>
                    </>
                  )}
                </>
              ))}
          </View>
          <View style={styles.thRow}>
            <View style={styles.thColStudent}>
              <Text style={styles.thText}>Total No. of Students Failed</Text>
            </View>

            {consolidatedList &&
              consolidatedList.subject_wise?.length > 0 &&
              consolidatedList.subject_wise.map((item, _) => (
                <>
                  {item.subject.is_curricular === true && (
                    <>
                      <View style={styles.tdColSubStudent}>
                        <Text style={styles.tdText}>
                          {item.total_students_failed}
                        </Text>
                      </View>
                    </>
                  )}
                </>
              ))}
          </View>
          <View style={styles.thRow}>
            <View style={styles.thColStudent}>
              <Text style={styles.thText}>Pass Percentage</Text>
            </View>

            {consolidatedList &&
              consolidatedList.subject_wise?.length > 0 &&
              consolidatedList.subject_wise.map((item, _) => (
                <>
                  {item.subject.is_curricular === true && (
                    <>
                      <View style={styles.tdColSubStudent}>
                        <Text style={styles.tdText}>
                          {item.pass_percentage}
                        </Text>
                      </View>
                    </>
                  )}
                </>
              ))}
          </View>
          <View style={styles.thRow}>
            <View style={styles.thColStudent}>
              <Text style={styles.thText}>Name of Subject Teacher</Text>
            </View>

            {consolidatedList &&
              consolidatedList.subject_wise?.length > 0 &&
              consolidatedList.subject_wise.map((item, _) => (
                <>
                  {item.subject.is_curricular === true && (
                    <>
                      <View style={styles.tdColSubStudent}>
                        <Text style={styles.tdText}>
                          {item.subject.teacher.name}
                        </Text>
                      </View>
                    </>
                  )}
                </>
              ))}
          </View>
        </View>
        <View style={styles.signature}>
          <View style={styles.rows}>
            <View style={[styles.col2, styles.sign]}>
              <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                {" "}
                Class Teacher{" "}
              </Text>
              <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                ({currentClass?.teacher?.name || "-"})
              </Text>
            </View>
            <View style={[styles.col2, styles.sign]}>
              <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                {" "}
                Principal{" "}
              </Text>
              <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                {" "}
                ({school || "-"})
              </Text>
            </View>
          </View>
        </View>

      </Page>
      <Page size="A3" orientation="landscape" tyle={styles.page}>
        <Image style={styles.image} src="/assets/img/heading.jpeg" />
        <View style={styles.label}>
          <Text style={styles.labelText}>CONSOLIDATED SHEET - {term == 1 ? "TERM 1" : "ANNUAL"}</Text>
        </View>
        <View style={[styles.table, styles.mt3]}>
          <View style={styles.thRow}>
            <View style={styles.thColNo}>
              <Text style={styles.thText}>Roll No</Text>
            </View>
            <View style={styles.thColName}>
              <Text style={styles.thText}>Name</Text>
            </View>
            {consolidatedList &&
              consolidatedList.student_wise?.length > 0 &&
              consolidatedList.student_wise[0].consolidated.student_subjects.map(
                (item, i) => (
                  <>
                    {item.subject.is_curricular === true && (
                      <>
                        <View style={styles.thColSubject}>
                          <Text style={styles.thText}>
                            {item.subject.name || ""}
                          </Text>
                        </View>
                      </>
                    )}
                  </>
                )
              )}
            <View style={styles.thColTotal}>
              <Text style={styles.thText}>Total Mark</Text>
            </View>
            <View style={styles.thColTotal}>
              <Text style={styles.thText}>Percentage</Text>
            </View>
          </View>
          <View style={styles.thRow}>
            <View style={styles.thColNoNull}>
              <Text style={styles.thText}></Text>
            </View>
            <View style={styles.thColNameNull}>
              <Text style={styles.thText}></Text>
            </View>

            {consolidatedList &&
              consolidatedList.student_wise?.length > 0 &&
              consolidatedList.student_wise[0].consolidated.student_subjects.map(
                (item, i) => (
                  <>
                    {item.subject.is_curricular === true && (
                      <>
                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdSub}>40 (MT1)</Text>
                        </View>

                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdSub}>20 (MT1)</Text>
                        </View>

                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdSub}>40 (MT1)</Text>
                        </View>

                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdSub}>Total</Text>
                        </View>
                      </>
                    )}
                  </>
                )
              )}
            <View style={styles.thColTotalNull}>
              <Text style={styles.thText}></Text>
            </View>
            <View style={styles.thColTotalNull}>
              <Text style={styles.thText}></Text>
            </View>
          </View>

          {consolidatedList &&
            consolidatedList.student_wise?.length > 0 &&
            consolidatedList.student_wise.map((item, _) => (
              <View style={styles.thRow}>
                <View style={styles.thColNoSub}>
                  <Text style={styles.thText}>{item.roll_no || "-"}</Text>
                </View>
                <View style={styles.thColNameSub}>
                  <Text style={styles.thText}>{item.name || "-"}</Text>
                </View>

                {item.consolidated.student_subjects.map((s, i) => (
                  <>
                    {s.subject.is_curricular === true && (
                      <>
                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdText}>
                            {(s.marks[term - 1] &&
                              s.marks[term - 1].mid_term_value) ||
                              "-"}
                          </Text>
                        </View>
                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdText}>
                            {(s.marks[term - 1] &&
                              s.marks[term - 1].end_term_assessment) ||
                              "-"}
                          </Text>
                        </View>
                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdText}>
                            {(s.marks[term - 1] &&
                              s.marks[term - 1].end_term) ||
                              "-"}
                          </Text>
                        </View>
                        <View style={styles.tdColSubSubject}>
                          <Text style={styles.tdText}>
                            {(s.marks[term - 1] &&
                              s.marks[term - 1].final_term) ||
                              "-"}
                          </Text>
                        </View>
                      </>
                    )}
                  </>
                ))}
                <>
                  <View style={styles.tdColTotal}>
                    <Text style={styles.tdText}>
                      {/* <pre>

                        {JSON.stringify(item.consolidated.student_subjects.find(s => s.student.id === item.id).aggregate_marks, 0, 2)}
                      </pre> */}
                      {term == 1
                        ? item.consolidated.student_subjects.find(
                            (s) => s.student.id === item.id
                          ).aggregate_marks[term - 1]?.total_mid_term_value
                        : item.consolidated.student_subjects.find(
                            (s) => s.student.id === item.id
                          ).aggregate_marks[term - 1]?.total_final_term}
                    </Text>
                  </View>
                  <View style={styles.tdColTotal}>
                    <Text style={styles.tdText}>
                      {item.consolidated.student_subjects
                        .find((s) => s.student.id === item.id)
                        .aggregate_marks[term - 1]?.total_percentage.toFixed(2)}
                    </Text>
                  </View>
                </>
              </View>
            ))}
        </View>
        <View style={styles.signature}>
          <View style={styles.rows}>
            <View style={[styles.col2, styles.sign]}>
              <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                {" "}
                Class Teacher{" "}
              </Text>
              <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                ({currentClass?.teacher?.name || "-"})
              </Text>
            </View>
            <View style={[styles.col2, styles.sign]}>
              <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                {" "}
                Principal{" "}
              </Text>
              <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                {" "}
                ({school || "-"})
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="main">
      <div className="main-content">
        {user.role === "Wali Kelas" && (
          <div className="container-fluid">
            {ismaintenance ? (
              <div className="text-center">
                <img
                  src="assets/img/maintenance.png"
                  className="img-responsive"
                  style={{ width: "400px", margin: "auto" }}
                />
                <h1 className="text-center" style={{ marginTop: "-20px" }}>
                  Under Maintenance
                </h1>
              </div>
            ) : (
              <>
                <h3 className="page-title">
                  <FormattedMessage id="menu.student-report" />
                </h3>

                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <p
                        style={{
                          textAlign: "left",
                          fontWeight: "700",
                          fontSize: "15",
                        }}
                      >
                        <FormattedMessage id="component.text.session" />:
                      </p>
                      <div className="form-group">
                        <select
                          className="form-control"
                          onChange={(e) => {
                            _handleChangeSession(e.target.value);
                          }}
                        >
                          <option value="">Now</option>
                          {loadingSession ? (
                            <option value="" disabled>
                              Waiting for data...
                            </option>
                          ) : (
                            sessionList.length > 0 &&
                            sessionList.map((item, index) => (
                              <option value={item.id}>{item.name}</option>
                            ))
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "700",
                        fontSize: "15",
                      }}
                    >
                      <FormattedMessage id="form.classroom.name" />:
                    </p>
                    <div className="form-group">
                      <Select
                        placeholder={
                          loadingClassroom
                            ? "Waiting for data..."
                            : "Choose the classroom"
                        }
                        options={classroomList || null}
                        onChange={(e) => handlePayload(e, "classroomId")}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "700",
                        fontSize: "15",
                      }}
                    >
                      <FormattedMessage id="component.text.term" />:
                    </p>
                    <div className="form-group">
                      <select
                        disabled={!classroomId}
                        className="form-control"
                        onChange={(e) => {
                          setTerm(e.target.value);
                          handlePayload(e.target.value, "term");
                        }}
                      >
                        <FormattedMessage id="component.text.mid-term">
                          {(message) => <option value="3">{message} 1</option>}
                        </FormattedMessage>
                        <FormattedMessage id="component.text.term">
                          {(message) => <option value="1">{message} 1</option>}
                        </FormattedMessage>
                        <FormattedMessage id="component.text.mid-term">
                          {(message) => <option value="4">{message} 2</option>}
                        </FormattedMessage>
                        <FormattedMessage id="component.text.term">
                          {(message) => <option value="2">{message} 2</option>}
                        </FormattedMessage>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    {(term === "1" || term === "2") &&
                      (loadingConsolidated ? (
                        <>
                          <p
                            style={{
                              textAlign: "left",
                              fontWeight: "700",
                              fontSize: "15",
                            }}
                          >
                            Loading Consolidated...
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            style={{
                              textAlign: "left",
                              fontWeight: "700",
                              fontSize: "15",
                            }}
                          >
                            Consolidated:
                          </p>

                          <PDFDownloadLink
                            document={<MyDocument />}
                            fileName="Consolidated.pdf"
                            style={{
                              textDecoration: "none",
                              padding: "10px",
                              color: "#4a4a4a",
                              backgroundColor: "#f2f2f2",
                              border: "1px solid #4a4a4a",
                            }}
                          >
                            {({ blob, url, loading, error }) =>
                              loading || loadingConsolidated
                                ? "Loading document..."
                                : "Download Pdf"
                            }
                          </PDFDownloadLink>
                        </>
                      ))}
                  </div>
                  <div className="col-md-12">
                    <div className="panel">
                      <div className="panel-body">
                        <Loading visible={loadingStudent} />
                        <table className="table">
                          <thead>
                            <tr>
                              <th>
                                <FormattedMessage
                                  id="form.number"
                                  defaultMessage="No"
                                />
                              </th>
                              <th>
                                <FormattedMessage id="form.student.name" />
                              </th>
                              <th>
                                <FormattedMessage id="form.student.address" />
                              </th>
                              <th>
                                <FormattedMessage id="form.classroom.name" />
                              </th>
                              <th>
                                <FormattedMessage id="component.text.assessment" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loadingStudent &&
                              studentList &&
                              studentList.length > 0 &&
                              studentList.map((student, i) => (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{student.name}</td>
                                  <td>{student.address}</td>
                                  <td>{student.classroom.name}</td>
                                  <td>
                                    <button
                                      className="btn btn-info btn-sm mr-1"
                                      onClick={() =>
                                        doPrint(
                                          `/student-report/subject/${student.id}`
                                        )
                                      }
                                    >
                                      <FormattedMessage id="component.text.assessment-subject" />
                                    </button>
                                    {(term === "1" || term === "2") && (
                                      <button
                                        className="btn btn-primary btn-sm mr-1"
                                        onClick={() =>
                                          doPrint(
                                            `/student-report/personality/${student.id}`,
                                            "personality"
                                          )
                                        }
                                      >
                                        <FormattedMessage id="component.text.assessment-personality" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
