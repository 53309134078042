import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import BaseSelect from 'react-select';

import { Users } from '../services';
import Constants from '../components/Constants';
import RequiredSelect from '../components/RequiredSelect';

const Select = props => (
    <RequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);
require('dotenv').config();
const users = new Users();

export default function Login(props) {
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        username: '',
        password: '',
        role: ''
    });

    const [showPassword, setShowPassword] = useState(false);

    const [invalidPassword, setInvalidPassword] = useState(false);
    const history = useHistory();

    const handlePayload = (e, prop) => {
        payload[prop] = prop === 'role' ? e.value : e.target.value;

        setPayload(payload);
    }

    const login = () => {
        setLoading(true);
        users.login(payload)
        .then(res => {
            console.log(res);
            if (res.status === 401) {
                setInvalidPassword(true);
            }
            if (res.status === 200) {
                setLoading(false);
                window.localStorage.setItem('token', `Bearer ${res.token}`);
                window.localStorage.setItem('user-data', JSON.stringify(res.data));
                if(res.data.role === "Admin"){
                    history.push("/dashboard-admin");
                }
                else if(res.data.role === "Guru Mapel"){
                    history.push("/dashboard-teacher");
                }
                else if(res.data.role === "Siswa" || res.data.role === "Orang Tua"){
                    history.push("/dashboard-student");
                }
                else if(res.data.role === "Wali Kelas"){
                    history.push("/dashboard-homeroom");
                }
                else {
                    history.push("/");

                }
            }
        })
        .catch(err => {
            console.error(err);
            setInvalidPassword(true);
            setLoading(false);
        })
    }
    const handleKeyPress = e => {
        if (e.key === "Enter") {
            login();
        }
      }
    
    return (

        <div>
            <div className="vertical-align-wrap">
                <div className="vertical-align-middle">
                    <div className="auth-box ">
                        <div className="left">
                            <div className="content">
                                <div className="header">
                                    <div className="logo text-center"><img src="assets/img/logo/logo MGS.png" width="160px" style={{marginTop:30}} alt="logo"/></div>
                                </div>
                                    <form 
                                        onSubmit={(e) => {e.preventDefault();login();} }  
                                        className="form-auth-small">
                                        {
                                        invalidPassword
                                        ?
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                    <FormattedMessage id="pages.login.invalid"/>
                                                </div>
                                            </div>
                                        :   null
                                        }                        
                                        <div className="form-group">
                                            <label htmlFor="signin-username" className="control-label sr-only">Email</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="signin-username" 
                                                placeholder="Username"
                                                required={true}
                                                onChange={(e) => handlePayload(e, 'username')}/>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-10">                                    
                                                    <label htmlFor="signin-password" className="control-label sr-only">Password</label>
                                                    <input 
                                                        type={showPassword ? 'text' : 'password'} 
                                                        className="form-control" 
                                                        id="signin-password" 
                                                        placeholder="Password"
                                                        required={true}
                                                        onChange={(e) => handlePayload(e, 'password')} onKeyPress={handleKeyPress}/>
                                                </div>
                                                <div className="col-md-2">
                                                    <button 
                                                        type="button" 
                                                        className="btn-block btn" 
                                                        style={{ marginTop: 0}}
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}  />
                                                    </button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="form-group">
                                            <Select
                                                placeholder={"Choose the role"}
                                                options={Constants.USER_ROLE}
                                                onChange={(e) => handlePayload(e, 'role')}
                                                required                               
                                            />
                                        </div>
                                        <button 
                                            disabled={loading}
                                            type="submit" 
                                            className="btn btn-primary btn-lg btn-block" 
                                            style={{marginBottom:30}}>                                       
                                                <FormattedMessage id="component.login.button"/>
                                        </button>
                                    </form>
                            </div>
                        </div>
                        <div className="right">
                            <div className="overlay" />
                                <div className="content text">
                                    <h1 className="heading">System E-Rapot</h1>
                                    <p>{process.env.REACT_APP_NAME}</p>
                                </div>
                        </div>
                    <div className="clearfix" />
                    </div>
                </div>
            </div>
        </div>
    )
}
