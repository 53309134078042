import React, { useState, useEffect, useRef, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Constants, Loading } from '../../components';
import { Users } from '../../services';
import { MDBDataTable } from 'mdbreact';
import ReactPaginate from 'react-paginate';
import { FormattedMessage } from "react-intl";

import { MaintenanceContext } from '../../Maintenance';

const users = new Users();

export default function User(props) {
    const selectInputRef = useRef();
    useEffect(() => {
        (async () => getUserList())();
    }, []);

    const user = JSON.parse(window.localStorage.getItem('user-data'));
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

    const [to, setTo] = useState(0);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        id: '',
        username: '',
        password: '',
        status: true,
        role: Constants.USER_ROLE[0].value,
        created_by: user.username,
        updated_by: user.username
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 5,
        search: '',
        total: 0,
        totalPage: 1
    });
    const [userList, setUserList] = useState([]);
    const [mode, setMode] = useState('Add');
    const _handleSearch = (v) => {
        if (to) {
          clearTimeout(to);
        }
        setTo(
          setTimeout(() => {
            pagination.search = v;
            setPagination(pagination);
            getUserList();
          }, 500)
        );
      };
    const getUserList = () => {
        setLoading(true);
        users.list(pagination)
        .then(res => {
            console.log(res);
            pagination.limit = res.limit;
            pagination.total = res.total;
            pagination.totalPage = res.total % res.limit === 0 ? (res.total / res.limit) : Math.floor(res.total / res.limit) + 1

            res.result.map( (item, i) => {
                let nums = parseInt(pagination.total - pagination.limit);
                let numRows = (pagination.page - 1) * (pagination.total - nums) + i;

                res.result[i].no = numRows + 1;
                res.result[i].status_label = 
                    <>
                        <p className="notification-item">
                            <span className={item.status ? 'dot bg-success' : 'dot bg-danger'} />
                            {item.status ? <FormattedMessage id="form.user.active"/> : <FormattedMessage id="form.user.deactive"/>}
                        </p>
                    </>
                res.result[i].option = 
                    <>
                        {res.result[i].role == Constants.USER_ROLE[0].value && <button 
                            className="btn btn-warning btn-sm mr-1" 
                            data-toggle="modal" 
                            data-target="#addModal" 
                            onClick={() => getUserInfo(item)}> 
                                <i className="lnr lnr-pencil"></i>
                                <FormattedMessage id="component.edit.button"/>
                        </button>}
                        <button 
                            className="btn btn-danger btn-sm" 
                            data-toggle="modal" 
                            data-target="#deleteModal"
                            onClick={() => getUserInfo(item)}> 
                                <i className="lnr lnr-trash"></i> 
                                <FormattedMessage id="component.delete.button"/>
                        </button>
                        <button 
                            className={`btn btn-${item.status ? 'danger' : 'success'} btn-sm ml-1`} 
                            onClick={() => manageUser(item)}> 
                                {item.status ? <FormattedMessage id="form.user.active"/> : <FormattedMessage id="form.user.deactive"/>}
                        </button>
                        <button 
                            className="btn btn-success btn-sm ml-1" 
                            data-toggle="modal" 
                            data-target="#addModal" 
                            onClick={() => getUserInfo(item, 'Change Password')}> 
                                <i className="lnr lnr-pencil"></i>
                                <FormattedMessage id="component.change-password.button"/>
                        </button>
                    </>
            })

            setPagination(pagination);
            setUserList(res.result);
            setLoading(false);
        })
        .catch(err => {
            console.error(err);

            if (err.code === 404) {
                setUserList([]);
                setPagination({
                    page: 0,
                    limit: 5,
                    search: '',
                    total: 0,
                    totalPage: 1
                });
            }
            if (err.status === 503) {
                setIsMaintenance(true)
            }
            setLoading(false);
        });
    };

    const handlePayload = (e, state) => {
        if (!e) {
            return false;
        }
        setPayload({
            ...payload,
            [state]: state === 'role' ? e.value : e.target.value
        });
    }

    const createUser = () => {
        setLoading(true);
        users.create(payload)
        .then(res => {
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                reset();

                getUserList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }
    
    const updateUser = () => {
        setLoading(true);
        users.update(payload)
        .then(res => {
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                reset();

                getUserList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }
    
    const deleteUser = () => {
        setLoading(true);
        users.delete(payload.id)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                reset();

                getUserList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }

    const manageUser = (payload) => {
        setLoading(true);
        payload.status = !payload.status;
        users.manageUser(payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                reset();

                getUserList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }

    const changePassword = () => {
        setLoading(true);
        users.changePassword(payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                reset();

                getUserList();
            }
        })
        .catch(err => {
            console.error(err)
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }

    const getUserInfo = (userInfo, mode = "Edit") => {
        payload.id = userInfo.id;
        payload.username = userInfo.username;
        payload.updated_by = userInfo.created_by;
        payload.status = userInfo.status;
        payload.role = userInfo.role;
        
        setPayload(payload);
        setMode(mode);
    }
    

    const reset = () => {
        setPayload({
            id: '',
            username: '',
            password: '',
            status: true,
            role: '',
            created_by: user.username,
            updated_by: user.username
        });
    }

    // handle pagination
    const _handleChangePage = e => {
        const { selected } = e;
        pagination.page = selected + 1;
        
        setPagination(pagination);
        getUserList();

    }

    const data = {
        columns: [
          {
            label: <FormattedMessage id='form.number' defaultMessage='No'/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Username',
            field: 'username',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id="form.user.role"/>,
            field: 'role',
            sort: 'asc',
            width: 200
          },
          {
            label: 'Status',
            field: 'status_label',
            sort: 'asc',
            width: 100
          },
          {
            label: <FormattedMessage id="form.options"/>,
            field: 'option',
            sort: 'asc',
            width: 100
          },
        ],
        rows: userList
    };

    const { page, totalPage } = pagination;
    return (
            <div className="main">
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar
                    pauseOnHover={false} />
                {/* MAIN CONTENT */}
                <div className="main-content">
                    {user.role === 'Admin' && (
                    
                    <div className="container-fluid">
                        {ismaintenance ? (
    <div className="text-center">
        <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
        <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
    </div>
)  : (
                             <>
                        <h3 className="page-title">
                            <FormattedMessage id="menu.user"/>
                        </h3>
                        <div className="row">
                            <div className="col-md-12">
                                {/* BASIC TABLE */}
                                <div className="panel">
                                    <div className="panel-header mt-2 mr-2">
                                        <button 
                                            className="btn btn-primary" 
                                            data-toggle="modal" 
                                            data-target="#addModal" 
                                            style={{float : 'right'}} 
                                            onClick={() => setMode('Add')}> 
                                                <FormattedMessage id="component.add.button"/>
                                        </button>
                                    </div>
                                    <div className="panel-body">
                                        <Loading visible={loading}/>
                                        <div className="row mt-4">
                                            <div className="col-md-3" style={{ float: "right" }}>
                                                Search:{" "}
                                                <input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => _handleSearch(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <MDBDataTable
                                            searching={false}
                                            className="table mt-5"
                                            data={data}
                                            sortable
                                            noBottomColumns    
                                            entriesOptions={[5, 10, 20, 50]}
                                            displayEntries={true}
                                            paging={false}
                                            noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>}                                                                                                                
                                        />
                                       
                                        <ReactPaginate
                                            // className="d-flex justify-content-center"
                                            breakLabel={'...'}
                                            pageCount={totalPage}
                                            marginPagesDisplayed={1}
                                            containerClassName={'pagination'}
                                            activeClassName={'active'}
                                            initialPage={page}
                                            onPageChange={_handleChangePage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </> 
                        )}
                    </div>
                    )}
            </div> 
            <Modal 
            title={mode === 'Add' ? 'Add Admin User' : 'Edit User'}
            label={mode}
            form={
                (
                    <>
                        {mode === 'Change Password'
                            ?
                            <>                                
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="required">New Password</label>
                                        <input 
                                            required
                                            type="password" 
                                            value={payload.password}
                                            className="form-control" 
                                            onChange={(e) => handlePayload(e, 'password')} />
                                    </div>                           
                                </div>    
                            </>          
                            :
                            <>
                                <div className={mode === 'Add' ? 'col-md-6' : 'col-md-12'}>
                                    <div className="form-group">
                                        <label className="required">Username</label>
                                        <input 
                                            required
                                            type="text" 
                                            value={payload.username}
                                            className="form-control" 
                                            onChange={(e) => handlePayload(e, 'username')} />
                                    </div>
                                </div>
                                {mode === 'Add' && (
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="required">Password</label>
                                            <input 
                                                required
                                                type="password" 
                                                value={payload.password}
                                                className="form-control" 
                                                onChange={(e) => handlePayload(e, 'password')} />
                                        </div>                           
                                    </div>
                                )}        
                            </>                
                        }
                    </>
                )
            }
            onSubmit={(e) => {e.preventDefault();mode === 'Add' ? createUser() : mode === 'Edit' ? updateUser() : changePassword();}}
            onClose={() => reset()}
            onDelete={() => deleteUser()}
            disabled={loading}/>           
        </div>        
    )
}
