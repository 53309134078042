import React, { useState, useEffect, useContext} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Helpers, Loading } from '../../components';
import { Sessions } from '../../services';
import { MDBDataTable } from 'mdbreact';
import { FormattedMessage } from "react-intl";
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import { MaintenanceContext } from '../../Maintenance';
moment.locale('id');

const sessions = new Sessions();
const helpers = new Helpers();

export default function Session(props) {

    useEffect(() => {
        (async () => getSessionList())();
    }, []);

    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

    const user = JSON.parse(window.localStorage.getItem('user-data'));
    const [loading, setLoading] = useState(false);
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [payload, setPayload] = useState({
        id: '',
        name: '',
        created_by: user.username,
        updated_by: user.username
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 5,
        search: '',
        total: 0,
        totalPage: 1
    });

    const [sessionList, setSessionList] = useState([]);
    const [mode, setMode] = useState('Add');

    const getSessionList = () => {
        setLoading(true);

        sessions.list(pagination)
        .then(res => {
            console.log('res: ', res);
            pagination.limit = res.limit;
            pagination.total = res.total;
            pagination.totalPage = res.total % res.limit === 0 ? (res.total / res.limit) : Math.floor(res.total / res.limit) + 1

            res.result.map( (item, i) => {
                let nums = parseInt(pagination.total - pagination.limit);
                let numRows = (pagination.page - 1) * (pagination.total - nums) + i;

                res.result[i].no = numRows + 1;
                res.result[i].date_of_birth = helpers.formatDate(item.date_of_birth, 'LL')
                res.result[i].option = 
                <>
                    <button 
                        className="btn btn-warning btn-sm mr-1" 
                        data-toggle="modal" 
                        data-target="#addModal"
                        onClick={() => getSessionInfo(item)}> 
                            <i className="lnr lnr-pencil"></i> 
                            <FormattedMessage id="component.edit.button"/>
                    </button>
                </>
                res.result[i].created_at = helpers.formatDate(item.created_at);
            });        

            setPagination(pagination);
            setSessionList(res.result);
            setLoading(false);
            setLoadingOverlay(false);
        })
        .catch(err => {
            console.error(err);

            if (err.code === 404) {
                setSessionList([]);
            }
            if (err.status === 503) {
                setIsMaintenance(true)
            }
            setLoading(false);
        });
    };

    const handlePayload = (e) => {        
        setPayload({
            ...payload,
            name: e.target.value
        });
    }

    const createSession = () => {
        setLoadingOverlay(true);
        sessions.create(payload)
        .then(res => {
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id="component.text.add-successful"/>);

                reset();
                setLoadingOverlay(false);

                window.location.reload();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>);
            setLoadingOverlay(false);
        });
    }
    
    const updateSession = () => {
        setLoading(true);
        sessions.update(payload)
        .then(res => {
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                reset();
                getSessionList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }
    
    const deleteSession = () => {
        setLoading(true);
        sessions.delete(payload.id)
        .then(res => {
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                reset();
                getSessionList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }

    const getSessionInfo = (sessionInfo) => {
        setPayload({
            id: sessionInfo.id,
            name: sessionInfo.name,
            updated_by: payload.created_by,
            created_by: payload.created_by,
        });
        setMode('Edit');
    }

    const reset = () => {
        setPayload({
            ...payload,
            name: '',
        });
    }

    // handle pagination
    const _handleChangePage = e => {
        const { selected } = e;
        pagination.page = selected + 1;
        
        setPagination(pagination);
        getSessionList();

    }

    const renderModalTitle = mode => {
        switch(mode) {
            case 'Add':
                return <FormattedMessage id="component.add.button"/>;
            case 'Edit':
                return <FormattedMessage id="component.edit.button"/>;
            default:
                return 'Import'
        }
    }

    const renderModalSubmit = mode => {
        switch(mode) {
            case 'Add':
                createSession()
                break;
            case 'Edit':
                updateSession()
                break;
            default:
                break;
        }
    }    

    const data = {
        columns: [
          {
            label: <FormattedMessage id='form.number' defaultMessage='No'/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: <FormattedMessage id="form.session.name"/>,
            field: 'name',
            sort: 'asc',
            width: 270
          },          
          {
            label: <FormattedMessage id="form.options"/>,
            field: 'option',
            sort: 'asc',
            width: 100
          },
        ],
        rows: sessionList
    };

    const { page, totalPage } = pagination;
    return (

        <div className="main">
            <ToastContainer
                autoClose={5000}
                hideProgressBar
                pauseOnHover={false} />

            <LoadingOverlay                                       
                active={loadingOverlay}
                spinner
                text='Please wait a few minutes'
            >
            
                    <div className="main-content" style={{ height: 950 }}>
                        {user.role === 'Admin' && (

                            <div className="container-fluid">
                                 {(
                                <>
                                <h3 className="page-title"><FormattedMessage id="menu.teacher" /></h3>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="panel">
                                            <div className="panel-header mt-2 mr-2">
                                                <div className="row">
                                                    <div className="col-md-6"></div>
                                                    
                                                    <div className="col-md-6">
                                                        <button 
                                                            className="btn btn-primary pr-3" 
                                                            data-toggle="modal" 
                                                            data-target="#warning" 
                                                            style={{float : 'right'}} 
                                                            onClick={() => setMode('Add')}> 
                                                            <FormattedMessage id="component.add.button"/>
                                                        </button>                                                
                                                    </div>
                                                </div>                                    
                                            </div>
                                            <div className="panel-body">                                    
                                                <Loading visible={loading} />

                                                <MDBDataTable
                                                    className="table mt-5"
                                                    data={data}
                                                    sortable
                                                    noBottomColumns    
                                                    entriesOptions={[5, 10, 20, 50]}
                                                    displayEntries={true}
                                                    paging={false}
                                                    noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>} 
                                                                                                                            
                                                />
                                                <ReactPaginate
                                                    // className="d-flex justify-content-center"
                                                    breakLabel={'...'}
                                                    pageCount={totalPage}
                                                    marginPagesDisplayed={1}
                                                    containerClassName={'pagination'}
                                                    activeClassName={'active'}
                                                    initialPage={page}
                                                    onPageChange={_handleChangePage}
                                                />
                                        </div>
                                    </div>                    
                                </div>
                            </div>
                            </>
                        )}
                        </div>
                        )}
                </div>
                <Modal 
                    title={renderModalTitle(mode)}
                    label={mode}
                    form={                
                        (
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="required"><FormattedMessage id="form.session.name" /></label>
                                        <input 
                                            type="text" 
                                            value={payload.name}
                                            className="form-control"
                                            required
                                            onChange={(e) => handlePayload(e)} />
                                    </div>   
                                </div>                                              
                            </div>
                        )
                    }
                    onSubmit={(e) => {e.preventDefault();renderModalSubmit(mode);}}
                    onClose={() => reset()}
                    onDelete={() => deleteSession()}
                    disabled={loading}
                />
                {/* Modal */}
                <div className="modal fade" id="warning" tabIndex={-1} aria-labelledby="warningLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" data-toggle="modal">
                                <span aria-hidden="true">×</span>
                                </button>
                                <h5 className="modal-title" id="warningLabel">
                                    <FormattedMessage id="xxx" defaultMessage="Warning"/>    
                                </h5>
                            </div>
                            <div className="modal-body">
                                <p>
                                    When adding a new session, the data for the current session will be deleted immediately.

                                    Continue?
                                </p>
                                                        
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                    <FormattedMessage id="component.close.button"/>
                                </button>
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-dismiss="modal" data-target="#addModal">
                                    <FormattedMessage id="component.next.button"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
            
    </div> 
    )
}
