export default {
    'component.login.button': 'Masuk',
    'component.add.button': 'Tambah',
    'component.edit.button': 'Ubah',
    'component.delete.button': 'Hapus',
    'component.close.button': 'Tutup',
    'component.list-subject.button': 'Daftar Pelajaran',
    'component.back.button': 'Kembali',
    'component.save.button': 'Simpan',
    'component.next.button': 'Lanjutkan',
    'component.change-password.button': 'Ubah Password',

    'component.text.choose-file': 'Pilih File',
    'component.text.confirm-delete-title': 'Perhatian',
    'component.text.confirm-delete': 'Apakah kamu yakin menghapus data ini?',
    'component.text.no-records': 'Data tidak ditemukan',
    'component.text.information': 'Informasi',

    'component.text.add-successful': 'Data berhasil ditambahkan',
    'component.text.edit-successful': 'Data berhasil diubah',
    'component.text.delete-successful': 'Data berhasil dihapus',
    'component.text.save-successful': 'Data berhasil disimpan',
    'component.text.add-failed': 'Data gagal ditambahkan',
    'component.text.edit-failed': 'Data gagal diubah',
    'component.text.delete-failed': 'Data gagal dihapus',
    'component.text.save-failed': 'Data gagal disimpan',
    'component.text.allow-access-report': 'Izinkan Melihat Laporan',
    'component.text.dont-allow-access-report': 'Jangan Izinkan Melihat Laporan',

    'component.text.term': 'Semester',
    'component.text.session': 'Tahun ajaran',
    'component.text.mid-term': 'Tengah Semester',
    'component.text.not-allowed-input-score': 'Belum diperbolehkan memasukkan data ini. Harap hubungi Administrator untuk memberikan izin',
    'component.text.assessment': 'Penilaian',
    'component.text.assessment-subject': 'Mata Pelajaran',
    'component.text.assessment-personality': 'Kepribadian',

    'component.text.waiting-data': 'Sedang memuat data...',
    'component.text.cannot-print': 'Tidak dapat mencetak Ujian untuk saat ini, silahkan hubungi Administrator',
    'component.text.rule.grade': 'Hanya dapat menilai A, B, C, D, and E',
    'component.list-student.button': 'Daftar siswa',
    'component.text.classroom.student.list': 'Daftar siswa kelas ',
    'component.text.confirm-password-invalid': 'Password baru dan konfirmasi password tidak sama',
    'component.text.invalid-password': 'Password tidak sesuai, harap periksa kembali'

    
}