import React, { useState, useEffect, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Configurations } from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { FormattedMessage } from "react-intl";
import { Constants, Loading } from '../../components';
import moment from 'moment';
import { MaintenanceContext } from '../../Maintenance';

moment.locale('id');

const configurations = new Configurations();


export default function Configuration(props) {
    useEffect(() => {
        (async () => getConfigurationList())();
    }, []);

    const token = window.localStorage.getItem('token');
    const user = JSON.parse(window.localStorage.getItem('user-data'));
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

    const [loading, setLoading] = useState(false);
    const [loadingBackup, setLoadingBackup] = useState(false);
    
    const [configurationList, setConfigurationList] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');

    const getConfigurationList = () => {
        setLoading(true);
        configurations.list()
        .then(res => {
            console.log(res);
            res.result.map( (item, i) => {
                if(item.id !== Constants.CONFIGURATION.SETTING_PRINCIPAL) {

                    res.result[i].value.created_at = moment(res.result[i].value.created_at).format('YYYY-MM-DD');
                    res.result[i].value.end_at = moment(res.result[i].value.end_at).format('YYYY-MM-DD');
                }
            })
            setConfigurationList(res.result);
            setLoading(false);
        })
        .catch(err => {
            console.error(err);

            if (err.code === 404) {
                setConfigurationList([]);
            }
            if (err.status === 503) {
                setIsMaintenance(true)
            }
            setLoading(false);
        });
    };

    const updateSchedule = (id) => {
        setLoading(true);
        const params = configurationList.filter(item => item.id === id)[0];        
        configurations.update(params)
        .then(res => {
            console.log(res);
            toast.success(<FormattedMessage id='component.text.edit-successful'/>);
            setLoading(false);
        })
        .catch(err => {
            console.error(err);
            toast.error(<div>{<FormattedMessage id='component.text.edit-successful'/>}<br/><br/>{JSON.stringify(err)}</div>)

            if (err.code === 404) {
                setConfigurationList([]);
            }
            setLoading(false);
        });
    }

    const handleDateChange = (e, index, state, isPrincipal = false) => {        
        let value = '';
        if(isPrincipal) {
            value = e;
        } else {
            if (e !== null) {
                value = moment(e._d).format('YYYY-MM-DD');
            }    
        }

        configurationList[index]['value'][state] = value;
        configurationList[index]['updated_by'] = user.username;

        console.warn(configurationList);
        setSelectedDate(value);
        
        setConfigurationList(configurationList)
    };


    return (
        <div>                                       
            <div className="main">  
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar
                    pauseOnHover={false} />          
                <div className="main-content">
                    {user.role === 'Admin' && (
                    
                        <div className="container-fluid">

                            {ismaintenance ? (
    <div className="text-center">
        <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
        <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
    </div>
)  : (
                                <>
                                <h3 className="page-title">
                                    <FormattedMessage id="menu.configuration"/>
                                </h3>                                
                                <div className="row">
                                    
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="panel">
                                                        <div className="panel-body">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="text-center pt-2 pb-2">
                                                                    <h3 className="page-title">
                                                                        <FormattedMessage id="menu.backup"/>
                                                                    </h3>
                                                                        <img src="assets/img/backup.svg" className="img-responsive" style={{width:'200px', margin: 'auto'}}/>
                                                                    </div>
                                                                    <a className="btn btn-primary btn-block" href={`${process.env.REACT_APP_URL_SERVICE}/configuration/backup?token=Bearer${token}`} download>
                                                                    <FormattedMessage id="menu.backup"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                </div>
                            {configurationList && configurationList.length > 0 && configurationList.map((item, i) => (
                                item.id === Constants.CONFIGURATION.SETTING_PRINCIPAL 
                                ?
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="panel">
                                            <div className="panel-heading">
                                                <h3 className="panel-title"><FormattedMessage id="menu.principal-configur"/></h3>
                                                <div className="right">
                                                    <button 
                                                        className="btn-toggle" 
                                                        type="button" 
                                                        data-toggle="collapse" 
                                                        data-target="#principal" 
                                                        aria-expanded="false" >
                                                        <i className="lnr lnr-chevron-down"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="collapse" id="principal">
                                                <div className="card card-body">
                                                    <div className="row pl-2 pr-2 pb-3 pt-3">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-group">
                                                                        <label>  <FormattedMessage id="form.principal-name"/></label>
                                                                        <input type="text" className="form-control" onChange={e => handleDateChange(e.target.value, i, 'created_at', true)} value={item.value.created_at || item.value.start_at}/>
                                                                    </div>
                                                                
                                                                </div>
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-group">
                                                                        <label><FormattedMessage id="form.foundation-name"/></label>
                                                                        <input type="text" className="form-control" onChange={e => handleDateChange(e.target.value, i, 'end_at', true)} value={item.value.end_at}/>
                                                                    </div>
                                                                
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <button className="btn btn-success btn-block" onClick={() => updateSchedule(item.id)}><FormattedMessage id="component.save.button"/></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="row" key={item.id}>
                                    
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="panel">
                                            <div className="panel-heading">
                                                <h3 className="panel-title">{item.name}</h3>
                                                <div className="right">
                                                    <button 
                                                        className="btn-toggle" 
                                                        type="button" 
                                                        data-toggle="collapse" 
                                                        data-target={`#item-${i}`} 
                                                        aria-expanded="false" 
                                                        aria-controls="getById">
                                                        <i className="lnr lnr-chevron-down"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="collapse" id={`item-${i}`}>
                                                <div className="card card-body">
                                                    <div className="row pl-2 pr-2 pb-3 pt-3">
                                                        <div className="col-md-12">
                                                            <p style={{textAlign: 'left'}}>
                                                                <FormattedMessage id="form.configuration.input-score"/>
                                                            </p>
                                                            <div className="form-group">
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>

                                                                    <DatePicker
                                                                        fullWidth
                                                                        maxDate={item.value.end_at}
                                                                        clearable={true}
                                                                        inputVariant="outlined"
                                                                        label={<FormattedMessage id="form.configuration.from"/>}
                                                                        value={item.value.created_at}
                                                                        autoOk={true}
                                                                        format="Do MMM yyyy"
                                                                        onChange={e => handleDateChange(e, i, 'created_at')}
                                                                    />
                                                                </MuiPickersUtilsProvider>                                                            
                                                            </div>
                                                            <div className="form-group">
                                                            <MuiPickersUtilsProvider 
                                                                utils={MomentUtils} 
                                                                libInstance={moment} 
                                                                className="form-control">

                                                                <DatePicker
                                                                    fullWidth
                                                                    minDate={item.value.created_at}
                                                                    clearable={true}
                                                                    inputVariant="outlined"
                                                                    label={<FormattedMessage id="form.configuration.finish"/>}
                                                                    value={item.value.end_at}
                                                                    autoOk={true}
                                                                    format="Do MMM yyyy"
                                                                    onChange={e => handleDateChange(e, i, 'end_at')}
                                                                    disabled={item.value.created_at ? false : true}
                                                                />
                                                            </MuiPickersUtilsProvider>  
                                                            </div>
                                                            <button 
                                                                className="btn btn-success mt-3 btn-block"
                                                                onClick={() => updateSchedule(item.id)}
                                                                disabled={
                                                                    (new Date(item.value.created_at).getTime() > new Date(item.value.end_at).getTime()) && loading
                                                                    ? true 
                                                                    : false
                                                                }>
                                                                    Edit / Save
                                                                
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                
                                    </div>
                                </div>                        
                            ))}
                               </> 
                            )}
                        </div>
                        
                        )}
                </div>
            </div>
        </div>
    )
}
