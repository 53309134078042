export default {
    'menu.teacher': 'Halaman Guru',
    'menu.student': 'Halaman Siswa',
    'menu.classroom': 'Halaman Kelas',
    'menu.religion': 'Halaman Pengaturan Agama',
    'menu.session': 'Halaman Tahun Ajaran',
    'menu.subject': 'Halaman Mapel',
    'menu.user': 'Halaman Pengguna',
    'menu.configuration': 'Pengaturan',
    'menu.personality': 'Halaman nilai kepribadian',
    'menu.language': 'Bahasa',
    'menu.change-password': 'Ubah Password',
    'menu.logout': 'Keluar',
    // Wali kelas
    'menu.personality-mark': 'Penilaian sikap',
    'menu.student-report': 'Laporan siswa',
    // Guru
    'menu.input-score': 'Pemberian Nilai',
    'menu.attendance': 'Kehadiran',
    'menu.termly-result': 'Hasil Semester',
    // Siswa
    'menu.my-report': 'Laporanku',
    'menu.backup': 'Cadangkan',

    'menu.principal-configur': 'Konfigurasi Utama',
}