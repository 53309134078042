import React, { useState, useEffect, useRef, useContext} from 'react';
import { Personalities, Students, PersonalityMark, Classrooms } from '../../services';
import Constants from '../../components/Constants';
import { Loading } from '../../components';
import Select from 'react-select';
import { FormattedMessage } from "react-intl";
import { ToastContainer, toast } from 'react-toastify';
import { MDBDataTable } from 'mdbreact';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MaintenanceContext } from '../../Maintenance';

import 'react-toastify/dist/ReactToastify.css';

const personalities = new Personalities();
const personality_marks = new PersonalityMark();
const students = new Students();
const classrooms = new Classrooms();

export default function PersonalityMarks(props) {
    const personalityRef = useRef();
    const attendanceRef = useRef();
    const termlyRef = useRef();


    useEffect(() => {
        (async () => getPersonalityList())();
        (async () => getClassroomList())();
    }, []);

    const user = JSON.parse(window.localStorage.getItem('user-data'));
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

    const [loadingMark, setLoadingMark] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingStudent, setLoadingStudent] = useState(false);
    const [loadingClassroom, setLoadingClassroom] = useState(false);

    const genericPayload = {
        personality_id: '',
        remarks: 1,
    }
    const [payload, setPayload] = useState({
        term: 1,
        data: [],
        created_by: user.username
    });
    const [personalityList, setPersonalityList] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const [student, setStudent] = useState({});
    const [personalityStudent, setPersonalityStudent] = useState([]);
    const [currentScore, setCurrentScore] = useState();
    // generic
    const [classroomList, setClassroomList] = useState([]);
    const [termlyClassroomList, setTermlyClassroomList] = useState([]);
    const [mode, setMode] = useState('personality');
    const [selectedDate, setSelectedDate] = useState('');
    // ATTENDANCE PAYLOAD
    let [attPayload, setAttPayload] = useState({
        classroom_id: '',
        term: 1,
        max_attend:0,
        created_by: user.username,
        data:[]
    })
    // TERMLY PAYLOAD
    const [termlyPayload, setTermlyPayload] = useState({
        classroom_id: '',
        term: 1,
        session: '',
        created_by: user.username,
        data: []
    }); 

    const getPersonalityList = () => {
        setLoading(true);
        const pagination = {
            limit: 100
        }
        personalities.list(pagination)
        .then(res => {
            console.log('personality_list: ', res);
            let p = [];
            res.result.map( item => {
                genericPayload.personality_id = item.id;
                

                p = [ ...p, { ...genericPayload }];
            });
            
            payload.data = p;

            setPayload(payload);
            setPersonalityList(res.result);
            setLoading(false);
        })
        .catch(err => {
            console.error(err);

            if (err.code === 404) {
                setPersonalityList([]);
            }
            if (err.status === 503) {
                setIsMaintenance(true)
            }
            setLoading(false);
        });
    };


    const getPersonalityMarkStudent = (studentInfo) => {
        if (studentInfo && studentInfo.id) {

            setLoading(true);
            setStudent(studentInfo);
    
            personality_marks.list(studentInfo.id)
            .then(res => {
                console.log('res: ', res);
    
                setPersonalityStudent(res.result);    
                renderCurrentScore(payload.term, res.result);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);   
                if (err.code === 404) {
                    setPersonalityStudent([]);
                    setCurrentScore([])
                }
                setLoading(false);
            });
        }
    }

    const getClassroomList = () => {
        setLoadingClassroom(true);
        classrooms.list({ limit: 100 })
        .then(res => {
            console.log(res);

            res.result.map((item, index) => {
                res.result[index].value = item.id;
                res.result[index].label = item.name;
            });
            setTermlyClassroomList(res.result);

            const classroom = res.result.filter(item => item.teacher.id === user.teacher_data.id);
            
            setClassroomList([...classroom]);
            setLoadingClassroom(false);
        })
        .catch(err => {
            console.error(err);
            setLoadingClassroom(false);
        })
    }

    const getStudentByClassroom = e => {
        if (e && e.id) {

            setLoadingStudent(true);
            students.getStudentByClassroom(e.id)
            .then(res => {
                console.log(res);
                let data = [];
                if (mode === 'personality') {
                    res.result.map((item, i) => { 
    
                        res.result[i].value = item.id;
                        res.result[i].label = item.name;
                    });
    
                } else if (mode === 'attendance') {
                    
                    res.result.map((item, i) => {
                        
                        const p = {};
        
                        p.student_id = item.id;
                        p.attending = 0;
        
                        data = [...data, { ...p }];                
                    });
    
                    attPayload.data = data;       
                    attPayload.classroom_id = e.id;
    
                    console.log('attPayload', attPayload) 
                    setAttPayload(attPayload);
    
                } else {
    
                    res.result.map((item, i) => {                
        
                        const p = {};
                        p.student_id = item.id;
                        p.promoted_to = '';
                        p.compartment_in = '';
                        p.detained_in = '';
                        p.class_teacher_comment = '';
                        p.co_curricular_comment = '';
                        p.final_result = '';
        
                        data = [...data, { ...p }];
                    });
                    termlyPayload.classroom_id = e.id;
                    termlyPayload.data = data;
        
                    console.log('termlyPayload: ', termlyPayload);
                    setTermlyPayload(termlyPayload);
                }
                setStudentList(res.result);
                setLoadingStudent(false);
            })
            .catch(err => {
                console.error(err);
                setLoadingStudent(false);
            })
        }
    }

    const handlePayload = (e, index = 0, state = 'score', type = 'personality') => {
        console.log(e, index, state, type);
        switch(type) {
            case 'personality':
                let v = parseInt(e.target.value);
                if (state === 'term') {
                    payload.term = v;
                    
                    if (personalityStudent && personalityStudent.length > 0) {
                        renderCurrentScore(v, personalityStudent);
                    }
        
                } else {
                    payload.data[index].remarks = v;
                }
                
                console.log('v: ', v)
                setPayload(payload)
                break;
            case 'attendance':
                const attVal = e;

                if (state === 'term' || state === 'max_attend') {
                    
                    attPayload[state] = parseInt(attVal);                                                            
                } else {
                    
                    attPayload.data[index][state] = parseInt(attVal);
                }     
                console.log('attPayload: ', attPayload);
                setAttPayload(attPayload);
                break;
            case 'termly':
                const tp = { ...termlyPayload };
                let val = e;

                if (state === 'session' || state === 'term') {

                    if (e !== null && state === 'session') {
                        val = moment(e._d).format('YYYY-MM-DD');
                    } 
                    tp[state] = val;

                    setSelectedDate(val);
                    setTermlyPayload(tp);
                } else if (state === 'final_result') {      
                                      
                    tp.data[index].promoted_to = '';
                    tp.data[index].compartment_in = '';
                    tp.data[index].detained_in = '';   
                    
                    tp.data[index][e] = e;
                } else if (state === 'to') {

                    const cat = tp.data[index].promoted_to || tp.data[index].compartment_in || tp.data[index].detained_in;
                    tp.data[index][cat] = e;
                } else {
                    tp.data[index][state] = val;
                }
                
                console.log('tp: ', tp);
                setTermlyPayload(tp);
                break;
            default: 
                break;
        }
    }

    const doMark = (type = 'personality') => {
        if (type === 'personality') {
            setLoadingMark(true);
            personality_marks.create(student.id, payload)
            .then(res => {
                console.log(res);
                if (res.code === 201) {
                    toast.success(<FormattedMessage id='component.text.save-successful'/>);
                    
                    getPersonalityMarkStudent(student);
                    setLoadingMark(false);
                }
            })
            .catch(err => {
                console.error(err);
                if (err.status === 401) {            
                    toast.error(<FormattedMessage id='component.text.not-allowed-input-score'/>);
                } else {                
                    toast.error(<div>{<FormattedMessage id='component.text.save-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
                }
                setLoadingMark(false);
            });
        } else if (type ==='attendance') {
            console.log('doMarkAttendanceReport ', attPayload);
            setLoadingMark(true);
            personality_marks.doMarkAttendanceReport(attPayload.classroom_id, attPayload)
            .then(res => {
                console.log(res);
                if (res.code === 201) {
                    toast.success(<FormattedMessage id='component.text.save-successful'/>);                    
                }
                setLoadingMark(false);
            })
            .catch(err => {
                console.error(err);
                if (err.status === 401) {            
                    toast.error(<FormattedMessage id='component.text.not-allowed-input-score'/>);
                } else {                
                    toast.error(<div>{<FormattedMessage id='component.text.save-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
                }
                setLoadingMark(false);
            });

        } else {
            console.log('doMarkTermlyReport ', termlyPayload);
            setLoadingMark(true);
            personality_marks.doMarkTermlyReport(termlyPayload.classroom_id, termlyPayload)
            .then(res => {
                console.log(res);
                if (res.code === 201) {
                    toast.success(<FormattedMessage id='component.text.save-successful'/>);                    
                }
                setLoadingMark(false);
            })
            .catch(err => {
                console.error(err);
                if (err.status === 401) {            
                    toast.error(<FormattedMessage id='component.text.not-allowed-input-score'/>);
                } else {                
                    toast.error(<div>{<FormattedMessage id='component.text.save-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
                }
                setLoadingMark(false);
            });
        }
    }
    
    const reset = (mode) => {
        personalityRef.current.select.clearValue();
        attendanceRef.current.select.clearValue();
        termlyRef.current.select.clearValue();

        setMode(mode);
        
        setAttPayload({
            ...attPayload,
            classroom_id: '',
            term: 1,
            max_attend:0,
            data:[]
        });

        setTermlyPayload({
            ...termlyPayload,
            classroom_id: '',
            term: 1,
            session: '',
            data: []
        });

        setPayload({
            ...payload,
            student_id: '',
            term: 1,
            data: [],
        });

        // setStudentList([]);
        setStudent({});
        setPersonalityStudent([]);
        setCurrentScore([]);
        setTermlyPayload({
            classroom_id: '',
            term: 1,
            session: '',
            created_by: user.username,
            data: []
        });
    };

    const renderCurrentScore = (term, data) => {
        const cs = data.filter(item => item.term === term);
        
        setCurrentScore(cs);
    }

    return (
        <div className="main">
            <ToastContainer
            autoClose={5000}
            hideProgressBar
            pauseOnHover={false} />
            <div className="main-content">
                {user.role === 'Wali Kelas' && (
                    
                <div className="container-fluid">
                    {ismaintenance ? (
    <div className="text-center">
        <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
        <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
    </div>
)  : (
                        <>
                    <h3 className="page-title">
                        <FormattedMessage id="menu.personality-mark"/>
                    </h3>
                    <div className="row">
                        <div className="col-md-12">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item active" role="presentation">
                                            <a onClick={() => reset('personality')} className="nav-link active" id="personality-tab" data-toggle="tab" href="#personality" role="tab" aria-controls="personality" aria-selected="true"><FormattedMessage id="menu.personality-mark"/></a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a onClick={() => reset('attendance')}className="nav-link" id="attendance-tab" data-toggle="tab" href="#attendance" role="tab" aria-controls="attendance" aria-selected="false"><FormattedMessage id="menu.attendance"/></a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a onClick={() => reset('termly')} className="nav-link" id="termly-tab" data-toggle="tab" href="#termly" role="tab" aria-controls="contact" aria-selected="false"><FormattedMessage id="menu.termly-result"/></a>
                                        </li>
                                    </ul>
                            <div className="panel">
                                <div className="panel-body">                                
                                <div className="tab-content" id="myTabContent">
                                    {/* PERSONALITY */}
                                    <div className="tab-pane active in" id="personality" role="tabpanel" aria-labelledby="personality-tab">

                                            <div className="row ml-2 mr-2">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <Select
                                                            ref={personalityRef}
                                                            placeholder={loadingClassroom ? 'Waiting for data...' : 'Choose the classroom'}                                                                                                   
                                                            options={classroomList}                                    
                                                            onChange={getStudentByClassroom}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <p style={{textAlign : 'left', fontWeight: '700', fontSize : '15'}}>
                                                            <FormattedMessage id="form.student.name"/>
                                                        </p>
                                                        <div className="form-group">
                                                            <Select
                                                                ref={personalityRef}
                                                                placeholder={loadingStudent ? 'Waiting for data...' : 'Choose the student'}                                                                                                   
                                                                options={studentList}                                    
                                                                onChange={getPersonalityMarkStudent}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    {loading && (
                                        <Loading visible={loading}/>
                                    )}
                                    {!loading && Object.keys(student).length > 0 && (
                                        <div>
                                            <div className="row">                                                
                                                <div className="col-md-12 mb-3">
                                                    <select 
                                                        className="form-control"
                                                        style={{ 
                                                            width: 200,
                                                            float: 'right'
                                                        }}
                                                        onChange={(e) => handlePayload(e, 0, 'term')} >
                                                        <FormattedMessage id="component.text.term">
                                                            {(message) => <option value='1' selected={payload.term === 1 ? true : false}>{message} 1</option>}
                                                        </FormattedMessage>
                                                        <FormattedMessage id="component.text.term">
                                                            {(message) => <option value='2' selected={payload.term === 2 ? true : false}>{message} 2</option>}
                                                        </FormattedMessage>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-10">
                                                    
                                                    <div className="metric">
                                                        <span className="icon mr-2"><i className="fa fa-user"></i></span>
                                                        <p >
                                                            <span className="number ">{student.name}</span>
                                                   
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th><FormattedMessage id="form.number"/></th>
                                                        <th><FormattedMessage id="form.personality"/></th>
                                                        <th><FormattedMessage id="form.personality.current.score"/></th>
                                                        <th><FormattedMessage id="form.personality.score"/></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    {personalityList && personalityList.length > 0 && personalityList.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>{item.label}</td>
                                                                <td>{currentScore && currentScore.length > 0  && currentScore[i].remarks || '-'}</td>
                                                                <td>
                                                                    <select 
                                                                        className="form-control"
                                                                        onChange={e => handlePayload(e, i, 'score')}>
                                                                        {Constants.SCORE_LIST.map((score, index) => (
                                                                            <option 
                                                                                id={score.value === 1 ? 'score-selected' : ''}
                                                                                value={score.value} 
                                                                                key={index}>
                                                                                {score.label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}                                                                                                        
                                                </tbody>
                                            </table>
                                            <button 
                                                disabled={loadingMark}
                                                className="btn btn-success btn-block mt-5 mb-5"
                                                onClick={() => doMark('personality')}>
                                                <FormattedMessage id="component.save.button"/>
                                            </button>
                                        </div>
                                    )}
                                </div>

                                    {/* ATTENDANCE */}
                                    <div className="tab-pane fade" id="attendance" role="tabpanel" aria-labelledby="attendance-tab">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <Select
                                                            ref={attendanceRef}
                                                            placeholder={loadingClassroom ? 'Waiting for data...' : 'Choose the classroom'}                                                                                                   
                                                            options={classroomList}                                    
                                                            onChange={getStudentByClassroom}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label>Term</label>
                                                        <select 
                                                                        className="form-control"
                                                                        onChange={(e) => handlePayload(e.target.value, 0, 'term', 'attendance')} >
                                                                        <FormattedMessage id="component.text.term">
                                                                            {(message) => <option value='1'>{message} 1</option>}
                                                                        </FormattedMessage>
                                                                        <FormattedMessage id="component.text.term">
                                                                            {(message) => <option value='2'>{message} 2</option>}
                                                                        </FormattedMessage>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label><FormattedMessage id="form.personalityMark.maxattendance"/></label>
                                                        <input type="text" className="form-control" onChange={e => handlePayload(e.target.value, 0, 'max_attend', 'attendance')}/>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th><FormattedMessage id="form.personalityMark.number"/></th>
                                                                    <th><FormattedMessage id="form.student.name"/></th>
                                                                    <th><FormattedMessage id="form.personalityMark.attendance-count"/></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {attPayload.classroom_id && studentList && studentList.length > 0 && studentList.map((s, i) => (
                                                                    <tr>
                                                                        <td>{i + 1}</td>
                                                                        <td>{s.name}</td>
                                                                        <td>
                                                                            <input 
                                                                                type="text" 
                                                                                className="form-control" 
                                                                                onChange={e => handlePayload(e.target.value, i, 'attending', 'attendance')}
                                                                            />
                                                                        </td>
                                                                        
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>

                                            <button className="btn btn-success col-md-12 mt-2" disabled={loadingMark} onClick={() => doMark('attendance')}>
                                                <FormattedMessage id="component.save.button"/>
                                            </button>
                                        </div>
                                    
                                    {/* TERMLY */}
                                    <div class="tab-pane fade" id="termly" role="tabpanel" aria-labelledby="termly-tab">
                                        <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <Select
                                                            ref={termlyRef}
                                                            placeholder={loadingClassroom ? 'Waiting for data...' : 'Choose the classroom'}                                                                                                   
                                                            options={classroomList}                                    
                                                            onChange={getStudentByClassroom}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6" style={{ float: 'left' }}>
                                                    <div className="form-group">
                                                        <label>Term</label>
                                                        <select 
                                                                        className="form-control"
                                                                        onChange={(e) => handlePayload(e.target.value, 0, 'term', 'termly')} >
                                                                        <FormattedMessage id="component.text.term">
                                                                            {(message) => <option value='1'>{message} 1</option>}
                                                                        </FormattedMessage>
                                                                        <FormattedMessage id="component.text.term">
                                                                            {(message) => <option value='2'>{message} 2</option>}
                                                                        </FormattedMessage>
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                {termlyPayload.classroom_id && studentList && studentList.length > 0 && studentList.map((s, i) => (
                                                    <div style={{padding:'20px',border:'1px solid #eee'}} className="mb-1">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <h3>{s.name}</h3>
                                                                {termlyPayload.term === '2' && (
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <p><FormattedMessage id="form.finalResult"/></p>
                                                                            <Select options={Constants.FINAL_RESULT} onChange={e => handlePayload(e.key, i, 'final_result', 'termly')} />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p><FormattedMessage id="form.to"/></p>
                                                                            <Select options={termlyClassroomList} onChange={e => handlePayload(e.value, i, 'to', 'termly')} />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className={`col-md-3 ${termlyPayload.term === '2' ? 'mt-5' : 'mt-0'}`}>
                                                                <p><FormattedMessage id="form.classComment" defaultMessage="No"/></p>
                                                                <textarea className="form-control" onChange={e => handlePayload(e.target.value, i, 'class_teacher_comment', 'termly')}></textarea>
                                                            </div>
                                                            <div className={`col-md-3 ${termlyPayload.term === '2' ? 'mt-5' : 'mt-0'}`}>
                                                                <p><FormattedMessage id="form.exculComment" defaultMessage="No"/></p>
                                                                <textarea className="form-control" onChange={e => handlePayload(e.target.value, i, 'co_curricular_comment', 'termly')}></textarea>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                ))}
                                                    <button className="btn btn-success col-md-12 mt-2" disabled={loadingMark} onClick={() => doMark('termly')}>
                                                        <FormattedMessage id="component.save.button"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>                   

                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </> 
                        )}
                    </div>
                )}
                </div>
            </div>
    )
}