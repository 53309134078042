import React, { Component } from "react";
import { Students, ReportHistory, Configurations } from "../../../services";
import { Helpers, Loading } from "../../../components";
import { FormattedMessage } from "react-intl";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
require("dotenv").config();

const students = new Students();
const reportHistory = new ReportHistory();
const helpers = new Helpers();
const configuration = new Configurations();

export default class YearlyPDF extends Component {
  constructor(props) {
    super(props);
    this.generic = {
      student_id: "",
      classroom_id: "",
      created_by: "APP",
    };

    this.state = {
      nonCuricular: "",
      Curicular: "",
      loading: false,
      validate: "mid-non",
      container: "mid-non2",
      bg: "bg",
      testing:
        "when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      loading: false,
      student: null,
      isEdited: false,
      school: {
        principal: "",
        foundation: "",
      },

      attendance: {
        ...this.generic,
        term: 0,
        attending: 0,
        max_attend: 0,
      },

      yearly_report: {
        ...this.generic,
        session: null,
        promoted_to: false,
        compartment_in: false,
        detained_in: false,
        class_teacher_comment: "",
        co_curricular_comment: "",
      },

      subject_list: [],
      aggregate_marks: [],
      session: "",
      notAllowed: false,
    };
  }

  componentDidMount() {
    this.getPrincpalSchool();
    const { term } = this.props;
    const params = new URLSearchParams(window.location.search)
    const historyID = params.get("history");
    const sessionId = params.get("session");
    if (historyID) {
      this.getDetailHistoryReport(historyID);
    } else if (sessionId) {
      this.getHistoryReportBySession(sessionId);
    } else {
      if (term > 2 || term < 1 || !term) {
        window.history.back();
      }
      this.getStudentReportData();
    }
  }
  getPrincpalSchool = () => {
    configuration
      .principal()
      .then((res) => {
        this.setState({
          school: {
            principal: res.result.value.created_at,
            foundation: res.result.value.end_at,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  action = (toPdf) => {
    this.setState(
      {
        validate: "mid-actived",
        container: "mid-actived2",
        bg: "bg",
      },
      () => {
        toPdf();
        this.setState({
          validate: "mid-non",
          container: "mid-non2",
          bg: "bg",
        });
      }
    );
  };
  action2 = (toPdf) => {
    this.setState(
      {
        validate: "mid-actived",
        container: "mid-actived2",
        bg: "no-bg",
      },
      () => {
        toPdf();
        this.setState({
          validate: "mid-non",
          container: "mid-non2",
          bg: "bg",
        });
      }
    );
  };
  getDetailHistoryReport = (historyID) => {
    this.setState({ loading: true });
    reportHistory
      .detail(historyID)
      .then(async (res) => {
        console.log(res);
        // const { subject_list, aggregate_marks } = await this.setStudentReportData(res.result);
        res.result.report_data.subjects = res.result.report_data.subjects.sort(
          (a, b) => a.subject.is_curricular - b.subject.is_curricular
        );
        var curicular = res.result.report_data.subjects.filter(
          (item) => item.subject.is_curricular === true
        );
        var noncuricular = res.result.report_data.subjects.filter(
          (item) => item.subject.is_curricular === false
        );
        res.result.report_data.address = res.result.student.address
        res.result.report_data.parent_name = res.result.student.parent_name
        res.result.report_data.date_of_birth = res.result.student.date_of_birth
        res.result.report_data.house = res.result.student.house
        res.result.report_data.telp_mob = res.result.student.telp_mob
        res.result.report_data.telp_res = res.result.student.telp_res
        res.result.report_data.gender = res.result.student.gender
        this.setState({
          student: res.result.report_data,
          loading: false,
          session: res.result.session.name,
          Curicular: curicular,
          nonCuricular: noncuricular,
        });
      })
      .catch((err) => {
        console.error(err);

        this.setState({ loading: false });
      });
  };
  getHistoryReportBySession = (sessionId) => {
    const { studentId } = this.props;
    this.setState({ loading: true });
    reportHistory
      .studentList(studentId,sessionId)
      .then(async (res) => {
        console.log(res);
        // const { subject_list, aggregate_marks } = await this.setStudentReportData(res.result);
        res.result.report_data.subjects = res.result.report_data.subjects.sort(
          (a, b) => a.subject.is_curricular - b.subject.is_curricular
        );
        var curicular = res.result.report_data.subjects.filter(
          (item) => item.subject.is_curricular === true
        );
        var noncuricular = res.result.report_data.subjects.filter(
          (item) => item.subject.is_curricular === false
        );
        res.result.report_data.address = res.result.student.address
        res.result.report_data.parent_name = res.result.student.parent_name
        res.result.report_data.date_of_birth = res.result.student.date_of_birth
        res.result.report_data.house = res.result.student.house
        res.result.report_data.telp_mob = res.result.student.telp_mob
        res.result.report_data.telp_res = res.result.student.telp_res
        res.result.report_data.gender = res.result.student.gender
        this.setState({
          student: res.result.report_data,
          loading: false,
          session: res.result.session.name,
          Curicular: curicular,
          nonCuricular: noncuricular,
        });
      })
      .catch((err) => {
        console.error(err);

        this.setState({ loading: false });
      });
  };
  getStudentReportData = () => {
    const { studentId } = this.props;

    this.setState({ loading: true });
    students
      .getStudentReportData(studentId)
      .then(async (res) => {
        console.log(res.result);
        res.result.subjects = res.result.subjects.sort(
          (a, b) => a.subject.is_curricular - b.subject.is_curricular
        );

        var curicular = res.result.subjects.filter(
          (item) => item.subject.is_curricular === true
        );
        // curicular = curicular.sort((a, b) => a.subject.name.localeCompare(b.subject.name));
        
        var noncuricular = res.result.subjects.filter(
          (item) => item.subject.is_curricular === false
        );
        // noncuricular = noncuricular.sort((a, b) => a.subject.name.localeCompare(b.subject.name));

        this.setState({
          student: res.result,
          loading: false,
          Curicular: curicular,
          nonCuricular: noncuricular,
          session: res.result.session.name,
        });
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 401) {
          this.setState({ notAllowed: true, loading: false });
        }

        this.setState({ loading: false });
      });
  };

  setStudentReportData = (data) => {
    const res = {
      subject_list: [],
      aggregate_marks: [],
    };

    if (data.subjects.length > 0) {
      let d = [];
      data.aggregate_marks[0].total_yearly_performance = 0;

      data.subjects.map((s) => {
        const sl = {
          name: "",
          first: {
            daily: 0,
            mid_term: 0,
            end_term: 0,
            final_term: 0,
            total: 0,
            grade: "-",
          },
          second: {
            daily: 0,
            mid_term: 0,
            end_term: 0,
            final_term: 0,
            total: 0,
            grade: "-",
          },
          yearly_performance: 0,
        };

        let finalTerm =
          s.marks[0].daily + s.marks[0].mid_term + s.marks[0].end_term;

        sl.name = s.subject.name;
        sl.first.daily = s.subject.is_curricular ? "-" : s.marks[0].daily;
        sl.first.mid_term = s.subject.is_curricular ? "-" : s.marks[0].mid_term;
        sl.first.end_term = s.subject.is_curricular ? "-" : s.marks[0].end_term;
        sl.first.final_term = s.subject.is_curricular
          ? "-"
          : finalTerm || s.marks[0].final_term;
        sl.first.grade = s.marks[0].grade || "-";

        data.aggregate_marks[0].total_final_term += finalTerm;

        sl.second.daily = s.subject.is_curricular ? "-" : s.marks[1].daily;
        sl.second.mid_term = s.subject.is_curricular
          ? "-"
          : s.marks[1].mid_term;
        sl.second.end_term = s.subject.is_curricular
          ? "-"
          : s.marks[1].end_term;
        finalTerm =
          s.marks[1].daily + s.marks[1].mid_term + s.marks[1].end_term;
        sl.second.final_term = s.subject.is_curricular
          ? "-"
          : finalTerm || s.marks[1].final_term;
        sl.second.grade = s.marks[1].grade || "-";

        data.aggregate_marks[1].total_final_term += finalTerm;

        sl.yearly_performance =
          (sl.first.final_term + sl.second.final_term) / 2 ||
          s.yearly_performance;
        data.aggregate_marks[0].total_yearly_performance +=
          sl.yearly_performance;

        d = [...d, { ...sl }];
      });

      res.subject_list = d;
    }

    if (data.aggregate_marks.length > 0) {
      res.aggregate_marks = data.aggregate_marks;
    }

    return res;
  };
  handlePayload = (val, prop, key) => {
    const s = {};
    s[prop] = { ...this.state[prop] };
    s[prop][key] = val;

    this.setState(s, () => console.log(this.state));
  };

  updateYearlyReport = () => {
    this.setState({ isEdited: !this.state.isEdited });
  };

  generateGrade = (v) => {
    // console.warn(v);
    v = parseInt(v);
    if (v >= 90 && v <= 100) {
      return "A+";
    } else if (v >= 80 && v <= 89) {
      return "A";
    } else if (v >= 70 && v <= 79) {
      return "B";
    } else if (v >= 60 && v <= 69) {
      return "C";
    } else {
      return "D";
    }
  };

  render() {
    const ref = React.createRef();
    const options = {
      orientation: "potrait",
      unit: "in",
      format: [842, 595],
    };
    const {
      Curicular,
      nonCuricular,
      validate,
      container,
      bg,
      student,
      loading,
      isEdited,
      notAllowed,
      school,
    } = this.state;

    const styles = StyleSheet.create({
      page: {
        flexDirection: "row",
        backgroundColor: "white",
      },
      bggrey: {
        backgroundColor: "#ccc",
      },
      bg: {
        backgroundImage: `url(${process.env.PUBLIC_URL + "/paper.jpeg"})`,
        height: "auto",
        backgroundRepeat: "no-repeat",
      },
      container: {
        margin: "auto",
        marginTop: 115,
        marginBottom: 0,
        paddingBottom: 0,
        marginLeft: 52,
        height: "auto",
        display: "block",
        width: "auto",
      },
      sign: {
        borderWidth: 1,
        borderBottom: 0,
        borderLeft: 0,
        borderRight: 0,
        marginLeft: 7.5,
        marginRight: 10,
      },
      row: {
        flexDirection: "row",
        display: "block",
        width: "auto",
        padding: 0,
      },
      col: {
        flexDirection: "column",
        display: "block",
        width: "auto",
      },
      col0: {
        width: 35,
        padding: 3,
      },
      col1: {
        width: 70,
        padding: 0,
      },
      col2: {
        width: 105,
        padding: 0,
      },
      col3: {
        width: 140,
        padding: 0,
      },
      col4: {
        width: 175,
        padding: 0,
      },
      col5: {
        width: 210,
        padding: 0,
      },
      col6: {
        width: 245,
        padding: 0,
      },
      col7: {
        width: 280,
        padding: 0,
      },
      col8: {
        width: 315,
        padding: 3,
      },
      col9: {
        width: 350,
        padding: 0,
      },
      col10: {
        width: 385,
        padding: 0,
      },
      col11: {
        width: 420,
        padding: 0,
      },
      col12: {
        width: 455,
        padding: 0,
      },
      tdcol0: {
        borderWidth: 1,
        color: "#000",
        width: 35,
        padding: 3,
      },
      tdcol1: {
        borderWidth: 1,
        color: "#000",
        width: 70,
        padding: 3,
      },
      tdcol2: {
        borderWidth: 1,
        color: "#000",
        width: 105,
        padding: 3,
      },
      tdcol3: {
        borderWidth: 1,
        color: "#000",
        width: 140,
        padding: 3,
      },
      tdcol4: {
        borderWidth: 1,
        color: "#000",
        width: 175,
        padding: 3,
      },
      tdcol5: {
        borderWidth: 1,
        color: "#000",
        width: 210,
        padding: 3,
      },
      tdcol6: {
        borderWidth: 1,
        color: "#000",
        width: 245,
        padding: 3,
      },
      tdcol7: {
        borderWidth: 1,
        color: "#000",
        width: 280,
        padding: 3,
      },
      tdcol8: {
        borderWidth: 1,
        color: "#000",
        width: 315,
        padding: 3,
      },
      tdcol9: {
        borderWidth: 1,
        color: "#000",
        width: 350,
        padding: 3,
      },
      tdcol10: {
        borderWidth: 1,
        color: "#000",
        width: 385,
        padding: 3,
      },
      tdcol11: {
        borderWidth: 1,
        color: "#000",
        width: 420,
        padding: 3,
      },
      tdcol12: {
        borderWidth: 1,
        color: "#000",
        width: 455,
        padding: 3,
      },
      tdcol13: {
        borderWidth: 1,
        color: "#000",
        width: 490,
        padding: 3,
      },
      text: {
        padding: 0,
        fontSize: 8,
        color: "#000",
        textAlign: "center",
      },
      textlittle: {
        padding: 0,
        fontSize: 7.5,
        color: "#000",
        textAlign: "center",
      },
      textheader: {
        padding: 0,
        fontSize: 9,
        color: "#000",
        textAlign: "center",
      },
      text2: {
        padding: 0,
        fontSize: 8,
        color: "#000",
        textAlign: "center",
        fontWeight: "bold",
      },
      text3: {
        padding: 0,
        fontSize: 7,
        color: "#000",
        textAlign: "center",
        fontWeight: "bold",
      },
      name: {
        backgroundColor: "grey",
        margin: 5,
        fontSize: 8,
        color: "#000",
      },
      tleft: {
        textAlign: "left",
      },
      tcenter: {
        textAlign: "center",
      },
      fleft: {
        float: "left",
      },
      fright: {
        float: "right",
      },
      mt05: {
        marginTop: "5",
      },
      mt1: {
        marginTop: "10",
      },
      mt2: {
        marginTop: "20",
      },
      mt3: {
        marginTop: "30",
      },
      mt33: {
        marginTop: "32",
      },
      mt4: {
        marginTop: "40",
      },
      mt5: {
        marginTop: "50",
      },
      mt6: {
        marginTop: "60",
      },
      mt7: {
        marginTop: "70",
      },
      mt8: {
        marginTop: "80",
      },
      mt9: {
        marginTop: "90",
      },
      mt10: {
        marginTop: "100",
      },
      mb05: {
        marginTop: "5",
      },
      mb1: {
        marginTop: "10",
      },
      mb2: {
        marginTop: "20",
      },
      mb3: {
        marginTop: "30",
      },
      mb4: {
        marginTop: "40",
      },
      mb5: {
        marginTop: "50",
      },
      mb6: {
        marginTop: "60",
      },
      mb7: {
        marginTop: "70",
      },
      mb8: {
        marginTop: "80",
      },
      mb9: {
        marginTop: "90",
      },
      mb10: {
        marginTop: "100",
      },
      image: {
        width: 17,
        height: 17,
      },
      min: {
        height: 420,
      },
    });

    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.bg}>
            <View style={styles.container}>
              <View style={styles.row}>
                <View style={styles.col3}></View>
                <View style={[styles.tdcol6, styles.bggrey]}>
                  <Text style={styles.textheader}>ANNUAL REPORT</Text>
                </View>
                <View style={styles.col3}></View>
              </View>

              <View style={[styles.row, styles.mt1]}>
                <View style={[styles.col9, styles.mb05]}>
                  <Text style={[styles.textheader, styles.tleft]}>
                    Name :{" "}
                    <View style={styles.bggrey}>
                      <Text style={styles.textheader}>{student.name}</Text>
                    </View>
                  </Text>
                </View>
                <View style={[styles.col2, styles.mb05]}>
                  <Text style={[styles.textheader, styles.tright]}>
                    Session : {this.state.session}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={[styles.col4, styles.mb05]}>
                  <Text style={[styles.textheader, styles.tleft]}>
                    Date of Birth :{" "}
                    {helpers.formatDate(student.date_of_birth, "LL") || "-"}{" "}
                  </Text>
                </View>
                <View style={[styles.col1, styles.mb05]}>
                  <Text style={[styles.textheader, styles.tleft]}>
                    Gender : {student.gender || "-"}
                  </Text>
                </View>
                <View style={[styles.col6, styles.mb05]}>
                  <Text style={[styles.textheader, styles.tleft]}>
                    Parent's name :
                    <View style={styles.bggrey}>
                      <Text
                        style={
                          student.parent_name.length >= 30
                            ? [styles.textlittle, styles.fleft]
                            : [styles.textheader, styles.fleft]
                        }
                      >
                        {student.parent_name}
                      </Text>
                    </View>
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={[styles.col3, styles.mb05]}>
                  <Text style={[styles.textheader, styles.tleft]}>
                    Grade : {student.classroom.name || "-"}
                  </Text>
                </View>
                <View style={[styles.col2, styles.mb05]}>
                  <Text style={[styles.textheader, styles.tleft]}>
                    Roll No : {student.roll_no || "-"}{" "}
                  </Text>
                </View>
                <View style={[styles.col2, styles.mb05]}>
                  <Text style={[styles.textheader, styles.tleft]}>
                    Tel: Res : {student.telp_res || "-"}
                  </Text>
                </View>
                <View style={[styles.col2, styles.mb05]}>
                  <Text style={[styles.textheader, styles.tright]}>
                    Tel: Mob : {student.telp_mob || "-"}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={[styles.col3, styles.mb05]}>
                  <Text style={[styles.textheader, styles.tleft]}>
                    House : {student.house || "-"}{" "}
                  </Text>
                </View>
                <View style={[styles.col9, styles.mb05]}>
                  <Text style={[styles.textheader, styles.tleft]}>
                    Address :
                    <View style={styles.bggrey}>
                      <Text
                        style={
                          student.address.length >= 60
                            ? [styles.textlittle, styles.tleft]
                            : [styles.textheader, styles.tleft]
                        }
                      >
                        {student.address || "-"}
                      </Text>
                    </View>
                  </Text>
                </View>
              </View>
              <View style={[styles.min, styles.mt33]}>
                <View style={styles.row}>
                  <View style={styles.tdcol3}>
                    <Text style={styles.text}></Text>
                    <Text style={styles.text}></Text>
                    <Text style={styles.text2}>Subject</Text>
                  </View>
                  <View style={styles.row}>
                    <View style={styles.col}>
                      <View style={[styles.tdcol1, styles.bggrey]}>
                        {term === "2" && (
                          <Text style={styles.text2}>Term 1 (A)</Text>
                        )}
                      </View>
                      <View style={styles.row}>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text2}>Total</Text>
                          <Text style={styles.text2}>(A)</Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text2}>Grade</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={styles.col}>
                      <View style={styles.tdcol4}>
                        <Text style={styles.text2}>Term 2 (B)</Text>
                      </View>
                      <View style={styles.row}>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>Mid Term 2</Text>
                          <Text style={styles.text}>(40)</Text>
                        </View>
                        <View style={styles.tdcol1}>
                          <Text style={styles.text2}>Term 2</Text>
                          <Text style={styles.text2}>(20+40)</Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text2}>Total</Text>
                          <Text style={styles.text2}>(B)</Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text2}>Grade</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  {term === "2" && (
                    <>
                      <View style={styles.tdcol1}>
                        <Text style={styles.text2}>Annual</Text>
                        <Text style={styles.text2}>Performance</Text>
                        <Text style={styles.text2}>(A+B)/2</Text>
                      </View>
                      <View style={styles.tdcol0}>
                        <Text style={styles.text2}></Text>
                        <Text style={styles.text2}></Text>
                        <Text style={styles.text2}>Grade</Text>
                      </View>
                    </>
                  )}
                </View>
                {/* column for body */}
                {nonCuricular.map((s, i) => (
                  <View style={styles.row}>
                    <View style={styles.tdcol3}>
                      <Text style={[styles.text, styles.tleft]}>
                        {s.subject.name}
                      </Text>
                    </View>
                    <View style={styles.tdcol0}>
                      <Text style={styles.text}>
                        {term === "2"
                          ? s.marks[0].final_term
                            ? s.marks[0].grade ||
                              s.marks[0].final_term.toFixed(2)
                            : s.marks[0].grade || "-"
                          : s.marks[1].final_term
                          ? s.marks[1].grade || s.marks[1].final_term.toFixed(2)
                          : s.marks[1].grade || "-"}
                      </Text>
                    </View>
                    <View style={styles.tdcol0}>
                      <Text style={styles.text}>
                        {term === "2"
                          ? this.generateGrade(s.marks[0].final_term) || s.marks[1].mid_term_value || "-"
                          : s.marks[0].grade ||
                            s.marks[0].mid_term_value ||
                            "-"}
                      </Text>
                    </View>
                    {term === "2" && (
                      <>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>
                            {term === "2"
                              ? s.marks[1].grade ||
                                s.marks[1].mid_term_value ||
                                "-"
                              : s.marks[0].grade ||
                                s.marks[0].mid_term_value ||
                                "-"}
                          </Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>
                            {term === "2"
                              ? s.marks[1].grade ||
                                s.marks[1].end_term_assessment ||
                                "-"
                              : s.marks[0].grade ||
                                s.marks[0].end_term_assessment ||
                                "-"}
                          </Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>
                            {term === "2"
                              ? s.marks[1].grade || s.marks[1].end_term || "-"
                              : s.marks[0].grade || s.marks[0].end_term || "-"}
                          </Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>
                            {term === "2"
                              ? s.marks[1].grade || s.marks[1].final_term
                                ? s.marks[1].grade ||
                                  s.marks[1].final_term.toFixed(2)
                                : s.marks[1].grade || "-"
                              : s.marks[0].final_term
                              ? s.marks[0].grade ||
                                s.marks[0].final_term.toFixed(2)
                              : s.marks[0].grade || "-"}
                          </Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>
                            {term === "2"
                              ? s.marks[1].grade ||
                                (s.marks[1].final_term &&
                                  this.generateGrade(s.marks[1].final_term)) ||
                                "-" ||
                                "-"
                              : s.marks[0].grade || "-"}
                          </Text>
                        </View>
                        <View style={styles.tdcol1}>
                          <Text style={styles.text}>
                            {s.yearly_performance
                              ? s.yearly_performance.toFixed(2)
                              : "-"}
                          </Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>
                            {s.yearly_performance
                              ? this.generateGrade(s.yearly_performance)
                              : "-"}
                          </Text>
                        </View>
                      </>
                    )}
                  </View>
                ))}
                {student && student.aggregate_marks.length > 0 && (
                  <View style={styles.row}>
                    <View style={styles.tdcol3}>
                      <Text style={[styles.text, styles.tleft]}>
                        Total Marks Obtained
                      </Text>
                    </View>
                    <View style={styles.tdcol0}>
                      <Text style={styles.text}>
                        {student.aggregate_marks[0].total_final_term || "-"}
                      </Text>
                    </View>
                    <View style={styles.tdcol0}>
                      <Text style={styles.text}>-</Text>
                    </View>
                    {term === "2" && (
                      <>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>
                            {term === "2"
                              ? student.aggregate_marks[1]
                                  .total_mid_term_value || "-"
                              : student.aggregate_marks[0]
                                  .total_mid_term_value || "-"}
                          </Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>
                            {term === "2"
                              ? student.aggregate_marks[1]
                                  .total_end_term_assessment || "-"
                              : student.aggregate_marks[0]
                                  .total_end_term_assessment || "-"}
                          </Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>
                            {term === "2"
                              ? student.aggregate_marks[1].total_end_term || "-"
                              : student.aggregate_marks[0].total_end_term ||
                                "-"}
                          </Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>
                            {term === "2"
                              ? student.aggregate_marks[1].total_final_term
                                ? student.aggregate_marks[1].total_final_term.toFixed(
                                    2
                                  )
                                : "-"
                              : student.aggregate_marks[0].total_final_term
                              ? student.aggregate_marks[0].total_final_term.toFixed(
                                  2
                                )
                              : "-"}
                          </Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>-</Text>
                        </View>
                        <View style={styles.tdcol1}>
                          <Text style={styles.text}>
                            {student.aggregate_yearly_performance
                              .total_yearly_performance
                              ? student.aggregate_yearly_performance.total_yearly_performance.toFixed(
                                  2
                                )
                              : "-"}
                          </Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>-</Text>
                        </View>
                      </>
                    )}
                  </View>
                )}
                <View style={styles.row}>
                  <View style={styles.tdcol9}>
                    <Text style={[styles.text, styles.tleft]}> </Text>
                  </View>
                  <View style={styles.tdcol3}>
                    <Text style={[styles.text, styles.tleft]}>
                      Maximum Marks : {student.aggregate_yearly_performance.max}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.tdcol3}>
                    <Text style={[styles.text, styles.tleft]}>
                      Aggregate Percentage
                    </Text>
                  </View>
                  <View style={styles.tdcol0}>
                    <Text style={styles.text3}>
                      {" "}
                      {student.aggregate_marks[0].total_percentage.toFixed(2)}%
                    </Text>
                  </View>
                  <View style={styles.tdcol3}>
                    <Text style={styles.text}> </Text>
                  </View>
                  {term === "2" && (
                    <>
                      <View style={styles.tdcol0}>
                        <Text style={styles.text3}>
                          {" "}
                          {student.aggregate_marks[1].total_percentage.toFixed(
                            2
                          )}
                          %
                        </Text>
                      </View>
                      <View style={styles.tdcol0}>
                        <Text style={styles.text}> </Text>
                      </View>
                      <View style={styles.tdcol1}>
                        <Text style={styles.text3}>
                          {" "}
                          {student.aggregate_yearly_performance.total_percentage.toFixed(
                            2
                          )}
                          %{" "}
                        </Text>
                      </View>
                      <View style={styles.tdcol0}>
                        <Text style={styles.text}> </Text>
                      </View>
                    </>
                  )}
                </View>
                <View style={styles.row}>
                  <View style={styles.tdcol13}>
                    <Text style={styles.text}> </Text>
                  </View>
                </View>
                {Curicular.map((s, i) => (
                  <View style={styles.row}>
                    <View style={[styles.tdcol3, { borderRightWidth: 0 }]}>
                      <Text style={[styles.text, styles.tleft]}>
                        {""}
                        {s.subject.name}{" "}
                      </Text>
                    </View>
                    <View style={{ borderWidth: 0, borderBottomWidth: 1, borderTopWidth: 1, width: 35 }}>
                      <Text style={styles.text}>
                        {" "}
                        {/* {term === "2"
                          ? s.marks[0].final_term
                            ? (s.marks[0].grade && "") ||
                              s.marks[0].final_term.toFixed(2)
                            : (s.marks[0].grade && "") || ""
                          : s.marks[1].final_term
                          ? (s.marks[1].grade && "") ||
                            s.marks[1].final_term.toFixed(2)
                          : (s.marks[1].grade && "") || ""} */}
                      </Text>
                    </View>
                    <View style={styles.tdcol0}>
                      <Text style={styles.text}>
                        {term === "2"
                          ? s.marks[0].grade ||
                            (s.marks[0].final_term &&
                              this.generateGrade(s.marks[0].final_term)) ||
                            ""
                          : s.marks[0].grade ||
                            (s.marks[0].final_term &&
                              this.generateGrade(s.marks[0].final_term)) ||
                            ""}
                      </Text>
                    </View>
                    <View style={{ borderWidth: 0, borderBottomWidth: 1, borderTopWidth: 1, width: 35 }}>
                      <Text style={styles.text}>
                        {term === "2"
                          ? (s.marks[1].grade && "") ||
                            s.marks[1].mid_term_value ||
                            ""
                          : (s.marks[0].grade && "") ||
                            s.marks[0].mid_term_value ||
                            ""}
                      </Text>
                    </View>
                    {term === "2" && (
                      <>
                        <View style={{ borderWidth: 0, borderBottomWidth: 1, borderTopWidth: 1, width: 35 }}>
                          <Text style={styles.text}>
                            {term === "2"
                              ? (s.marks[1].grade && "") ||
                                s.marks[1].end_term_assessment ||
                                ""
                              : (s.marks[0].grade && "") ||
                                s.marks[0].end_term_assessment ||
                                ""}
                          </Text>
                        </View>
                        <View style={{ borderWidth: 0, borderBottomWidth: 1, borderTopWidth: 1, width: 35 }}>
                          <Text style={styles.text}>
                            {term === "2"
                              ? (s.marks[1].grade && "") ||
                                s.marks[1].end_term ||
                                ""
                              : (s.marks[0].grade && "") ||
                                s.marks[0].end_term ||
                                ""}
                          </Text>
                        </View>
                        <View style={{ borderWidth: 0, borderBottomWidth: 1, borderTopWidth: 1, width: 35 }}>
                          <Text style={styles.text}>
                            {term === "2"
                              ? (s.marks[1].grade && "") ||
                                s.marks[1].final_term
                                ? (s.marks[1].grade && "") ||
                                  s.marks[1].final_term.toFixed(2)
                                : (s.marks[1].grade && "") || ""
                              : s.marks[0].final_term
                              ? (s.marks[0].grade && "") ||
                                s.marks[0].final_term.toFixed(2)
                              : (s.marks[0].grade && "") || ""}{" "}
                          </Text>
                        </View>
                        <View style={styles.tdcol0}>
                          <Text style={styles.text}>
                            {" "}
                            {term === "2"
                              ? s.marks[1].grade ||
                                (s.marks[1].final_term &&
                                  this.generateGrade(s.marks[1].final_term)) ||
                                "" ||
                                ""
                              : s.marks[0].grade || ""}
                          </Text>
                        </View>
                        <View style={[styles.tdcol1, { borderWidth: 0, borderBottomWidth: 1, borderTopWidth: 1 }]}>
                          <Text style={styles.text}>
                            {" "}
                            {/* {s.yearly_performance
                              ? s.yearly_performance.toFixed(2)
                              : (s.marks[1].grade && "") || ""} */}
                          </Text>
                        </View>
                        <View style={[styles.tdcol0, { borderLeftWidth: 0 }]}>
                          <Text style={styles.text}></Text>
                        </View>
                      </>
                    )}
                  </View>
                ))}
                <View style={styles.row}>
                  <View style={styles.tdcol13}>
                    <Text style={styles.text}> </Text>
                  </View>
                </View>
                {term === "2" && (
                  <View style={[styles.row, styles.mt05]}>
                    <View style={styles.col2}>
                      <Text style={[styles.text, styles.tleft, styles.fleft]}>
                        Final Result
                      </Text>
                    </View>
                    <View style={styles.col1}>
                      <Image
                        style={styles.image}
                        src={
                          student.termly_reports &&
                          student.termly_reports.length > 0 &&
                          student.termly_reports[
                            student.termly_reports.length - 1
                          ].promoted_to
                            ? "/assets/img/check.png"
                            : "/assets/img/uncheck.png"
                        }
                      />
                    </View>
                    <View style={styles.col2}>
                      <Text style={[styles.text, styles.tleft, styles.fleft]}>
                        Promoted To
                      </Text>
                    </View>
                    <View style={styles.col1}>
                      <Image
                        style={styles.image}
                        src={
                          student.termly_reports &&
                          student.termly_reports.length > 0 &&
                          student.termly_reports[
                            student.termly_reports.length - 1
                          ].detained_in
                            ? "/assets/img/check.png"
                            : "/assets/img/uncheck.png"
                        }
                      />
                    </View>
                    <View style={styles.col1}>
                      <Text style={[styles.text, styles.tleft]}>
                        {" "}
                        Detained In{" "}
                      </Text>
                    </View>
                  </View>
                )}
                <View style={styles.row}>
                  <View style={styles.tdcol6}>
                    <Text style={styles.text}>
                      {" "}
                      Attendance (Term) 1{" "}
                      {student.attendances && student.attendances.length > 0
                        ? ` ${student.attendances[0].attending} `
                        : " - "}{" "}
                      Out of{" "}
                      {student.attendances && student.attendances.length > 0
                        ? ` ${student.attendances[0].max_attend} `
                        : " - "}
                    </Text>
                  </View>
                  {term === "2" && (
                    <View style={styles.tdcol6}>
                      <Text style={styles.text}>
                        {" "}
                        Attendance (Term) 2{" "}
                        {student.attendances && student.attendances.length > 0
                          ? ` ${
                              (student.attendances[1] &&
                                student.attendances[1].attending) ||
                              " - "
                            } `
                          : " - "}{" "}
                        Out of{" "}
                        {student.attendances && student.attendances.length > 0
                          ? ` ${
                              (student.attendances[1] &&
                                student.attendances[1].max_attend) ||
                              " - "
                            } `
                          : " - "}{" "}
                      </Text>
                    </View>
                  )}
                </View>

                <View style={[styles.row, styles.mt1]}>
                  <View style={[styles.tdcol2, styles.bggrey]}>
                    <Text style={[styles.text, styles.tleft]}>
                      Class Teacher's Comments{" "}
                    </Text>
                  </View>
                  <View style={styles.tdcol10}>
                    <Text
                      style={
                        student.termly_reports[
                          student.termly_reports.length - 1
                        ].class_teacher_comment.length >= "100"
                          ? [styles.textlittle, styles.tleft]
                          : [styles.text, styles.tleft]
                      }
                    >
                      {(student.termly_reports &&
                        student.termly_reports.length > 0 &&
                        student.termly_reports[
                          student.termly_reports.length - 1
                        ] &&
                        student.termly_reports[
                          student.termly_reports.length - 1
                        ].class_teacher_comment) ||
                        "-"}
                    </Text>
                  </View>
                </View>
                <View style={[styles.row, styles.mt1]}>
                  <View style={[styles.tdcol2, styles.bggrey]}>
                    <Text style={[styles.text, styles.tleft]}>
                      Co Curricular Activities{" "}
                    </Text>
                  </View>
                  <View style={styles.tdcol10}>
                    <Text
                      style={
                        student.termly_reports[
                          student.termly_reports.length - 1
                        ].co_curricular_comment.length >= "100"
                          ? [styles.textlittle, styles.tleft]
                          : [styles.text, styles.tleft]
                      }
                    >
                      {(student.termly_reports &&
                        student.termly_reports.length > 0 &&
                        student.termly_reports[
                          student.termly_reports.length - 1
                        ] &&
                        student.termly_reports[
                          student.termly_reports.length - 1
                        ].co_curricular_comment) ||
                        "-"}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={[styles.row, styles.mt3]}>
                <View style={[styles.col2, styles.sign]}>
                  <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                    {" "}
                    Class Teacher{" "}
                  </Text>
                  <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                    ({student.classroom.teacher.name})
                  </Text>
                </View>
                <View style={[styles.col2, styles.sign]}>
                  <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                    {" "}
                    Parent{" "}
                  </Text>
                  <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                    ({student.parent_name})
                  </Text>
                </View>
                <View style={[styles.col2, styles.sign]}>
                  <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                    {" "}
                    Kepala Sekolah{" "}
                  </Text>
                  <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                    ({school.principal})
                  </Text>
                </View>
                <View style={[styles.col2, styles.sign]}>
                  <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                    {" "}
                    Principal{" "}
                  </Text>
                  <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                    ({school.foundation})
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );

    // console.log(this.state);
    const { term } = this.props;
    // console.log(term);
    return (
      <>
        <Loading visible={loading} />
        {!loading && notAllowed && (
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-5">
                <div className="text-center">
                  <img
                    src="assets/img/no-data.svg"
                    className="img-fluid mb-5"
                    style={{ width: "500px" }}
                  />
                  <h3 className="page-title">
                    <FormattedMessage id="component.text.cannot-print" />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        )}
        {!loading && !notAllowed && student && (
          <>
            <div ref={ref} className={validate}>
              <div className={bg}>
                <div className={container}>
                  {/* <div className="row">
                                    <div className="col-xs-3 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                        <img src="assets/img/logo/logo MGS.png" className="logo-preview" alt="logo"/>
                                    </div>
                                    <div className="col-xs-9 col-sm-8 col-md-10 col-lg-10 col-xl-10">
                                        <h1 className="heading-preview">{process.env.REACT_APP_NAME}</h1>
                                        <div className="list"/>
                                        <div className="sub">
                                            <p>Jl. Tabing, Blok B-16 No. 3, Kemayoran, Jakarta Pusat</p>
                                            <p>Tel : +62-21-6542241 Fax: +62-21-6542479</p>
                                        </div>
                                    </div>
                                </div> */}
                  <div className="row mb-1 banner">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"></div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "#ccc",
                          textAlign: "center",
                          fontSize: "15px",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        <span className="text-center">Annual Report</span>
                      </div>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"></div>
                  </div>
                  <div
                    className="row"
                    style={{ paddingBottom: "0px", marginBottom: "0px" }}
                  >
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 ">
                      <div className="identitas">
                        <p>
                          Name : <span className="name">{student.name}</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 ">
                      <div className="identitas">
                        <p style={{ float: "right" }}>
                          Session :
                          <span className="sub-identitas">
                            {this.state.session}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <div className="identitas">
                        <p>
                          Date of Birth :{" "}
                          <span className="sub-identitas">
                            {" "}
                            {helpers.formatDate(student.date_of_birth, "LL") ||
                              "-"}{" "}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <div className="identitas">
                        <p>
                          Gender :{" "}
                          <span className="sub-identitas">
                            {student.gender || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="identitas">
                        <p style={{ float: "right" }}>
                          Parent's name :{" "}
                          <span className="sub-identitas">
                            {student.parent_name}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                      <div className="identitas">
                        <p>
                          Grade :{" "}
                          <span className="sub-identitas">
                            {student.classroom.name || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <div className="identitas">
                        <p>
                          Roll No :{" "}
                          <span className="sub-identitas">
                            {" "}
                            {student.roll_no || "-"}{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                      <div className="identitas">
                        <p>
                          Tel: Res :{" "}
                          <span className="sub-identitas">
                            {student.telp_res || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <div className="identitas">
                        <p style={{ float: "right" }}>
                          Tel: Mob. :{" "}
                          <span className="sub-identitas">
                            {student.telp_mob || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                      <div className="identitas">
                        <p>
                          House :{" "}
                          <span className="sub-identitas">
                            {" "}
                            {student.house || "-"}{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                      <div className="identitas">
                        <p>
                          Address :{" "}
                          <span className="sub-identitas">
                            {student.address || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                      <div className="img-overlay text-center">
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th className="th-preview" rowSpan="2">
                                Subject
                              </th>
                              {term === "2" && (
                                <>
                                  <th
                                    className="th-preview th-color"
                                    colSpan="2"
                                  >
                                    Term 1 (A)
                                  </th>
                                  <th
                                    className="th-preview th-color2"
                                    colSpan="5"
                                  >
                                    Term 2 (B)
                                  </th>
                                  <th className="th-preview" rowSpan="2">
                                    Annual <br /> Performance <br /> (A+B)/2
                                  </th>
                                  <th className="th-preview" rowSpan="2">
                                    Grade
                                  </th>
                                </>
                              )}
                            </tr>
                            <tr>
                              <th
                                colSpan="1"
                                className="th-preview border-delete"
                              >
                                Total <br /> (A)
                              </th>
                              <th className="th-preview" colSpan="1">
                                Grade{" "}
                              </th>
                              {term === "2" && (
                                <>
                                  <th
                                    colSpan="1"
                                    className="th-preview border-delete"
                                  >
                                    Mid Term 2 <br /> (40){" "}
                                  </th>
                                  <th className="th-preview" colSpan="2">
                                    Term 2 <br /> (20+40)
                                  </th>
                                  <th
                                    colSpan="1"
                                    className="th-preview border-delete"
                                  >
                                    Total <br /> (B){" "}
                                  </th>
                                  <th className="th-preview" colSpan="1">
                                    Grade{" "}
                                  </th>
                                </>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {nonCuricular.map((s, i) => (
                              <tr>
                                <th className="subject th-preview2">
                                  {s.subject.name}
                                </th>
                                <td className="td-preview">
                                  {term === "2"
                                    ? s.marks[0].final_term
                                      ? s.marks[0].grade ||
                                        s.marks[0].final_term.toFixed(2)
                                      : s.marks[0].grade || "-"
                                    : s.marks[1].final_term
                                    ? s.marks[1].grade ||
                                      s.marks[1].final_term.toFixed(2)
                                    : s.marks[1].grade || "-"}
                                </td>
                                <td className="td-preview">
                                  {term === "2"
                                    ? s.marks[0].grade ||
                                      (s.marks[0].final_term &&
                                        this.generateGrade(
                                          s.marks[0].final_term
                                        )) ||
                                      "-"
                                    : s.marks[0].grade ||
                                      (s.marks[0].final_term &&
                                        this.generateGrade(
                                          s.marks[0].final_term
                                        )) ||
                                      "-"}
                                </td>
                                {term === "2" && (
                                  <>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? s.marks[1].grade ||
                                          s.marks[1].mid_term_value ||
                                          "-"
                                        : s.marks[0].grade ||
                                          s.marks[0].mid_term_value ||
                                          "-"}
                                    </td>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? s.marks[1].grade ||
                                          s.marks[1].end_term_assessment ||
                                          "-"
                                        : s.marks[0].grade ||
                                          s.marks[0].end_term_assessment ||
                                          "-"}
                                    </td>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? s.marks[1].grade ||
                                          s.marks[1].end_term ||
                                          "-"
                                        : s.marks[0].grade ||
                                          s.marks[0].end_term ||
                                          "-"}
                                    </td>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? s.marks[1].grade ||
                                          s.marks[1].final_term
                                          ? s.marks[1].grade ||
                                            s.marks[1].final_term.toFixed(2)
                                          : s.marks[1].grade || "-"
                                        : s.marks[0].final_term
                                        ? s.marks[0].grade ||
                                          s.marks[0].final_term.toFixed(2)
                                        : s.marks[0].grade || "-"}
                                    </td>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? s.marks[1].grade ||
                                          (s.marks[1].final_term &&
                                            this.generateGrade(
                                              s.marks[1].final_term
                                            )) ||
                                          "-" ||
                                          "-"
                                        : s.marks[0].grade || "-"}
                                    </td>
                                    <td className="td-preview">
                                      {s.yearly_performance
                                        ? s.yearly_performance.toFixed(2)
                                        : "-"}
                                    </td>
                                    <td className="td-preview">
                                      {s.yearly_performance
                                        ? this.generateGrade(
                                            s.yearly_performance
                                          )
                                        : "-"}
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                            {student && student.aggregate_marks.length > 0 && (
                              <tr>
                                <th className="subject font th-preview2">
                                  <b>Total Marks Obtained</b>
                                </th>
                                <td className="td-preview">
                                  {student.aggregate_marks[0]
                                    .total_final_term || "-"}
                                </td>
                                <td className="td-preview">-</td>
                                {term === "2" && (
                                  <>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? student.aggregate_marks[1]
                                            .total_mid_term_value || "-"
                                        : student.aggregate_marks[0]
                                            .total_mid_term_value || "-"}
                                    </td>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? student.aggregate_marks[1]
                                            .total_end_term_assessment || "-"
                                        : student.aggregate_marks[0]
                                            .total_end_term_assessment || "-"}
                                    </td>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? student.aggregate_marks[1]
                                            .total_end_term || "-"
                                        : student.aggregate_marks[0]
                                            .total_end_term || "-"}
                                    </td>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? student.aggregate_marks[1]
                                            .total_final_term
                                          ? student.aggregate_marks[1].total_final_term.toFixed(
                                              2
                                            )
                                          : "-"
                                        : student.aggregate_marks[0]
                                            .total_final_term
                                        ? student.aggregate_marks[0].total_final_term.toFixed(
                                            2
                                          )
                                        : "-"}
                                    </td>
                                    <td className="td-preview"></td>
                                    <td className="td-preview">
                                      {student.aggregate_yearly_performance
                                        .total_yearly_performance
                                        ? student.aggregate_yearly_performance.total_yearly_performance.toFixed(
                                            2
                                          )
                                        : "-"}
                                    </td>
                                  </>
                                )}
                              </tr>
                            )}
                            <tr>
                              <th
                                className="subject font th-preview2"
                                colSpan="7"
                              ></th>
                              <th
                                className="subject font th-preview2"
                                colSpan="3"
                              >
                                <b>Maximum Marks</b> :{" "}
                                {student.aggregate_yearly_performance.max}
                              </th>
                            </tr>
                            <tr>
                              <th
                                className="subject font th-preview2"
                                colSpan="1"
                              >
                                <b>Aggregate Percentage : </b>
                              </th>
                              <td className="td-preview ">
                                {student.aggregate_marks[0].total_percentage.toFixed(
                                  2
                                )}
                                %
                              </td>
                              <td className=" td-preview" colSpan="4">
                                {" "}
                              </td>
                              {term === "2" && (
                                <>
                                  <td className="td-preview">
                                    {student.aggregate_marks[1].total_percentage.toFixed(
                                      2
                                    )}
                                    %
                                  </td>
                                  <td className="td-preview"></td>
                                  <td className="td-preview">
                                    {student.aggregate_yearly_performance.total_percentage.toFixed(
                                      2
                                    )}
                                    %
                                  </td>
                                </>
                              )}
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <th className="curicular"></th>
                              <th className="curicular"></th>
                              <th className="curicular"></th>
                              <th className="curicular"></th>
                              <th className="curicular"></th>
                              <th className="curicular"></th>
                              <th className="curicular"></th>
                              <th className="curicular"></th>
                              <th className="curicular"></th>
                            </tr>
                            {Curicular.map((s, i) => (
                              <tr>
                                <th className="subject th-preview2">
                                  {s.subject.name}
                                </th>
                                <td className="td-preview">
                                  {/* {term === "2"
                                    ? s.marks[0].final_term
                                      ? (s.marks[0].grade && "") ||
                                        s.marks[0].final_term.toFixed(2)
                                      : (s.marks[0].grade && "") || ""
                                    : s.marks[1].final_term
                                    ? (s.marks[1].grade && "") ||
                                      s.marks[1].final_term.toFixed(2)
                                    : (s.marks[1].grade && "") || ""} */}
                                </td>
                                <td className="td-preview">
                                  {term === "2"
                                    ? s.marks[0].grade ||
                                      (s.marks[0].final_term &&
                                        this.generateGrade(
                                          s.marks[0].final_term
                                        )) ||
                                      ""
                                    : s.marks[0].grade ||
                                      (s.marks[0].final_term &&
                                        this.generateGrade(
                                          s.marks[0].final_term
                                        )) ||
                                      ""}
                                </td>
                                {term === "2" && (
                                  <>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? (s.marks[1].grade && "") ||
                                          s.marks[1].mid_term_value ||
                                          ""
                                        : (s.marks[0].grade && "") ||
                                          s.marks[0].mid_term_value ||
                                          ""}
                                    </td>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? (s.marks[1].grade && "") ||
                                          s.marks[1].end_term_assessment ||
                                          ""
                                        : (s.marks[0].grade && "") ||
                                          s.marks[0].end_term_assessment ||
                                          ""}
                                    </td>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? (s.marks[1].grade && "") ||
                                          s.marks[1].end_term ||
                                          ""
                                        : (s.marks[0].grade && "") ||
                                          s.marks[0].end_term ||
                                          ""}
                                    </td>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? (s.marks[1].grade && "") ||
                                          s.marks[1].final_term
                                          ? (s.marks[1].grade && "") ||
                                            s.marks[1].final_term.toFixed(2)
                                          : (s.marks[1].grade && "") || ""
                                        : s.marks[0].final_term
                                        ? (s.marks[0].grade && "") ||
                                          s.marks[0].final_term.toFixed(2)
                                        : (s.marks[0].grade && "") || ""}
                                    </td>
                                    <td className="td-preview">
                                      {term === "2"
                                        ? s.marks[1].grade ||
                                          (s.marks[1].final_term &&
                                            this.generateGrade(
                                              s.marks[1].final_term
                                            )) ||
                                          "" ||
                                          ""
                                        : s.marks[0].grade || ""}
                                    </td>
                                    <td className="td-preview">
                                      {/* {s.yearly_performance
                                        ? s.yearly_performance.toFixed(2)
                                        : (s.marks[1].grade && "") || ""} */}
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="maximum"></div>
                        {term === "2" && (
                          <div className="maximum">
                            <div className="row">
                              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 mt-1">
                                <p className="font text-center">Final Result</p>
                              </div>
                              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 mt-1">
                                <div className="row">
                                  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <p
                                      className="font"
                                      style={{ textAlign: "left" }}
                                    >
                                      <input
                                        disabled
                                        checked={
                                          student.termly_reports &&
                                          student.termly_reports.length > 0 &&
                                          student.termly_reports[
                                            student.termly_reports.length - 1
                                          ].promoted_to
                                            ? true
                                            : false
                                        }
                                        style={{
                                          margin: "0px 20px 0px 0px",
                                        }}
                                        type="checkbox"
                                        className="checkbox-preview"
                                      />
                                      <b>Promoted to</b>
                                    </p>
                                  </div>
                                  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <p
                                      className="font"
                                      style={{ textAlign: "left" }}
                                    >
                                      <input
                                        disabled
                                        checked={
                                          student.termly_reports &&
                                          student.termly_reports.length > 0 &&
                                          student.termly_reports[
                                            student.termly_reports.length - 1
                                          ].detained_in
                                            ? true
                                            : false
                                        }
                                        style={{
                                          margin: "0px 20px 0px 0px",
                                        }}
                                        type="checkbox"
                                        className="checkbox-preview"
                                      />
                                      <b>Detained in</b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <table
                          className="table table-bordered mb-0"
                          cellspacing="0"
                          cellpadding="0"
                          style={{
                            borderSpacing: "0",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td className="subject2 font text-center td-preview2">
                                {" "}
                                Attendance (Term) 1{" "}
                                {student.attendances &&
                                student.attendances.length > 0
                                  ? ` ${student.attendances[0].attending} `
                                  : " - "}{" "}
                                Out of{" "}
                                {student.attendances &&
                                student.attendances.length > 0
                                  ? ` ${student.attendances[0].max_attend} `
                                  : " - "}
                              </td>
                              {term === "2" && (
                                <td className="subject2 font text-center td-preview2">
                                  {" "}
                                  Attendance (Term) 2{" "}
                                  {student.attendances &&
                                  student.attendances.length > 0
                                    ? ` ${
                                        (student.attendances[1] &&
                                          student.attendances[1].attending) ||
                                        " - "
                                      } `
                                    : " - "}{" "}
                                  Out of{" "}
                                  {student.attendances &&
                                  student.attendances.length > 0
                                    ? ` ${
                                        (student.attendances[1] &&
                                          student.attendances[1].max_attend) ||
                                        " - "
                                      } `
                                    : " - "}{" "}
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                        <div className="containerr">
                          <table className="table table-bordered ">
                            <tbody>
                              <tr>
                                <td
                                  className="comment sub-comment td-preview"
                                  style={{ width: "30%" }}
                                >
                                  Class Teacher's Comments
                                </td>
                                <td
                                  className={
                                    student.termly_reports[
                                      student.termly_reports.length - 1
                                    ].class_teacher_comment.length >= "100"
                                      ? "font2 td-preview4"
                                      : "font td-preview3"
                                  }
                                >
                                  {(student.termly_reports &&
                                    student.termly_reports.length > 0 &&
                                    student.termly_reports[
                                      student.termly_reports.length - 1
                                    ] &&
                                    student.termly_reports[
                                      student.termly_reports.length - 1
                                    ].class_teacher_comment) ||
                                    "-"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table className="table table-bordered ">
                            <tbody>
                              <tr>
                                <td
                                  className="comment sub-comment td-preview"
                                  style={{ width: "30%" }}
                                >
                                  Co Curricular Activities
                                </td>
                                <td
                                  className={
                                    student.termly_reports[
                                      student.termly_reports.length - 1
                                    ].co_curricular_comment.length >= "100"
                                      ? "font2 td-preview4"
                                      : "font td-preview3"
                                  }
                                >
                                  {(student.termly_reports &&
                                    student.termly_reports.length > 0 &&
                                    student.termly_reports[
                                      student.termly_reports.length - 1
                                    ] &&
                                    student.termly_reports[
                                      student.termly_reports.length - 1
                                    ].co_curricular_comment) ||
                                    "-"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* <img src="assets/img/logo/logo MGS.png" alt="logo"/> */}
                      </div>
                      <div className="row footer">
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <p className="sub-footer">Class Teacher</p>
                          <p className="text-center name-footer">
                            ({student.classroom.teacher.name})
                          </p>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <p className="sub-footer">Parent</p>
                          <p className="text-center name-footer">
                            ({student.parent_name})
                          </p>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <p className="sub-footer">Kepala Sekolah</p>
                          <p className="text-center name-footer">
                            ({school.principal})
                          </p>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <p className="sub-footer">Principal</p>
                          <p className="text-center name-footer">
                            ({school.foundation})
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-12">
                <PDFDownloadLink
                  document={<MyDocument />}
                  fileName="Annual.pdf"
                  style={{
                    textDecoration: "none",
                    padding: "10px",
                    color: "#4a4a4a",
                    backgroundColor: "#f2f2f2",
                    border: "1px solid #4a4a4a",
                  }}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download Pdf"
                  }
                </PDFDownloadLink>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
