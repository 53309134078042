import React, { useState, useEffect, useRef, useContext } from 'react';
import Constants from '../../components/Constants';
import Select from 'react-select';
import { FormattedMessage } from "react-intl";
import { StudentSubjects, Classrooms } from '../../services';
import { ToastContainer, toast } from 'react-toastify';
import { Loading } from '../../components';
import 'react-toastify/dist/ReactToastify.css';
import { MaintenanceContext } from '../../Maintenance';

const student_subjects = new StudentSubjects();
const classrooms = new Classrooms();

export default function Scoring(props) {
    useEffect(() => {
        (async () => getClassroomList())();
    }, []);

    const user = JSON.parse(window.localStorage.getItem('user-data'));
    const store = JSON.parse(window.localStorage.getItem('INPUT_SCORE'));
    
    const [templateURL, setTemplateURL] = useState('/api/v1/static/midterm_scorings.csv');
    const [storage, setStorage] = useState({
        classroomId: store?.classroomId || '',
        subjectId: store?.subjectId || '',
        term: store?.term || 1,
        exam: store?.exam || 0,
        studentSubjectList: store?.studentSubjectList || [],
        classroomList: store?.classroomList || [],
        classroomSubjectList: store?.classroomSubjectList || [],
        holder: []
    });
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

    const [loading, setLoading] = useState(false);
    const [loadingClassroom, setLoadingClassroom] = useState(false);
    const [loadingSubject, setLoadingSubject] = useState(false);
    const [payload, setPayload] = useState({
        id: '',
        classroomId: store?.classroomId || '',
        subjectId: store?.subjectId || '',
        exam: store?.exam || '',
        term: store?.term || '',
        updated_by: user.username,
        file: null
    });
    const [mark, setMark] = useState(store?.holder || []);
    const [assessment, setAssessment] = useState(store?.assessment || []);
    const [studentSubjectList, setStudentSubjectList] = useState(store?.studentSubjectList || []);
    const [classroomList, setClassroomList] = useState(store?.classroomList || []);
    const [classroomSubject, setClassroomSubject] = useState(store?.classroomSubjectList || []);
    const [showExam, setShowExam] = useState(true);

    const getStudentSubjectList = (classroomID, subjectID, sExam = showExam) => { 
        setLoading(true);   
        setStudentSubjectList([]);
        student_subjects.list(classroomID, subjectID)
        .then(res => {
            console.log(res);
            const term = payload.term ? payload.term - 1 : 1;
            const exam = payload.exam && sExam ? payload.exam : !sExam ? 5 : 1;

            let holder = [];
            let assess = [];
            res.result.map( item => {
                if (typeof(item.marks[term][examText(exam)]) === 'number') {
                    if (item.marks[term][`${examText(exam)}_ml`]) {
                        holder = [...holder, 'ML'];
                    } else {
                        holder = [...holder, item.marks[term][examText(exam)].toString() || ''];
                    }
                    assess = [...assess, item.marks[term][examText(exam, 'assessment')].toString() || ''];

                    
                } else {
                    if (item.marks[term][`${examText(exam)}_ml`]) {
                        holder = [...holder, 'ML'];
                    } else {
                        holder = [...holder, item.marks[term][examText(exam)] || ''];
                    }
                    assess = [...assess, item.marks[term][examText(exam, 'assessment')] || ''];
                }
            });
            payload.exam = exam;
            payload.subjectId = subjectID;

            let studentByTeacherReligion = res.result;
            const teacherSubjectReligion = user.subjects_data.find(item => item.is_religion_subject);
            console.log(teacherSubjectReligion);
            if (teacherSubjectReligion  && Object.keys(teacherSubjectReligion).length > 0) {
                studentByTeacherReligion = res.result.filter(item => item.student.religion.subject_id === teacherSubjectReligion.id);
            }
            console.log(studentByTeacherReligion);
            setPayload(payload);
            setMark(holder);
            setAssessment(assess);
            
            setStudentSubjectList(studentByTeacherReligion);
            setLoading(false);

            const s = {
                ...storage,
                exam,
                subjectId: subjectID,
                studentSubjectList: res.result,
                term: term + 1
            }
            setStorage(s);

            window.localStorage.setItem('INPUT_SCORE', JSON.stringify(s));
        })
        .catch(err => {
            console.error(err);

            if (err.code === 404) {
                setStudentSubjectList([]);
            }
            setLoading(false);
        });
    };

    const getClassroomList = () => {
        const params = {
            limit: 100
        };
        setLoadingClassroom(true);
        classrooms.list(params)
        .then(res => {
            console.log(res);

            res.result.map( (item, i) => {
                res.result[i].value = item.id;
                res.result[i].label = item.name;
            })     
            setClassroomList(res.result);
            const s = {
                ...storage,
                classroomList: res.result
            }
            setStorage(s);
            setLoadingClassroom(false);

            window.localStorage.setItem('INPUT_SCORE', JSON.stringify(s));
        
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setClassroomList([]);
            }
            if (err.status === 503) {
                setIsMaintenance(true)
            }
            setLoadingClassroom(false);
        });
    };

    const getDetailClassroom = (classroomID) => {
        setStudentSubjectList([]);
        setLoadingSubject(true);
        classrooms.detail(classroomID)
        .then(res => {
            console.log(res);
            if (res.result.subject_list.length > 0) {
                res.result.subject_list.map( (item, i) => {
                    res.result.subject_list[i].value = item.subject_id;
                    res.result.subject_list[i].label = item.name;
                })     
            }

            const teacherID = user.teacher_data.id;
            const subjectList = res.result.subject_list.filter(item => item.teacher_id === teacherID);

            setClassroomSubject(subjectList);            

             const s = {
                 ...storage,
                 classroomId: classroomID,
                 classroomSubjectList: subjectList
             }

             setStorage(s);
             setLoadingSubject(false);

             window.localStorage.setItem('INPUT_SCORE', JSON.stringify(s))
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setClassroomSubject([]);
            }
            setLoadingSubject(false);
        });
    }

    const inputMidTerm = (p) => {        
        setLoading(true);

        student_subjects.inputMidTerm(p)
        .then(res => {
            console.log(res);
            toast.success(<FormattedMessage id='component.text.save-successful'/>);

            // getStudentSubjectList(classroomId, subjectId);
            setLoading(false);
            
        })
        .catch(err => {
            console.error(err);
            if (err.status === 401) {       
                if (err.message === "subject is not under this user") {
                    toast.error('Only the teacher concerned can assess the lesson');
                } else {
                    toast.error(<FormattedMessage id='component.text.not-allowed-input-score'/>);
                }
            } else if(err.status === 403) {
                toast.error('Only the teacher concerned can assess the lesson');
                let marks = [...mark];
                
                setMark(marks);
            } else {                
                toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            }
            setLoading(false);
        });
    };

    const inputEndTerm = (p) => {            
        const { classroomId, subjectId } = payload;
        setLoading(true);
        student_subjects.inputEndTerm(p)
        .then(res => {
            console.log(res);
            toast.success(<FormattedMessage id='component.text.save-successful'/>);

            // getStudentSubjectList(classroomId, subjectId);
            setLoading(false);
            
        })
        .catch(err => {
            console.error(err);
            if (err.status === 401) {  
                if (err.message === "subject is not under this user") {
                    toast.error('Only the teacher concerned can assess the lesson');
                } else {
                    toast.error(<FormattedMessage id='component.text.not-allowed-input-score'/>);
                }
            } else if(err.status === 403) {
                toast.error('Only the teacher concerned can assess the lesson');
                let marks = [...mark];
                
                setMark(marks);
            } else {                
                toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            }
            setLoading(false);
        });
    };

    
    const inputGrade = (p) => {   
        setLoading(true);           
        const { classroomId, subjectId } = payload;
              
        student_subjects.inputGrade(p)
        .then(res => {
            console.log(res);
            toast.success(<FormattedMessage id='component.text.save-successful'/>);

            // getStudentSubjectList(classroomId, subjectId);
            setLoading(false);
            
        })
        .catch(err => {
            console.error(err);
            if (err.status === 401) {    
                if (err.message === "subject is not under this user") {
                    toast.error('Only the teacher concerned can assess the lesson');
                } else {
                    toast.error(<FormattedMessage id='component.text.not-allowed-input-score'/>);
                }
            } else if(err.status === 403) {
                toast.error('Only the teacher concerned can assess the lesson');
                let marks = [...mark];
                
                setMark(marks);
            } else {                
                toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            }
            setLoading(false);
        });
    };

    const importFile = (files) => {
        setLoading(true);

        const formData = new FormData();

        formData.append('file', files);
        formData.append('classroom_id', payload.classroomId);
        formData.append('subject_id', payload.subjectId);
        formData.append('term', payload.term);
        formData.append('updated_by', payload.updated_by);


        student_subjects.importFile(formData, examText(payload.exam))
        .then(res => {
            toast.success(<FormattedMessage id='component.text.save-successful'/>);

            getStudentSubjectList(classroomId, subjectId);
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            
        });
    }

    const handlePayload = async (e, state) => {
        let v = '';
        let holder = [];
        let assess = [];
        
        if (state === 'classroomId' || state === 'subjectId') {
            v = e.id;
            if (state === 'classroomId') {
                getDetailClassroom(v);
            } else {
                if (e.is_curricular) {
                    setPayload({ ...payload, exam: 5 })

                    setShowExam(false);
                } else {
                    setShowExam(true);
                }
                v = e.subject_id;
                if (e.is_curricular) setTemplateURL('static/grading.csv')
                getStudentSubjectList(payload.classroomId, v, !e.is_curricular);
            }
        } else if(state === 'term') { 
            v = parseInt(e.value);
            studentSubjectList.map( item => {
                const x = !showExam ? 5 : exam;
                if (typeof(item.marks[v-1][examText(x)]) === 'number') {
                    if (item.marks[v-1][`${examText(x)}_ml`]) {
                        holder = [...holder, 'ML'];
                    } else {
                        holder = [...holder, item.marks[v-1][examText(x)].toString() || ''];
                    }
                    assess = [...assess, item.marks[v-1][examText(x, 'assessment')].toString() || ''];
                } else {
                    if (item.marks[v-1][`${examText(x)}_ml`]) {
                        holder = [...holder, 'ML'];
                    } else {
                        holder = examText(x) === 'Grade' ? [...holder, item.marks[v-1][examText(x).toLowerCase()]] || '' : [...holder, item.marks[v-1][examText(x)] || ''];
                    }
                    assess = [...assess, item.marks[v-1][examText(x, 'assessment')] || ''];
                }                
            });   
        
            
        } else {
            v = parseInt(e.value);
            generateTemplateURL(v);
            studentSubjectList.map( item => {
                if ( (item.marks[payload.term - 1][examText(v)] !== undefined) && (item.marks[payload.term - 1][examText(v)] !== null)) {
                    if (item.marks[payload.term-1][`${examText(v)}_ml`]) {
                        holder = [...holder, 'ML'];
                    } else {
                        holder = [...holder, item.marks[payload.term - 1][examText(v)].toString()];
                    }
                    assess = [...assess, item.marks[payload.term - 1][examText(v, 'assessment')].toString()];
                }
            });

            const s = {
                ...storage,
                exam: v
            }
            setStorage(s);

            window.localStorage.setItem('INPUT_SCORE', JSON.stringify(s));
        }

        setPayload({
            ...payload,
            [state]: v
        });
        const s = {
            ...storage,
            [state]: v
        }
        if (state !== 'classroomId' || state !== 'file') {
            setMark(holder);
            setAssessment(assess);

            s.holder = holder;
            s.assessment = assess;
        }

        if(showExam) {
            if (Constants.EXAM_LIST.length > 2) {
                Constants.EXAM_LIST.pop();
            }
        }
        
        
        setStorage(s);
        window.localStorage.setItem('INPUT_SCORE', JSON.stringify(s));

    }

    const handleMarkInput = (e, i, type = 'holder') => {
        const x = type === 'holder' ? [...mark] : [...assessment];

        if (x.length > 0) {
            let val =  !showExam ? e.target.value.replace(/[^A-Za-z]/ig, '') : e.target.value.replace(/[^\d]/,'');
            switch(payload.exam) {
                case 2:
                    if (type === 'assessment') {
                        val = parseInt(val) < 0 || parseInt(val) > 20 ? '20' : val;
                    } else {
                        val = parseInt(val) < 0 || parseInt(val) > 20 ? '20' : val;
                    }
                    break;
                case 3:
                    if (type === 'assessment') {
                        val = parseInt(val) < 0 || parseInt(val) > 20 ? '20': val;
                    } else {
                        val = parseInt(val) < 0 || parseInt(val) > 40 ? '40': val;
                    }
                    break;
            }

            x[i] = val;
            const s = {
                ...storage,
                [type]: x
            };

            setStorage(s);
            if(type === 'assessment') {
                setAssessment(x)
            } else {
                setMark(x);
            }

            window.localStorage.setItem('INPUT_SCORE', JSON.stringify(s))
        }
    }

    const doMark = () => {
        const p = {
            term: payload.term, 
            marks: [],
            classroom_id: payload.classroomId,
            subject_id: payload.subjectId,  
            updated_by: user.username,
        }
        const type = payload.exam === 2 ? 'mid_term' : payload.exam === 3 ? 'end_term' : 'grade';
        const marks = [];
        const prop = examText(payload.exam) === 'Grade' ? 'grade' : examText(payload.exam);
        for(let j in mark) {
            if (mark[j] === 'ML') {
                mark[j] = 0;
            }
            studentSubjectList[j].marks[payload.term - 1][prop] = mark[j];
            studentSubjectList[j].marks[payload.term - 1][examText(payload.exam, 'assessment')] = assessment[j];

            marks.push({
                student_id: studentSubjectList[j].student.id,
                [type]: payload.exam === 2 || payload.exam === 3 ? parseInt(mark[j]) : mark[j],
                assessment: parseInt(assessment[j])
            })
        }

        p.marks = marks;
        

        if (payload.exam === 2) {
            inputMidTerm(p);
        } else if (payload.exam === 3) {
            inputEndTerm(p);
        } else {
            inputGrade(p);
        }

        for (let j in marks) {
            if (studentSubjectList[j].marks[payload.term - 1][`${prop}_ml`]) {
                mark[j] = 'ML';
                studentSubjectList[j].marks[payload.term - 1][prop] = mark[j];
            }
        }
        
        setStudentSubjectList([...studentSubjectList]);
        setPayload(payload);
    }

    const examText = (exam, type = 'common', callback = 'prop') => {
        for (let i in Constants.EXAM_LIST) {
            if (parseInt(exam) === Constants.EXAM_LIST[i].value) {
                 let text = Constants.EXAM_LIST[i][callback] 

                 if (type === 'assessment') {
                     text += '_assessment';
                 }

                 return text;
            } else if(exam === 5) {
                return 'Grade';
            }
        }
    }

    const generateTemplateURL = (exam) => {
        switch(exam) {
            case 2:
                setTemplateURL('static/midterm_scorings.csv');
                break;
            case 3:
                setTemplateURL('static/endterm_scorings.csv');
                break;
            case 5:
                setTemplateURL('static/grading.csv');
        }
    }

    const setNA = (na, studentSubjectId) => {
        const p = {
            na,
            updated_by: user.username
        }
        student_subjects.setNA(p, studentSubjectId)
        .then(res => {
            console.log(res);
            for(let i in studentSubjectList) {
                if (studentSubjectList[i].id === studentSubjectId) {
                    studentSubjectList[i].na = na;
                    break;
                }
            }

            setStudentSubjectList([...studentSubjectList]);
        })
        .catch(err => {
            console.log(err);
        })
    }
    const setML = (i, ml, studentSubjectId) => {
        const term = payload.exam === 2 ? 'mid_term' : payload.exam === 3 ? 'end_term' : 'grade'; 
        const p = {
            ml,
            term: payload.term,
            type: term.replace('_',''),
            updated_by: user.username
        }
        student_subjects.setML(p, studentSubjectId)
        .then(res => {
            for(let i in studentSubjectList) {
                if (studentSubjectList[i].id === studentSubjectId) {
                    studentSubjectList[i].marks[payload.term - 1][`${term}_ml`] = ml;
                    break;
                }
            }
            mark[i] = ml ? 'ML' : '0';
            setMark([...mark]);
            setStudentSubjectList([...studentSubjectList]);
        })
        .catch(err => {
            console.log(err);
        })
    }
    const { classroomId, subjectId, term, exam } = payload;
    const t = payload.exam === 2 ? 'mid_term' : payload.exam === 3 ? 'end_term' : 'grade';
    return (
        <div>
            <div className="main">

                <ToastContainer
                    autoClose={5000}
                    hideProgressBar
                    pauseOnHover={false} />
                <div className="main-content">
                    {/* {user.role === 'Guru Mapel' && ( */}
                    
                        <div className="container-fluid">
                        {ismaintenance ? (
    <div className="text-center">
        <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
        <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
    </div>
)  : (
                        <>
                            <h3 className="page-title">
                                <FormattedMessage id="menu.input-score"/>
                            </h3>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="panel">
                                            <div className="panel-header mt-2 mr-2">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <p style={{textAlign : 'left', fontWeight: '700', fontSize : '15'}}>
                                                                <FormattedMessage id="form.classroom.name"/>: 
                                                            </p>
                                                            <div className="form-group">
                                                                <Select
                                                                    value={
                                                                        classroomList?.length > 0 
                                                                        ? classroomList.find(item => item.id === payload.classroomId)
                                                                        : null
                                                                    }
                                                                    placeholder={loadingClassroom ? 'Waiting for data...' : 'Choose the classroom'}                                                                                                   
                                                                    options={classroomList || null}                                    
                                                                    onChange={(e) => handlePayload(e, 'classroomId')}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p style={{textAlign : 'left', fontWeight: '700', fontSize : '15'}}>
                                                                <FormattedMessage id="form.subject.name"/>: 
                                                            </p>
                                                            <div className="form-group">
                                                                <Select
                                                                    value={
                                                                        classroomSubject?.length > 0 
                                                                        ? classroomSubject.find(item => item.subject_id === payload.subjectId)
                                                                        : null
                                                                    }
                                                                    placeholder={loadingSubject ? 'Waiting for data...' : 'Choose the subject'}
                                                                                                                                                         
                                                                    options={classroomSubject || null}                                    
                                                                    onChange={(e) => handlePayload(e, 'subjectId')}
                                                                    isDisabled={classroomSubject.length < 1}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">                                                        
                                                            <div className="col-md-1">
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="col mt-3 mr-auto">
                                                                <a href={`${process.env.REACT_APP_URL_SERVICE}/${templateURL}`} download className="btn btn-info mb-2 mr-2" style={{ float: 'left' }}>
                                                                    <i className="lnr lnr-download"></i>Download template
                                                                </a>
                                                        </div>
                                                        {payload.classroomId && payload.subjectId && payload.term && payload.exam && (
                                                            <label className="btn btn-success mb-2 ml-3">
                                                                <i className="lnr lnr-upload"></i> Import <input type="file" className="file" accept=".csv" onChange={e => importFile(e.target.files[0])}/>
                                                            </label>
                                                        )}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <Select

                                                                value={
                                                                    Constants.TERM_LIST?.length > 0 
                                                                    ? Constants.TERM_LIST.find(item => item.value === payload.term)
                                                                    : null
                                                                }
                                                                isDisabled={!subjectId}
                                                                placeholder={'Choose an term'}                                                                                                   
                                                                options={Constants.TERM_LIST}                                    
                                                                onChange={(e) => handlePayload(e, 'term')}
                                                            />
                                                        </div>
                                                        {showExam && (
                                                            <div className="col-md-3">
                                                                <Select
                                                                    value={
                                                                        Constants.EXAM_LIST?.length > 0 
                                                                        ? Constants.EXAM_LIST.find(item => item.value === payload.exam)
                                                                        : null
                                                                    }
                                                                    isDisabled={!term}
                                                                    placeholder={'Choose an exam'}                                                                                                   
                                                                    options={Constants.EXAM_LIST}                                    
                                                                    onChange={(e) => handlePayload(e, 'exam')}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel-body">
                                                {examText(payload.exam) === 'Grade' && (
                                                    <label><FormattedMessage id="component.text.rule.grade"/> </label>
                                                )}
                                                <table className="table table-bordered mt-2">
                                                    <thead>
                                                        <tr>
                                                        <th scope="col">                                                        
                                                            <FormattedMessage id="form.number"/>
                                                        </th>
                                                        <th scope="col">
                                                            <FormattedMessage id="form.student.name"/>
                                                        </th>
                                                        {examText(payload.exam) !== 'Grade' && (
                                                            <th scope="col" className="text-center">
                                                                <FormattedMessage id="form.na" defaultMessage="NA"/>
                                                            </th>
                                                        )}
                                                        {showExam && (
                                                            <th scope="col">
                                                                <FormattedMessage id="component.text.assessment"/> (20)
                                                            </th>
                                                        )}
                                                        <th scope="col">
                                                            {payload.exam === 2 ? `${examText(payload.exam, 'common', 'label')} (20)` : `${examText(payload.exam, 'common', 'label')} ${showExam ? '(40)': ''} `}
                                                        </th>
                                                        {examText(payload.exam) !== 'Grade' && (
                                                            <th scope="col" className="text-center">
                                                                <FormattedMessage id="form.ml" defaultMessage="ML"/>
                                                            </th>
                                                        )}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loading && (
                                                            <tr><td colspan="4"><Loading visible={loading}/></td></tr>
                                                        )}
                                                        {
                                                        classroomId &&
                                                        term > 0 &&
                                                        exam > 1 &&
                                                        studentSubjectList && 
                                                        studentSubjectList.length > 0 && 
                                                        studentSubjectList.map((item, i) => (
                                                            <>
                                                                <tr key={i}>
                                                                    <th scope="row">{ i + 1}</th>
                                                                    <td>{item.student.name}</td>
                                                                    {examText(payload.exam) !== 'Grade' && (
                                                                        <td className="text-center">
                                                                            <input 
                                                                                type="checkbox" 
                                                                                checked={item.na}
                                                                                onClick={() => setNA(!item.na, item.id)}/>
                                                                        </td>
                                                                    )}
                                                                   

                                                                    {showExam && (

                                                                    <td>
                                                                        <input 
                                                                            pattern={"[A-Za-z]"}
                                                                            type="text" 
                                                                            className="form-control" 
                                                                            style={{
                                                                                width:'60px',
                                                                                border:'0px',
                                                                                boxShadow: 'none',
                                                                                fontWeight:'bold'
                                                                            }} 
                                                                            maxLength="4" 
                                                                            placeholder="enter"
                                                                            value={assessment[i]}
                                                                            onChange={e => handleMarkInput(e, i, 'assessment')}                                                                           
                                                                        />
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <input 
                                                                            pattern={"[A-Za-z]"}
                                                                            type="text" 
                                                                            className="form-control" 
                                                                            style={{
                                                                                width:'60px',
                                                                                border:'0px',
                                                                                boxShadow: 'none',
                                                                                fontWeight:'bold'
                                                                            }} 
                                                                            maxLength="4" 
                                                                            placeholder="enter"
                                                                            value={mark[i]}
                                                                            onChange={e => handleMarkInput(e, i, 'holder')}
                                                                        />
                                                                        </td>
                                                                        {item.marks.length > 0 && examText(payload.exam) !== 'Grade' && (
                                                                        <td className="text-center">                     
                                                                            <input 
                                                                                type="checkbox" 
                                                                                checked={item.marks[payload.term - 1][`${t}_ml`]}
                                                                                onClick={() => setML(i, !item.marks[payload.term - 1][`${t}_ml`], item.id)}/>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            </>
                                                        ))}
                                                        {
                                                            classroomId &&
                                                            term > 0 &&
                                                            exam > 1 &&
                                                            studentSubjectList && 
                                                            studentSubjectList.length > 0 &&
                                                            <tr>
                                                                <td colspan="6">
                                                                    <button className="btn btn-success col-md-12" onClick={doMark}>Save</button> 
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>    
                                    </div>
                                </div>
                                </> 
                            )}
                            </div>
                        {/* )} */}
                    </div>
                <div>
            </div>
        </div>
    </div>
    )
}
