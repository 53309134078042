import React, { Component } from "react";
import { Helpers, Loading } from "../../../components";
import { Students, ReportHistory, Configurations } from "../../../services";
import { FormattedMessage } from "react-intl";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";

const students = new Students();
const reportHistory = new ReportHistory();
const helpers = new Helpers();
const configuration = new Configurations();

export default class MidTermPDF extends Component {
  constructor(props) {
    super(props);
    this.generic = {
      student_id: "",
      classroom_id: "",
      created_by: "APP",
    };
    this.state = {
      Curicular: "",
      nonCuricular: "",
      validate: "mid-non",
      container: "mid-non2",
      bg: "bg",

      loading: false,
      student: null,
      isEdited: false,
      term: window.location.pathname.split("/")[5],
      school: {
        principal: "",
        foundation: "",
      },
      attendance: {
        ...this.generic,
        term: 0,
        attending: 0,
        max_attend: 0,
      },

      yearly_report: {
        ...this.generic,
        session: null,
        promoted_to: false,
        compartment_in: false,
        detained_in: false,
        class_teacher_comment: "",
        co_curricular_comment: "",
      },

      subject_list: [],
      aggregate_marks: [],
      session: "",
      notAllowed: false,
    };
  }

  componentDidMount() {
    this.getPrincpalSchool();
    const params = new URLSearchParams(window.location.search)
    const historyID = params.get("history");
    const sessionId = params.get("session");

    if (historyID) {
      this.getDetailHistoryReport(historyID);
    } else if (sessionId) {
      this.getHistoryReportBySession(sessionId);
    } else {
      this.getStudentReportData();
    }
  }
  getPrincpalSchool = () => {
    configuration
      .principal()
      .then((res) => {
        this.setState({
          school: {
            principal: res.result.value.created_at,
            foundation: res.result.value.end_at,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  action = (toPdf) => {
    this.setState(
      {
        validate: "mid-actived",
        container: "mid-actived2",
        bg: "bg",
      },
      () => {
        toPdf();
        this.setState({
          validate: "mid-non",
          container: "mid-non2",
          bg: "bg",
        });
      }
    );
  };
  action2 = (toPdf) => {
    this.setState(
      {
        validate: "mid-actived",
        container: "mid-actived2",
        bg: "no-bg",
      },
      () => {
        toPdf();
        this.setState({
          validate: "mid-non",
          container: "mid-non2",
          bg: "bg",
        });
      }
    );
  };

  getDetailHistoryReport = (historyID) => {
    this.setState({ loading: true });
    reportHistory
      .detail(historyID)
      .then(async (res) => {
        console.log(res);

        res.result.report_data.subjects = res.result.report_data.subjects.sort(
          (a, b) => a.subject.is_curricular - b.subject.is_curricular
        );
        var curicular = res.result.report_data.subjects.filter(
          (item) => item.subject.is_curricular === true
        );
        var noncuricular = res.result.report_data.subjects.filter(
          (item) => item.subject.is_curricular === false
        );
        res.result.report_data.address = res.result.student.address
        res.result.report_data.parent_name = res.result.student.parent_name
        res.result.report_data.date_of_birth = res.result.student.date_of_birth
        res.result.report_data.house = res.result.student.house
        res.result.report_data.telp_mob = res.result.student.telp_mob
        res.result.report_data.telp_res = res.result.student.telp_res
        res.result.report_data.gender = res.result.student.gender
        this.setState({
          student: res.result.report_data,
          loading: false,
          session: res.result.session.name,
          Curicular: curicular,
          nonCuricular: noncuricular,
        });
      })
      .catch((err) => {
        console.error(err);

        this.setState({ loading: false });
      });
  };
  getHistoryReportBySession = (sessionId) => {
    const studentId = window.location.pathname.split("/")[3];
    this.setState({ loading: true });
    reportHistory
      .studentList(studentId,sessionId)
      .then(async (res) => {
        console.log(res);
        // const { subject_list, aggregate_marks } = await this.setStudentReportData(res.result);
        res.result.report_data.subjects = res.result.report_data.subjects.sort(
          (a, b) => a.subject.is_curricular - b.subject.is_curricular
        );
        var curicular = res.result.report_data.subjects.filter(
          (item) => item.subject.is_curricular === true
        );
        var noncuricular = res.result.report_data.subjects.filter(
          (item) => item.subject.is_curricular === false
        );
        // console.log("imama: ", res.result.report_data.subjects);
        res.result.report_data.address = res.result.student.address
        res.result.report_data.parent_name = res.result.student.parent_name
        res.result.report_data.date_of_birth = res.result.student.date_of_birth
        res.result.report_data.house = res.result.student.house
        res.result.report_data.telp_mob = res.result.student.telp_mob
        res.result.report_data.telp_res = res.result.student.telp_res
        res.result.report_data.gender = res.result.student.gender
        this.setState({
          student: res.result.report_data,
          loading: false,
          session: res.result.session.name,
          Curicular: curicular,
          nonCuricular: noncuricular,
        });
      })
      .catch((err) => {
        console.error(err);

        this.setState({ loading: false });
      });
  };

  getStudentReportData = () => {
    const studentId = window.location.pathname.split("/")[3];

    this.setState({ loading: true });
    students
      .getStudentReportData(studentId)
      .then(async (res) => {
        console.log(res);
        res.result.subjects = res.result.subjects.sort(
          (a, b) => a.subject.is_curricular - b.subject.is_curricular
        );
        var curicular = res.result.subjects.filter(
          (item) => item.subject.is_curricular === true
        );
        var noncuricular = res.result.subjects.filter(
          (item) => item.subject.is_curricular === false
        );
        this.setState({
          student: res.result,
          loading: false,
          Curicular: curicular,
          nonCuricular: noncuricular,
          session: res.result.session.name,
        });
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 401) {
          this.setState({ notAllowed: true, loading: false });
        }
        this.setState({ loading: false });
      });
  };

  render() {
    const ref = React.createRef();
    const options = {
      orientation: "potrait",
      unit: "in",
      format: [550, 730],
    };
    const {
      Curicular,
      nonCuricular,
      validate,
      container,
      bg,
      student,
      term,
      loading,
      notAllowed,
      school,
    } = this.state;
    const styles = StyleSheet.create({
      page: {
        flexDirection: "row",
        backgroundColor: "white",
      },
      bggrey: {
        backgroundColor: "#ccc",
      },
      bg: {
        backgroundImage: `url(${process.env.PUBLIC_URL + "/paper.jpeg"})`,
        height: "auto",
        backgroundRepeat: "no-repeat",
      },
      container: {
        margin: "auto",
        marginTop: 115,
        marginBottom: 0,
        paddingBottom: 0,
        marginLeft: 52,
        height: "auto",
        display: "block",
        width: "auto",
      },
      sign: {
        borderWidth: 1,
        borderBottom: 0,
        borderLeft: 0,
        borderRight: 0,
        marginLeft: 7.5,
        marginRight: 10,
      },
      row: {
        flexDirection: "row",
        display: "block",
        width: "auto",
        padding: 0,
      },
      col: {
        flexDirection: "column",
        display: "block",
        width: "auto",
      },
      col0: {
        width: 35,
        padding: 3,
      },
      col1: {
        width: 70,
        padding: 0,
      },
      col2: {
        width: 105,
        padding: 0,
      },
      col3: {
        width: 140,
        padding: 0,
      },
      col4: {
        width: 175,
        padding: 0,
      },
      col5: {
        width: 210,
        padding: 0,
      },
      col6: {
        width: 245,
        padding: 0,
      },
      col7: {
        width: 280,
        padding: 0,
      },
      col8: {
        width: 315,
        padding: 3,
      },
      col9: {
        width: 350,
        padding: 0,
      },
      col10: {
        width: 385,
        padding: 0,
      },
      col11: {
        width: 420,
        padding: 0,
      },
      col12: {
        width: 455,
        padding: 0,
      },
      tdcol0: {
        borderWidth: 1,
        color: "#000",
        width: 35,
        padding: 3,
      },
      tdcol1: {
        borderWidth: 1,
        color: "#000",
        width: 70,
        padding: 3,
      },
      tdcol2: {
        borderWidth: 1,
        color: "#000",
        width: 105,
        padding: 3,
      },
      tdcol3: {
        borderWidth: 1,
        color: "#000",
        width: 140,
        padding: 3,
      },
      tdcol4: {
        borderWidth: 1,
        color: "#000",
        width: 175,
        padding: 3,
      },
      tdcol5: {
        borderWidth: 1,
        color: "#000",
        width: 210,
        padding: 3,
      },
      tdcol6: {
        borderWidth: 1,
        color: "#000",
        width: 245,
        padding: 3,
      },
      tdcol7: {
        borderWidth: 1,
        color: "#000",
        width: 280,
        padding: 3,
      },
      tdcol8: {
        borderWidth: 1,
        color: "#000",
        width: 315,
        padding: 3,
      },
      tdcol9: {
        borderWidth: 1,
        color: "#000",
        width: 350,
        padding: 3,
      },
      tdcol10: {
        borderWidth: 1,
        color: "#000",
        width: 385,
        padding: 3,
      },
      tdcol11: {
        borderWidth: 1,
        color: "#000",
        width: 420,
        padding: 3,
      },
      tdcol12: {
        borderWidth: 1,
        color: "#000",
        width: 455,
        padding: 3,
      },
      tdcol13: {
        borderWidth: 1,
        color: "#000",
        width: 490,
        padding: 3,
      },
      text: {
        padding: 0,
        fontSize: 8,
        color: "#000",
        textAlign: "center",
      },
      textlittle: {
        padding: 0,
        fontSize: 7.5,
        color: "#000",
        textAlign: "center",
      },
      textheader: {
        padding: 0,
        fontSize: 9,
        color: "#000",
        textAlign: "center",
      },
      text2: {
        padding: 0,
        fontSize: 8,
        color: "#000",
        textAlign: "center",
        fontWeight: "bold",
      },
      text3: {
        padding: 0,
        fontSize: 7,
        color: "#000",
        textAlign: "center",
        fontWeight: "bold",
      },
      name: {
        backgroundColor: "grey",
        margin: 5,
        fontSize: 8,
        color: "#000",
      },
      tleft: {
        textAlign: "left",
      },
      tcenter: {
        textAlign: "center",
      },
      fleft: {
        float: "left",
      },
      fright: {
        float: "right",
      },
      mt05: {
        marginTop: "5",
      },
      mt1: {
        marginTop: "10",
      },
      mt2: {
        marginTop: "20",
      },
      mt3: {
        marginTop: "30",
      },
      mt33: {
        marginTop: "32",
      },
      mt4: {
        marginTop: "40",
      },
      mt5: {
        marginTop: "50",
      },
      mt6: {
        marginTop: "60",
      },
      mt7: {
        marginTop: "70",
      },
      mt8: {
        marginTop: "80",
      },
      mt9: {
        marginTop: "90",
      },
      mt10: {
        marginTop: "100",
      },
      mb05: {
        marginTop: "5",
      },
      mb1: {
        marginTop: "10",
      },
      mb2: {
        marginTop: "20",
      },
      mb3: {
        marginTop: "30",
      },
      mb4: {
        marginTop: "40",
      },
      mb5: {
        marginTop: "50",
      },
      mb6: {
        marginTop: "60",
      },
      mb7: {
        marginTop: "70",
      },
      mb8: {
        marginTop: "80",
      },
      mb9: {
        marginTop: "90",
      },
      mb10: {
        marginTop: "100",
      },
      image: {
        width: 17,
        height: 17,
      },
      min: {
        height: 420,
      },
    });

    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.container}>
            <View style={styles.row}>
              <View style={styles.col3}></View>
              <View style={[styles.tdcol6, styles.bggrey]}>
                <Text style={styles.textheader}>
                  MID TERM {term === "3" ? "1" : "2"} REPORT
                </Text>
              </View>
              <View style={styles.col3}></View>
            </View>

            <View style={[styles.row, styles.mt1]}>
              <View style={[styles.col9, styles.mb05]}>
                <Text style={[styles.textheader, styles.tleft]}>
                  Name :{" "}
                  <View style={styles.bggrey}>
                    <Text style={styles.textheader}>{student.name}</Text>
                  </View>
                </Text>
              </View>
              <View style={[styles.col2, styles.mb05]}>
                <Text style={[styles.textheader, styles.tright]}>
                  Session : {this.state.session}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.col4, styles.mb05]}>
                <Text style={[styles.textheader, styles.tleft]}>
                  Date of Birth :{" "}
                  {helpers.formatDate(student.date_of_birth, "LL") || "-"}{" "}
                </Text>
              </View>
              <View style={[styles.col1, styles.mb05]}>
                <Text style={[styles.textheader, styles.tleft]}>
                  Gender : {student.gender || "-"}
                </Text>
              </View>
              <View style={[styles.col6, styles.mb05]}>
                <Text style={[styles.textheader, styles.tleft]}>
                  Parent's name :
                  <View style={styles.bggrey}>
                    <Text
                      style={
                        student.parent_name.length >= 30
                          ? [styles.textlittle, styles.fleft]
                          : [styles.textheader, styles.fleft]
                      }
                    >
                      {student.parent_name}
                    </Text>
                  </View>
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.col3, styles.mb05]}>
                <Text style={[styles.textheader, styles.tleft]}>
                  Grade : {student.classroom.name || "-"}
                </Text>
              </View>
              <View style={[styles.col2, styles.mb05]}>
                <Text style={[styles.textheader, styles.tleft]}>
                  Roll No : {student.roll_no || "-"}{" "}
                </Text>
              </View>
              <View style={[styles.col2, styles.mb05]}>
                <Text style={[styles.textheader, styles.tleft]}>
                  Tel: Res : {student.telp_res || "-"}
                </Text>
              </View>
              <View style={[styles.col2, styles.mb05]}>
                <Text style={[styles.textheader, styles.tright]}>
                  Tel: Mob : {student.telp_mob || "-"}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.col3, styles.mb05]}>
                <Text style={[styles.textheader, styles.tleft]}>
                  House : {student.house || "-"}{" "}
                </Text>
              </View>
              <View style={[styles.col9, styles.mb05]}>
              <Text style={[styles.textheader, styles.tleft]}>
              Address :
                  <View style={styles.bggrey}>
                    <Text
                       style={
                        student.address.length >= 60
                          ? [styles.textlittle, styles.tleft]
                          : [styles.textheader, styles.tleft]
                      }
                    >
                      {student.address || "-"}
                    </Text>
                  </View>
                </Text>
              
              </View>
            </View>
            <View style={[styles.min, styles.mt33]}>
              <View style={styles.row}>
                <View style={styles.tdcol4}>
                  <Text style={[styles.text2, styles.tleft]}>Subject</Text>
                </View>

                <View style={styles.tdcol2}>
                  <Text style={styles.text}> I. Assessment(20)</Text>
                </View>
                <View style={styles.tdcol2}>
                  <Text style={styles.text}> Mid Term(20)</Text>
                </View>
                <View style={styles.tdcol2}>
                  <Text style={styles.text2}> Total(20 + 20) </Text>
                </View>
              </View>
              {/* column for body */}
              {nonCuricular.map((item, i) => (
                <View style={styles.row}>
                  <View style={styles.tdcol4}>
                    <Text style={[styles.text, styles.tleft]}>
                      {" "}
                      {item.subject.name}
                    </Text>
                  </View>
                  <>
                    <View style={styles.tdcol2}>
                      <Text style={styles.text}>
                        {term === "3"
                          ? item.marks[0].grade ||
                            item.marks[0].mid_term_assessment ||
                            "-"
                          : item.marks[1].grade ||
                            item.marks[1].mid_term_assessment ||
                            "-"}
                      </Text>
                    </View>
                    <View style={styles.tdcol2}>
                      <Text style={styles.text}>
                        {term === "3"
                          ? (item.marks[0].grade_ml && "ML") ||
                            item.marks[0].grade ||
                            (item.marks[0].mid_term_ml && "ML") ||
                            item.marks[0].mid_term ||
                            "-"
                          : (item.marks[1].grade_ml && "ML") ||
                            item.marks[1].grade_ml ||
                            (item.marks[1].mid_term_ml && "ML") ||
                            item.marks[1].mid_term ||
                            "-"}
                      </Text>
                    </View>
                    <View style={styles.tdcol2}>
                      <Text style={styles.text}>
                        {term === "3"
                          ? item.marks[0].grade ||
                            item.marks[0].mid_term_value ||
                            "-"
                          : item.marks[1].grade ||
                            item.marks[1].mid_term_value ||
                            "-"}
                      </Text>
                    </View>
                  </>
                </View>
              ))}
              {student?.aggregate_marks?.length > 0 && (
                <View style={styles.row}>
                  <View style={styles.tdcol4}>
                    <Text style={[styles.text, styles.tleft]}>Total Marks</Text>
                  </View>
                  <View style={styles.tdcol2}>
                    <Text style={styles.text}>
                      {term === "3"
                        ? student.aggregate_marks[0]
                            .total_mid_term_assessment || "-"
                        : student.aggregate_marks[1]
                            .total_mid_term_assessment || "-"}
                    </Text>
                  </View>

                  <View style={styles.tdcol2}>
                    <Text style={styles.text}>
                      {term === "3"
                        ? student.aggregate_marks[0].total_mid_term || "-"
                        : student.aggregate_marks[1].total_mid_term || "-"}
                    </Text>
                  </View>
                  <View style={styles.tdcol2}>
                    <Text style={styles.text}>
                      {term === "3"
                        ? student.aggregate_marks[0].total_mid_term_value || "-"
                        : student.aggregate_marks[1].total_mid_term_value ||
                          "-"}
                    </Text>
                  </View>
                </View>
              )}
              {student?.aggregate_marks?.length > 0 && (
                <View style={styles.row}>
                  <View style={styles.tdcol7}>
                    <Text style={[styles.text, styles.tleft]}>
                      Aggregate Percentage :{" "}
                      {term === "3"
                        ? student.aggregate_marks[0]
                            .total_percentage_mid_term_value
                          ? student.aggregate_marks[0].total_percentage_mid_term_value.toFixed(
                              2
                            )
                          : "-"
                        : student.aggregate_marks[1]
                            .total_percentage_mid_term_value
                        ? student.aggregate_marks[1].total_percentage_mid_term_value.toFixed(
                            2
                          )
                        : "-"}
                      %
                    </Text>
                  </View>

                  <View style={styles.tdcol5}>
                    <Text style={[styles.text, styles.tleft]}>
                      Maximum Marks :{" "}
                      {term === "3"
                        ? student.aggregate_marks[0].max_mid_term_value || "-"
                        : student.aggregate_marks[1].max_mid_term_value || "-"}
                    </Text>
                  </View>
                </View>
              )}
            </View>
            <View style={[styles.row, styles.mt3]}>
              <View style={[styles.col2, styles.sign]}>
                <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                  {" "}
                  Class Teacher{" "}
                </Text>
                <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                  ({student.classroom.teacher.name})
                </Text>
              </View>
              <View style={[styles.col2, styles.sign]}>
                <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                  {" "}
                  Parent{" "}
                </Text>
                <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                  ({student.parent_name})
                </Text>
              </View>
              <View style={[styles.col2, styles.sign]}>
                <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                  {" "}
                  Kepala Sekolah{" "}
                </Text>
                <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                  ({school.principal})
                </Text>
              </View>
              <View style={[styles.col2, styles.sign]}>
                <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                  {" "}
                  Principal{" "}
                </Text>
                <Text style={[styles.text, styles.tcenter, styles.mt05]}>
                  ({school.foundation})
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
    return (
      <>
        <Loading visible={loading} />
        {!loading && notAllowed && (
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-5">
                <div className="text-center">
                  <img
                    src="assets/img/no-data.svg"
                    className="img-fluid mb-5"
                    style={{ width: "500px" }}
                  />
                  <h3 className="page-title">
                    <FormattedMessage id="component.text.cannot-print" />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        )}
        {!loading && !notAllowed && student && (
          <>
            <div ref={ref} className={validate}>
              <div className={bg}>
                <div className={container}>
                  {/* <div className="row">
                                    <div className="col-xs-3 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                                        <img src="assets/img/logo/logo MGS.png" className="logo-preview" alt="logo"/>
                                    </div>
                                    <div className="col-xs-9 col-sm-8 col-md-10 col-lg-10 col-xl-10">
                                        <h1 className="heading-preview">{process.env.REACT_APP_NAME}</h1>
                                        <div className="list"/>
                                        <div className="sub">
                                            <p>Jl. Tabing, Blok B-16 No. 3, Kemayoran, Jakarta Pusat</p>
                                            <p>Tel : +62-21-6542241 Fax: +62-21-6542479</p>
                                        </div>
                                    </div>
                                </div> */}
                  <div className="row mb-1 banner">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"></div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "#ccc",
                          textAlign: "center",
                          fontSize: "15px",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        <span className="text-center">
                          MID TERM {term === "3" ? "1" : "2"} REPORT
                        </span>
                      </div>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"></div>
                  </div>
                  <div
                    className="row"
                    style={{ paddingBottom: "0px", marginBottom: "0px" }}
                  >
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 ">
                      <div className="identitas">
                        <p>
                          Name : <span className="name">{student.name}</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 ">
                      <div className="identitas">
                        <p style={{ float: "right" }}>
                          Session :
                          <span className="sub-identitas">
                            {this.state.session}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <div className="identitas">
                        <p>
                        Date of Birth :{" "}
                          <span className="sub-identitas">
                            {" "}
                            {helpers.formatDate(student.date_of_birth, "LL") ||
                              "-"}{" "}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <div className="identitas">
                        <p>
                          Gender :{" "}
                          <span className="sub-identitas">
                            {student.gender || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="identitas">
                        <p style={{ float: "right" }}>
                          Parent's name :{" "}
                          <span className="sub-identitas">
                            {student.parent_name}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                      <div className="identitas">
                        <p>
                          Grade :{" "}
                          <span className="sub-identitas">
                            {student.classroom.name || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <div className="identitas">
                        <p>
                          Roll No :{" "}
                          <span className="sub-identitas">
                            {" "}
                            {student.roll_no || "-"}{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                      <div className="identitas">
                        <p>
                          Tel: Res :{" "}
                          <span className="sub-identitas">
                            {student.telp_res || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                      <div className="identitas">
                        <p style={{ float: "right" }}>
                          Tel: Mob. :{" "}
                          <span className="sub-identitas">
                            {student.telp_mob || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                      <div className="identitas">
                        <p>
                          House :{" "}
                          <span className="sub-identitas">
                            {" "}
                            {student.house || "-"}{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                      <div className="identitas">
                        <p>
                          Address :{" "}
                          <span className="sub-identitas">
                            {student.address || "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                      <div className="img-overlay2 text-center">
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th className="th-preview2" rowSpan="2">
                                Subject
                              </th>
                            </tr>
                            <tr>
                              <>
                                <th
                                  colSpan="1"
                                  className="th-preview border-delete"
                                >
                                  I. Assessment(20)
                                </th>
                                <th className="th-preview" colSpan="1">
                                  Mid Term(20)
                                </th>
                                <th
                                  colSpan="1"
                                  className="th-preview border-delete"
                                >
                                  Total(20 + 20){" "}
                                </th>
                              </>
                            </tr>
                          </thead>
                          <tbody>
                            {nonCuricular.map((item, i) => (
                              <tr>
                                <th className="subject th-preview2">
                                  {item.subject.name}
                                </th>

                                <>
                                  <td className="td-preview">
                                    {term === "3"
                                      ? item.marks[0].grade ||
                                        item.marks[0].mid_term_assessment ||
                                        "-"
                                      : item.marks[1].grade ||
                                        item.marks[1].mid_term_assessment ||
                                        "-"}
                                  </td>
                                  <td className="td-preview">
                                    {term === "3"
                                      ? (item.marks[0].grade_ml && "ML") ||
                                        item.marks[0].grade ||
                                        (item.marks[0].mid_term_ml && "ML") ||
                                        item.marks[0].mid_term ||
                                        "-"
                                      : (item.marks[1].grade_ml && "ML") ||
                                        item.marks[1].grade_ml ||
                                        (item.marks[1].mid_term_ml && "ML") ||
                                        item.marks[1].mid_term ||
                                        "-"}
                                  </td>
                                  <td className="td-preview">
                                    {term === "3"
                                      ? item.marks[0].grade ||
                                        item.marks[0].mid_term_value ||
                                        "-"
                                      : item.marks[1].grade ||
                                        item.marks[1].mid_term_value ||
                                        "-"}
                                  </td>
                                </>
                              </tr>
                            ))}

                            {student?.aggregate_marks?.length > 0 && (
                              <tr>
                                <th className="subject font th-preview2">
                                  <b>Total Marks</b>
                                </th>
                                <td className="td-preview">
                                  {term === "3"
                                    ? student.aggregate_marks[0]
                                        .total_mid_term_assessment || "-"
                                    : student.aggregate_marks[1]
                                        .total_mid_term_assessment || "-"}
                                </td>

                                <>
                                  <td className="td-preview">
                                    {term === "3"
                                      ? student.aggregate_marks[0]
                                          .total_mid_term || "-"
                                      : student.aggregate_marks[1]
                                          .total_mid_term || "-"}
                                  </td>
                                  <td className="td-preview">
                                    {term === "3"
                                      ? student.aggregate_marks[0]
                                          .total_mid_term_value || "-"
                                      : student.aggregate_marks[1]
                                          .total_mid_term_value || "-"}
                                  </td>
                                </>
                              </tr>
                            )}
                            {student?.aggregate_marks?.length > 0 && (
                              <tr>
                                <th
                                  className="subject font th-preview2"
                                  colSpan="2"
                                >
                                  <b>Percentage Secured</b> :{" "}
                                  {term === "3"
                                    ? student.aggregate_marks[0]
                                        .total_percentage_mid_term_value
                                      ? student.aggregate_marks[0].total_percentage_mid_term_value.toFixed(
                                          2
                                        )
                                      : "-"
                                    : student.aggregate_marks[1]
                                        .total_percentage_mid_term_value
                                    ? student.aggregate_marks[1].total_percentage_mid_term_value.toFixed(
                                        2
                                      )
                                    : "-"}
                                  %
                                </th>
                                <th
                                  className="subject font th-preview"
                                  colSpan="2"
                                >
                                  <b>Maximum Marks</b> :{" "}
                                  {term === "3"
                                    ? student.aggregate_marks[0]
                                        .max_mid_term_value || "-"
                                    : student.aggregate_marks[1]
                                        .max_mid_term_value || "-"}
                                </th>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      {/* <img src="assets/img/logo/logo MGS.png" alt="logo"/> */}
                      <div className="row footer">
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <p className="sub-footer">Class Teacher</p>
                          <p className="text-center name-footer">
                            ({student.classroom.teacher.name})
                          </p>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <p className="sub-footer">Parent</p>
                          <p className="text-center name-footer">
                            ({student.parent_name})
                          </p>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <p className="sub-footer">Kepala Sekolah</p>
                          <p className="text-center name-footer">
                            ({school.principal})
                          </p>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <p className="sub-footer">Principal</p>
                          <p className="text-center name-footer">
                            ({school.foundation})
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-12">
                <PDFDownloadLink
                  document={<MyDocument />}
                  fileName="MidTerm.pdf"
                  style={{
                    textDecoration: "none",
                    padding: "10px",
                    color: "#4a4a4a",
                    backgroundColor: "#f2f2f2",
                    border: "1px solid #4a4a4a",
                  }}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download Pdf"
                  }
                </PDFDownloadLink>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
