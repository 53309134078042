require('dotenv').config();
export default class StudentSubjects {
    
    constructor() {}

    list = async (classroomID, subjectID) => {        

        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/student-subject/list/${classroomID}/${subjectID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',            
                    'authorization': token
                }
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    detail = async (id) => {        
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/student-subject/detail/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',            
                    'authorization': token
                }
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    create = async (payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/student-subject/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',            
                    'authorization': token
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.code === 201) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    inputMidTermAssessment = async (id, payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/student-subject/mid-term-assessment/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',            
                    'authorization': token
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                if (res.status === 403) {
                    return reject(res);
                }
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                })
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    inputMidTerm = async (payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/student-subject/batch/mid_term`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',            
                    'authorization': token
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                if (res.status === 403) {
                    return reject(res);
                }
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }

    inputEndTermAssessment = async (id, payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/student-subject/end-term-assessment/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',            
                    'authorization': token
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                if (res.status === 403) {
                    return reject(res);
                }
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }

    inputEndTerm = async (payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/student-subject/batch/end_term`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',            
                    'authorization': token
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                if (res.status === 403) {
                    return reject(res);
                }
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }

    inputFinalTerm = async (id, payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/student-subject/final-term/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',            
                    'authorization': token
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                if (res.status === 403) {
                    return reject(res);
                }
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    
    inputGrade = async (payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/student-subject/batch/grade`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',            
                    'authorization': token
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                if (res.status === 403) {
                    return reject(res);
                }
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }

    importFile = (payload, markType) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/student-subject/import/${markType}`, {
                method: 'POST',            
                body: payload
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }

    setNA = (payload, studentSubjectId) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/student-subject/set-na/${studentSubjectId}`, {
                method: 'POST',            
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `${token}`
                }
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    setML = (payload, studentSubjectId) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/student-subject/set-ml/${studentSubjectId}`, {
                method: 'POST',            
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `${token}`
                }
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
}