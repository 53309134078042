import React, { useState, useEffect, useRef, useContext} from 'react';
import { Users } from '../../services';
import { FormattedMessage } from "react-intl";
import { ToastContainer, toast } from 'react-toastify';
import { MaintenanceContext } from '../../Maintenance';

import 'react-toastify/dist/ReactToastify.css';
const users = new Users();
export default function ChangePassword(props) {

    useEffect(() => {
    }, []);

    const user = JSON.parse(window.localStorage.getItem('user-data'));
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);
    const [payload, setPayload] = useState({
        id: user.id,
        old_password: '',
        password: '',
        confirmNewPassword: ''
    });
    const [loading, setLoading] = useState(false);

    const data = [
        {
            id: 'menu.old-password',
            defaultMessage: 'Old Password',
            state: 'old_password'
        },
        {
            id: 'menu.new-password',
            defaultMessage: 'New Password',
            state: 'password'
        },
        {
            id: 'menu.confirm-new-password',
            defaultMessage: 'Confirm New Password',
            state: 'confirmNewPassword'
        }
    ]

    const handleSetPayload = (val, state) => {
        payload[state] = val;

        setPayload({ ...payload });
    }

    const onSubmit = () => {
        setLoading(true);
        if (payload.password !== payload.confirmNewPassword) {
            toast.error(<FormattedMessage id="component.text.confirm-password-invalid"/>);
            setLoading(false);
            return
        }

        users.changePassword(payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                reset();
                setLoading(false);
            }

        })
        .catch(err => {
            console.error(err)
            if (err.status.message === 'invalid-old_password') {
                toast.error(<div>{<FormattedMessage id='component.text.invalid-password'/>}</div>)
            } else {
                toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            }
            setLoading(false);
        });
    }

    const reset = () => {
        setPayload({
            id: user.id,
            old_password: '',
            password: '',
            confirmNewPassword: ''
        });
    }

    return (
        <div className="main">
            <ToastContainer
            autoClose={5000}
            hideProgressBar
            pauseOnHover={false} />
            <div className="main-content">
                {/* {(user.role === 'Wali Kelas' || user.role === 'Admin') &&  ( */}
                    
                <div className="container-fluid">
                    {ismaintenance 
                    ? (
                        <div className="text-center">
                            <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
                            <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
                        </div>
                    )  
                    : 
                    (
                        <>
                            <h3 className="page-title">
                                <FormattedMessage id="menu.change-password" defaultMessage="Change Password"/>
                            </h3>
                            <form onSubmit={(e) => {e.preventDefault(); onSubmit();}}>
                                {data.map(item => (
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    <FormattedMessage id={item.id} defaultMessage={item.defaultMessage}/>
                                                </label>
                                                <input 
                                                    type="password"
                                                    onChange={e => handleSetPayload(e.target.value, item.state)}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                        </div>                                
                                    </div>
                                ))}
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-lg col-md-6"
                                    disabled={loading}
                                >
                                    Submit
                                </button>
                            </form>
                        </> 
                    )}
                </div>
            {/* )} */}
        </div>
    </div>
    )
}