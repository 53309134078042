import React, { useState, useEffect , useContext} from 'react';
import { FormattedMessage } from "react-intl";
import { useParams } from 'react-router-dom';
import { Students } from '../../services';
import { Loading } from '../../components';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MaintenanceContext } from '../../Maintenance';

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
  } 
from "@react-pdf/renderer";

import ReactPDF from '@react-pdf/renderer';
import '../../Preview.css';

const ref = React.createRef();
export default function PersonalityReport(props) {
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

    const styles = StyleSheet.create({
        page: {
          backgroundColor: "#ffffff",
          width:840,
          height:400
        },
        container: {
            margin:'auto',
            marginTop:100,
            marginBottom:0,
            paddingBottom:0,
            marginLeft:40,
            height:'auto',
            display: "block", 
            width: 'auto', 
        },
        container2: {
            margin:'auto',
            marginTop:10,
            marginBottom:0,
            paddingBottom:0,
            marginLeft:40,
            height:'auto',
            display: "block", 
            width: 'auto', 
        },
        table: { 
          margin:'auto',
          marginTop:10,
          marginBottom:0,
          paddingBottom:0,
          height:'auto',
          display: "block", 
          width: 'auto', 
          border: "1px solid #000", 
          borderWidth: 0.5, 
        }, 
        table2: { 
          margin:'auto',
          marginTop:5,
          display: "block", 
          width: 'auto', 
          border: "1px solid #000", 
          borderWidth: 0.5, 
        }, 
        thRow: { 
          flexDirection: "row",
          
        }, 
        thCol: { 
          width:'auto', 
          border: "1px solid #000", 
          borderRightWidth: 0.5, 
          borderBottomWidth: 0.5, 
          padding:0 
        }, 
        thText: { 
          fontSize: 4,
          fontWeight:800,
          color:'#000'
          
        },
        tdRow: { 
          flexDirection: "row",

        }, 
        tdCol: { 
          width:'auto', 
          borderRightWidth: 0.5, 
          borderBottomWidth: 0.5, 
          padding:0 
        }, 
        tdText: { 
          padding:0, 
          fontSize:4,
          color:'#000'
        },
        text: { 
          padding:0, 
          fontSize:4,
          color:'#000'
        },
        row: { 
            flexDirection: "row",
            display: "block", 
            width: 840, 
        }, 
        col: { 
            width:200, 
            padding:0 
            
          }, 
        textRight: { 
          padding:0, 
          fontSize:4,
          color:'#000',
          textAlign:'right'
        },
        rowRight: { 
            flexDirection: "row",
            display: "block", 
            width: 750, 
            textAlign:'right',
        }, 
        colRight: { 
            width:750, 
            padding:0 ,
            textAlign:'right',
            
          }, 
        thColNo: { 
            width:20,  
            borderRightWidth: 0.5,  
            padding:0 
        }, 
        thColNoSub: { 
            textAlign:'center',
            width:20,  
            borderRightWidth: 0.5,  
            borderBottomWidth: 0.5,  
            padding:0 
        }, 
        thColName: { 
            width:74,  
            borderRightWidth: 0.5,  
            padding:0 
        }, 
        thColNameSub: { 
            width:74,  
            borderRightWidth: 0.5,  
            borderBottomWidth: 0.5,  
            padding:0, 
            textTransform:'uppercase'
        }, 
        thColNoNull: { 
            width:20,  
            borderRightWidth: 0.5,  
            borderBottomWidth: 0.5, 
            padding:0 
          }, 
          thColNameNull: { 
            width:74,  
            borderRightWidth: 0.5,  
            borderBottomWidth: 0.5, 
            padding:0 
          }, 
          thColSubject: { 
            textAlign:'center',
            width:56,  
            borderRightWidth: 0.5,  
            borderBottomWidth: 0.5, 
            padding:0 
          }, 
          tdColSubSubject: { 
            textAlign:'center',
            width:14,  
            borderRightWidth: 0.5,  
            borderBottomWidth: 0.5, 
          }, 
          tdSub: { 
            padding:0, 
            fontSize:2,
            color:'black'
          },
          thColStudent: {
            width:94,   
            padding:0, 
            borderRightWidth: 0.5, 
          },
          tdColSubStudent: { 
            textAlign:'center',
            width:56,  
            borderRightWidth: 0.5,  
            borderBottomWidth: 0.5, 
          }, 
          tdColSubTeacher: { 
            width:56,  
            borderRightWidth: 0.5,  
            borderBottomWidth: 0.5, 
          }, 
          table3: { 
            margin:'auto',
            marginTop:100,
            display: "block", 
            width: 'auto',
            height:'auto', 
            border: "1px solid #000", 
            borderWidth: 1, 
          }, 
          tableRowCustom2: { 
            margin: "auto", 
            flexDirection: "row",
            
          }, 
         
          tableColNo: { 
            width: 50, 
            border: "1px solid #000", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderBottomWidth: 0, 
            padding:0 
          }, 
          tableColValue: { 
            width: 55, 
            border: "1px solid #000", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderBottomWidth: 0, 
            padding:0 
          }, 
          tableColName: { 
            width: 150, 
            border: "1px solid #000", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderBottomWidth: 0, 
            padding:0 
          }, 
          tableColTeacher: { 
            width: 200, 
            border: "1px solid #ccc", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderBottomWidth: 0, 
            padding:0 
          }, 
          tableColTeacherName: { 
            width: 165, 
            border: "1px solid #000", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderBottomWidth: 0, 
            padding:0 
          }, 
          tableRow2: { 
              margin: "auto", 
              flexDirection: "row",
              
            }, 
            tableCellNo: { 
                textAlign:'center',
                marginTop: 0, 
                padding:1, 
                fontSize: 10,
                fontWeight:800,
                color:'black'
                
              },
            tableCellName: { 
                marginTop: 0, 
                padding:1, 
                fontSize: 10,
                fontWeight:800,
                color:'black',
                textTransform:'uppercase'
                
              },
            tableCellValue1: { 
                textAlign:'center',
                marginTop: 0, 
                padding:1, 
                fontSize: 8,
                fontWeight:800,
                color:'black'
                
              },
            tableCellValue2: { 
                textAlign:'center',
                marginTop: 0, 
                padding:1, 
                fontSize: 10,
                fontWeight:800,
                color:'black'
                
              },
            tableCellTeacher: { 
                marginTop: 0, 
                padding:1, 
                fontSize: 10,
                fontWeight:800,
                color:'black'
                
              },
            tableCellTeacherName: { 
                marginTop: 0, 
                padding:1, 
                fontSize: 10,
                fontWeight:800,
                color:'black'
                
              },
      });

    const action = () => {
        //  toPdf(); 
        ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`)  
    }

    const MyDocument = () => (
      <Document>
          <Page size="A4" orientation="landscape" tyle={styles.page}>
            <View style={styles.container}> 
                <View style={styles.row}> 
                    <View style={styles.col}> 
                        <Text style={styles.text}>{process.env.REACT_APP_NAME}</Text> 
                    </View> 
                </View>
                <View style={styles.row}> 
                    <View style={styles.col}> 
                        <Text style={styles.text}>JL.Tabing Blok B-16 No. 3. Kemayoran, Jakarta</Text> 
                    </View> 
                </View>
                <View style={styles.rowRight}> 
                    <View style={styles.colRight}> 
                        <Text style={styles.textRight}>Total Mark</Text> 
                    </View> 
                </View>
                <View style={styles.row}> 
                    <View style={styles.col}> 
                        <Text style={styles.text}>Class: IX-D</Text> 
                    </View> 
                </View>
                <View style={styles.row}> 
                    <View style={styles.col}> 
                        <Text style={styles.text}>Class Teacher: MS Soni </Text> 
                    </View> 
                </View>
            </View>
              <View style={styles.table}> 
                <View style={styles.thRow}> 
                    <View style={styles.thColNo}> 
                        <Text style={styles.thText}>Roll No</Text> 
                    </View> 
                    <View style={styles.thColName}> 
                        <Text style={styles.thText}>Name</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Pancasila Moral</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Pancasila Moral</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Pancasila Moral</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Pancasila Moral</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Pancasila Moral</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Pancasila Moral</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Pancasila Moral</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Pancasila Moral</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Pancasila Moral</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Pancasila Moral</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Pancasila Moral</Text> 
                    </View> 
                   
                </View>
                <View style={styles.thRow}> 
                    <View style={styles.thColNoNull}> 
                        <Text style={styles.thText}></Text> 
                    </View> 
                    <View style={styles.thColNameNull}> 
                        <Text style={styles.thText}></Text> 
                    </View>
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 

                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 

                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 

                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 

                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 

                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 

                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 

                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 

                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 

                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 

                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 

                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdSub}>40 (MT1)</Text> 
                        </View> 

                        
                   
                </View>
                <View style={styles.thRow}> 
                    <View style={styles.thColNoSub}> 
                        <Text style={styles.thText}>1</Text> 
                    </View> 
                    <View style={styles.thColNameSub}> 
                        <Text style={styles.thText}>AHMAD HAZMI PRATAMA</Text> 
                    </View>
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20.4</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>40</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20.4</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>40</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20.4</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>40</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20.4</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>40</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20.4</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>40</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20.4</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>40</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20.4</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>40</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20.4</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>40</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20.4</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>40</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20.4</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>40</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20.4</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>40</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20.4</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>40</Text> 
                        </View> 
                        <View style={styles.tdColSubSubject}> 
                            <Text style={styles.tdText}>20</Text> 
                        </View> 
                </View>
            </View>
            <View style={styles.container2}> 
                <View style={styles.row}> 
                    <View style={styles.col}> 
                        <Text style={styles.text}>Subject Wise Pass Percentage Summary Report</Text> 
                    </View> 
                </View>
            </View>
            <View style={styles.table2}> 
                <View style={styles.thRow}> 
                    <View style={styles.thColStudent}> 
                        <Text style={styles.thText}></Text> 
                    </View>
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 
                    <View style={styles.thColSubject}> 
                        <Text style={styles.thText}>Agama</Text> 
                    </View> 

                </View>
                <View style={styles.thRow}> 
                    <View style={styles.thColStudent}> 
                        <Text style={styles.thText}>Total No. of Students Appeared</Text> 
                    </View>
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                </View>
                <View style={styles.thRow}> 
                    <View style={styles.thColStudent}> 
                        <Text style={styles.thText}>Total No. of Students Passed</Text> 
                    </View>
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                </View>
                <View style={styles.thRow}> 
                    <View style={styles.thColStudent}> 
                        <Text style={styles.thText}>Total No. of Students Failed</Text> 
                    </View>
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                </View>
                <View style={styles.thRow}> 
                    <View style={styles.thColStudent}> 
                        <Text style={styles.thText}>Pass Percentage</Text> 
                    </View>
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                        <View style={styles.tdColSubStudent}> 
                            <Text style={styles.tdText}>30</Text> 
                        </View> 
                     
                </View>
                <View style={styles.thRow}> 
                    <View style={styles.thColStudent}> 
                        <Text style={styles.thText}>Name of Subject Teacher</Text> 
                    </View>
                     
                        <View style={styles.tdColSubTeacher}> 
                            <Text style={styles.tdText}>Agama Teacher</Text> 
                        </View> 
                        <View style={styles.tdColSubTeacher}> 
                            <Text style={styles.tdText}>Agama Teacher</Text> 
                        </View> 
                        <View style={styles.tdColSubTeacher}> 
                            <Text style={styles.tdText}>Agama Teacher</Text> 
                        </View> 
                        <View style={styles.tdColSubTeacher}> 
                            <Text style={styles.tdText}>Agama Teacher</Text> 
                        </View> 
                        <View style={styles.tdColSubTeacher}> 
                            <Text style={styles.tdText}>Agama Teacher</Text> 
                        </View> 
                        <View style={styles.tdColSubTeacher}> 
                            <Text style={styles.tdText}>Agama Teacher</Text> 
                        </View> 
                        <View style={styles.tdColSubTeacher}> 
                            <Text style={styles.tdText}>Agama Teacher</Text> 
                        </View> 
                        <View style={styles.tdColSubTeacher}> 
                            <Text style={styles.tdText}>Agama Teacher</Text> 
                        </View> 
                        <View style={styles.tdColSubTeacher}> 
                            <Text style={styles.tdText}>Agama Teacher</Text> 
                        </View> 
                        <View style={styles.tdColSubTeacher}> 
                            <Text style={styles.tdText}>Agama Teacher</Text> 
                        </View> 
                        <View style={styles.tdColSubTeacher}> 
                            <Text style={styles.tdText}>Agama Teacher</Text> 
                        </View> 
                        <View style={styles.tdColSubTeacher}> 
                            <Text style={styles.tdText}>Agama Teacher</Text> 
                        </View> 
                </View>
            </View> 
          </Page>
          <Page size="A4" orientation="landscape" tyle={styles.page}>
          
          <View style={styles.table3}> 
              <View style={styles.tableRowCustom2}> 
                <View style={styles.tableColNo}> 
                  <Text style={styles.tableCellNo}>ROLL NO</Text> 
                </View> 
                <View style={styles.tableColName}> 
                  <Text style={styles.tableCellName}>NAME</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue1}>40(MT1)</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue1}>40(MT1)</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue1}>40(MT1)</Text> 
                </View> 
              </View>
              <View style={styles.tableRow2}> 
                <View style={styles.tableColNo}> 
                  <Text style={styles.tableCellNo}>1</Text> 
                </View> 
                <View style={styles.tableColName}> 
                  <Text style={styles.tableCellName}>Ahmad Hazmi Pratanma</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue2}>30</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue2}>16</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue2}>32</Text> 
                </View> 
              </View> 
              <View style={styles.tableRow2}> 
                <View style={styles.tableColNo}> 
                  <Text style={styles.tableCellNo}>1</Text> 
                </View> 
                <View style={styles.tableColName}> 
                  <Text style={styles.tableCellName}>Ahmad Hazmi Pratanma</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue2}>30</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue2}>16</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue2}>32</Text> 
                </View> 
              </View> 
              <View style={styles.tableRow2}> 
                <View style={styles.tableColNo}> 
                  <Text style={styles.tableCellNo}>1</Text> 
                </View> 
                <View style={styles.tableColName}> 
                  <Text style={styles.tableCellName}>Ahmad Hazmi Pratanma</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue2}>30</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue2}>16</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue2}>32</Text> 
                </View> 
              </View> 
              <View style={styles.tableRow2}> 
                <View style={styles.tableColNo}> 
                  <Text style={styles.tableCellNo}>1</Text> 
                </View> 
                <View style={styles.tableColName}> 
                  <Text style={styles.tableCellName}>Ahmad Hazmi Pratanma</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue2}>30</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue2}>16</Text> 
                </View> 
                <View style={styles.tableColValue}> 
                  <Text style={styles.tableCellValue2}>32</Text> 
                </View> 
              </View> 
              <View style={styles.tableRow2}> 
                <View style={styles.tableColTeacher}> 
                  <Text style={styles.tableCellTeacher}>Teacher</Text> 
                </View> 
                <View style={styles.tableColTeacherName}> 
                  <Text style={styles.tableCellTeacherName}>Teacher Name</Text> 
                </View> 
              </View> 
            </View>
        </Page>
        </Document>
    )
    return (
      <div className="main">
            <div className="main-content">
                <div className="container-fluid" ref={ref}>
                {ismaintenance ? (
    <div className="text-center">
        <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
        <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
    </div>
)  : (
                <>
                      <div className="row">
                            <div className="col-md-2">
                                <div className="row">                                                                                    
                                    {/* <PDFDownloadLink
                                        document={<MyDocument/>}
                                        fileName="Consolidated.pdf"
                                        style={{
                                            textDecoration: "none",
                                            padding: "10px",
                                            color: "#4a4a4a",
                                            backgroundColor: "#f2f2f2",
                                            border: "1px solid #4a4a4a"
                                        }}
                                        >
                                        {({ blob, url, loading, error }) =>
                                            loading ? "Loading document..." : "Download Pdf"
                                        }
                                    </PDFDownloadLink> */}
                                    <MyDocument/>
                                </div>
                            </div>
                        </div>
                </> 
                )}
                      </div>
                    </div>
              </div>        
    )
}

