require('dotenv').config();
export default class Religions {
    
    constructor() {}

    list = async (params = {}) => {        
        params.page = parseInt(params.page) || 1;
        params.limit = params.limit || 10;
        params.search = params.search || '';
        params.is_curricular = params.is_curricular || false;

        const { page, limit, search, is_curricular } = params;
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/religion/list?page=${page}&limit=${limit}&name_search=${search}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    
    detail = async (id) => {        
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/religion/detail/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    create = async (payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/religion/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.code === 201) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
    update = async (payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/religion/${payload.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    delete = async (id) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/religion/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                console.error('catch: ', err);
                reject(err);
            });
        });
    }

    xxx = async (payload) => {
        const token = window.localStorage.getItem('token');
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/religion/import`, {
                method: 'POST',   
                headers: {
                    'Authorization': token
                },         
                body: payload
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.code === 201) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }
}