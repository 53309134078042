import React, {useState} from 'react';
import { I18nPropvider, LOCALES } from './i18nProvider';

import Login from './auth/Login';
import {BrowserRouter} from 'react-router-dom';
import {Route, Switch} from "react-router";
import Sidebar from './templates/Sidebar';
import Navbar from './templates/Navbar';


import DashboardAdmin from './pages/admin/Dashboard';
import Users from './pages/admin/Users';
import Teacher from './pages/admin/Teacher';
import Subject from './pages/admin/Subject';
import Religion from './pages/admin/Religion';
import Classes from './pages/admin/Classes';
import Session from './pages/admin/Session';
import Student from './pages/admin/Student';
import Configuration from './pages/admin/Configuration';
import Personality from './pages/admin/Personality';

import DashboardTeacher from './pages/teacher/Dashboard';
import Scoring from './pages/teacher/Scoring';

import Yearly from './pages/report/Yearly';
import PersonalityReport from './pages/report/PersonalityReport';
import Term from './pages/report/Term';
import Consolidated from './pages/report/ConsolidatedReport';

import DashboardHomeroom from './pages/homeroom/Dashboard';
import PersonalityMark from './pages/homeroom/PersonalityMark';
import StudentReport from './pages/homeroom/StudentReport';

import StudentDashboard from './pages/student/Dashboard';
import MyReport from './pages/student/MyReport';
import MidTerm from './pages/report/MidTerm';

import { MaintenanceContext } from './Maintenance';
import ChangePassword from "./pages/homeroom/ChangePassword";

const App = () => {
  const lsLocale = window.localStorage.getItem("locale");
  const [locale, setLocale] = useState(lsLocale || LOCALES.ENGLISH);
  const [ismaintenance, setIsMaintenance] = useState(false);
  return (
    <I18nPropvider locale={locale}>
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Login} />
            <MaintenanceContext.Provider
              value={{ ismaintenance, setIsMaintenance }}
            >
              <Navbar />
              <Sidebar />
              <Route
                exact
                path="/change-password"
                component={ChangePassword}
              ></Route>
              {/* Admin */}
              <Route
                exact
                path="/dashboard-admin"
                component={DashboardAdmin}
              ></Route>
              <Route exact path="/users" component={Users}></Route>
              <Route exact path="/teacher" component={Teacher}></Route>
              <Route exact path="/student" component={Student}></Route>
              <Route exact path="/subject" component={Subject}></Route>
              <Route exact path="/religion" component={Religion}></Route>
              <Route exact path="/classes" component={Classes}></Route>
              <Route exact path="/session" component={Session}></Route>
              <Route
                exact
                path="/configuration"
                component={Configuration}
              ></Route>
              <Route exact path="/personality" component={Personality}></Route>

              {/* Teacher */}
              <Route
                exact
                path="/dashboard-teacher"
                component={DashboardTeacher}
              ></Route>
              <Route exact path="/scoring" component={Scoring}></Route>

              {/* Homeroom */}
              <Route
                exact
                path="/dashboard-homeroom"
                component={DashboardHomeroom}
              ></Route>
              <Route
                exact
                path="/personality-mark"
                component={PersonalityMark}
              ></Route>
              <Route
                exact
                path="/student-report"
                component={StudentReport}
              ></Route>
              <Route exact path="/student-report/subject/:studentId/:term">
                <Yearly />
              </Route>
              <Route
                exact
                path="/student-report/personality/:studentId/term/:term"
              >
                <PersonalityReport />
              </Route>
              <Route exact path="/yearly" component={Yearly} />
              <Route exact path="/term" component={Term} />
              <Route exact path="/consolidated" component={Consolidated} />
              <Route
                exact
                path="/student-report/subject/:studentId/mid-term/:term"
                render={(props) => <MidTerm {...props} />}
                component={MidTerm}
              />
              <Route
                exact
                path="/student-report/subject/:studentId/term/:term"
                component={Term}
              />
              <Route
                exact
                path="/dashboard-student"
                component={StudentDashboard}
              ></Route>
              <Route exact path="/my-report" component={MyReport}></Route>
            </MaintenanceContext.Provider>
          </Switch>
        </BrowserRouter>
      </div>
    </I18nPropvider>
  );
};

export default App;
