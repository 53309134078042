import React, { Component, useContext } from 'react'
import { useParams } from 'react-router-dom';
import '../../Preview.css';
import YearlyPDF from './PDF/YearlyPDF';
import { MaintenanceContext } from '../../Maintenance';


export default function Yearly(props) {
    const { studentId, term } = useParams();
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);


    return (
        <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                {ismaintenance ? (
    <div className="text-center">
        <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
        <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
    </div>
)  : (
                <>
                <YearlyPDF studentId={studentId} term={term}/>
                </> 
                )}
                </div>
            </div>
        </div>
    )
}
