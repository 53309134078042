import React, { useState, useEffect, useRef, useContext  } from 'react';
import { Hint } from 'react-autocomplete-hint';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Loading } from '../../components';
import { Classrooms, Teachers, Subjects, Students } from '../../services';
import { MDBDataTable } from 'mdbreact';
import { FormattedMessage } from "react-intl";
import RequiredSelect from '../../components/RequiredSelect';
import BaseSelect from 'react-select';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2'
import { MaintenanceContext } from '../../Maintenance';

const classrooms = new Classrooms();
const teachers = new Teachers();
const subjects = new Subjects();
const students = new Students();

export default function Classroom(props) {
    let text;
    let hintData = ['MTK','IPS','PKN','Bahasa' ];

    useEffect(() => {        
        (async () => getTeacherList())();
        (async () => getClassroomList())();
    }, []);

    const selectInputRef = useRef();
    const user = JSON.parse(window.localStorage.getItem('user-data'));
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

    const [to, setTo] = useState(0);
    const [loadingClassroom, setLoadingClassroom] = useState(false);
    const [loadingSubject, setLoadingSubject] = useState(false);
    const [loadingTeacher, setLoadingTeacher] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);

    const [payload, setPayload] = useState({
        id: '',
        teacher_id: '',

        name: '',
        subject_list: [],
        created_by: user.username,
        updated_by: user.username,
        file: null
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 5,
        search: '',
        total: 0,
        totalPage: 1
    });

    const [subjectList, setSubjectList] = useState([]);
    const [classroomSubjectList, setClassroomSubjectList] = useState([]);
    const [classroomList, setClassroomList] = useState([]);
    const [teacherList, setTeacherList] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const [mode, setMode] = useState('Add');
    const [showMode, setShowMode] = useState('list-subject');
    

    const _handleSearch = (v) => {
        if (to) {
          clearTimeout(to);
        }
        setTo(
          setTimeout(() => {
            pagination.search = v;
            setPagination(pagination);
            getClassroomList();
          }, 500)
        );
      };

    const getClassroomList = () => {
        setLoadingClassroom(true);
        classrooms.list(pagination)
        .then(res => {
            console.log(res);
            pagination.limit = res.limit;
            pagination.total = res.total;
            pagination.totalPage = res.total % res.limit === 0 ? (res.total / res.limit) : Math.floor(res.total / res.limit) + 1
                     
            res.result.map((item, i) => {     
                let nums = parseInt(pagination.total - pagination.limit);
                let numRows = (pagination.page - 1) * (pagination.total - nums) + i;

                res.result[i].no = numRows + 1;
                res.result[i].teacher_name = item.teacher.name;
                res.result[i].option = 
                    <>
                    
                        <button 
                            className="btn btn-warning btn-sm mr-1" 
                            data-toggle="modal" 
                            data-target="#addModal"
                            onClick={() => getClassroomInfo(item, 'Edit')}> 
                                <i className="lnr lnr-pencil"></i> 
                                 <FormattedMessage id="component.edit.button"/>
                        </button>
                        <button 
                            className="btn btn-danger btn-sm mr-1" 
                            data-toggle="modal" 
                            data-target="#deleteModal"
                            onClick={() => getClassroomInfo(item, 'Edit')}> 
                                <i className="lnr lnr-trash"></i> 
                                 <FormattedMessage id="component.delete.button"/>
                        </button>
                        <button 
                            className="btn btn-primary btn-sm mr-1" 
                            data-toggle="modal" 
                            data-target="#listSubject"
                            onClick={() => getClassroomInfo(item)}> 
                                <i className="lnr lnr-list"></i> 
                                 <FormattedMessage id="component.list-subject.button"/>
                        </button>
                        <button 
                            className="btn btn-primary btn-sm" 
                            data-toggle="modal" 
                            data-target="#listSubject"
                            onClick={() => getStudentByClassroom(item)}> 
                                <i className="lnr lnr-list"></i> 
                                 <FormattedMessage id="component.list-student.button"/>
                        </button>
                    </>
                
            });   

            setPagination(pagination);
            setClassroomList(res.result);
            
            setLoadingClassroom(false)
        })
        .catch(err => {
            console.error(err);

            if (err.code === 404) {
                setClassroomList([]);
                setPagination({
                    page: 0,
                    limit: 0,
                    search: '',
                    total: 0,
                    totalPage: 1
                });
            }
            if (err.status === 503) {
                setIsMaintenance(true)
            }
            setLoadingClassroom(false)
        });
    };

    const getDetailClassroom = (id) => {
        setLoadingSubject(true);

        classrooms.detail(id)
        .then(res => {
            console.log(res);
            payload.subject_list = res.result.subject_list || [];
            setPayload(payload);
            setClassroomSubjectList(payload.subject_list)
            getSubjectList();            
        })
        .catch(err => {
            console.error(err);

            setLoadingSubject(false);
        })
    }

    const getStudentByClassroom = (classroomInfo) => {
        setStudentList([]);
        payload.id = classroomInfo.id;
        payload.name = classroomInfo.name;
        payload.teacher_id = classroomInfo.teacher && classroomInfo.teacher.id;
        payload.updated_by = payload.created_by;
        payload.created_by = payload.created_by;

        setShowMode('list-student');
        setPayload(payload);
        students.getStudentByClassroom(classroomInfo.id)
        .then(res => {
            setStudentList(res.result);
        })
        .catch(err => {
            console.log(err);
        })
    }

    const getSubjectList = () => {
        setLoadingSubject(true);

        subjects.list({ limit: 100 })
        .then(res => {
            console.log(res);

            res.result.map( (item, i) => {

                res.result[i].no = i + 1;              
                res.result[i].teacher_name = item.teacher.name;                
            })

            setSubjectList(res.result);

            setLoadingSubject(false);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setSubjectList([]);
            }

            setLoadingSubject(false);
        });
    };
    const getTeacherList = () => {
        setLoadingTeacher(true);
        teachers.list({ limit: 100 })
        .then(res => {
            console.log(res.result);    
            res.result.map( (item, i) => {
                res.result[i].value = item.id;
                res.result[i].label = item.name;
            });                     
            setTeacherList(res.result);

            setLoadingTeacher(false);
        })
        .catch(err => {
            console.error(err);

            if (err.code === 404) {
                setTeacherList([]);
            }

            setLoadingTeacher(false);
        });
    };


    const createClassroom = () => {
        setLoadingClassroom(true);

        classrooms.create(payload)
        .then(res => {
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                reset();
                getClassroomList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            
            setLoadingClassroom(false)
        })
        .finally(reset);
    }
    
    const updateClassroom = () => {
        setLoadingClassroom(true);

        classrooms.update(payload)
        .then(res => {
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                reset();
                getClassroomList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            
            setLoadingClassroom(false)
        });
    }
    
    const deleteClassroom = () => {
        setLoadingClassroom(true);

        classrooms.delete(payload.id)
        .then(res => {
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                setPayload({
                    ...payload,
                    name: ''
                });

                getClassroomList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            
            setLoadingClassroom(false)
        });
    }

    const addClassroomSubject = (classroomId, params) => {
        setLoadingSubject(true);
        classrooms.addClassroomSubject(classroomId, params)
        .then(res => {
            if (res.status.code === 200) {
                document.getElementById('addClassLabel').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                getDetailClassroom(payload.id);
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoadingSubject(false);
        });
    }
    
    const removeClassroomSubject = (classroomId, params) => {
        setLoadingSubject(true);

        classrooms.removeClassroomSubject(classroomId, params)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {         
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
                getDetailClassroom(payload.id);
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            
            setLoadingSubject(false);
        });
    }

    const getClassroomInfo = (classroomInfo, mode = '') => {
        payload.id = classroomInfo.id;
        payload.name = classroomInfo.name;
        payload.teacher_id = classroomInfo.teacher && classroomInfo.teacher.id;
        payload.updated_by = payload.created_by;
        payload.created_by = payload.created_by;

        setShowMode('list-subject');
        setPayload(payload);
        if (mode) {
            return setMode('Edit');
        } 

        getSubjectList();
        getDetailClassroom(classroomInfo.id);
    }

    const setSubject = (subject, mode = 'add') => {   
        
        const { id } = subject;
        const params = {
            subject_id: id,
            created_by: payload.created_by
        };

        if (mode === 'add') {
            addClassroomSubject(payload.id, params);
        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              })
              .then((result) => {
                if (result.isConfirmed) {
                    params.subject_id = subject.subject_id;
                    removeClassroomSubject(payload.id, params)    
                }
              })
        }
    }

    const handlePayload = (e, state) => {
        if (!e) {
            return false;
        }
        let v = '';
        if (state === 'teacher_id') {
            v = e.id;
        } else if (state === 'file') {
            v = e.target.files[0];
        } else if (state === 'is_curricular') {
            v = !payload.is_curricular;
        } else {
            v = e.target.value;
        }
        setPayload({
            ...payload,
            [state]: v
        });
    }

    const reset = () => {
        selectInputRef.current.selectRef.select.clearValue();
        setPayload({
            id: '',
            teacher_id: '',
            name: '',
            is_curricular: false,
            created_by: user.username,
            updated_by: user.username
        });

        setStudentList([]);

    }

    const renderDisableAddSubject = (subject) => {
        if (payload.subject_list !== null) {
            const { subject_list } = payload;
            for (let i in subject_list) {

                if (subject_list[i].subject_id === subject.id) {
                    return true;
                }
            }
        }
        return false;
    }

    // handle pagination
    const _handleChangePage = e => {
        const { selected } = e;
        pagination.page = selected + 1;
        
        setPagination(pagination);
        getClassroomList();

    }

    const renderModalTitle = mode => {
        switch(mode) {
            case 'Add':
                return <FormattedMessage id="component.add.button"/>;
            case 'Edit':
                return <FormattedMessage id="component.edit.button"/>;
            default:
                return 'Import'
        }
    }

    const renderModalSubmit = mode => {
        switch(mode) {
            case 'Add':
                createClassroom()
                break;
            case 'Edit':
                updateClassroom()
                break;
            default:
                importFile()
                break;
        }
    }

    const importFile = () => {
        setLoadingClassroom(true);

        const formData = new FormData();

        formData.append('file', payload.file);
        formData.append('teacher_id', payload.teacher_id);
        formData.append('created_by', payload.created_by);

        classrooms.xxx(formData)
        .then(res => {
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                reset();
                getClassroomList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            
            setLoadingClassroom(false)
        });
    }
    
    const handleSortSubject = (type, subjectId) => {
        console.log(type);
        setOrderLoading(true);
        classrooms.moveSubject(payload.id, subjectId, type)
        .then(res => {
            
            setOrderLoading(false);
            setClassroomSubjectList(res.result.subject_list)
        })
        .catch(err => {
            setOrderLoading(false);
            console.error(err);
        })
    }
    
    const data = {
        columns: [
          {
            label: <FormattedMessage id='form.number' defaultMessage='No'/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: <FormattedMessage id="form.classroom.name"/>,
            field: 'name',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id="form.teacher.name"/>,
            field: `teacher_name`,
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id="form.classroom.total-student"/>,
            field: 'total_student',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id="form.options"/>,
            field: 'option',
            sort: 'asc',
            width: 100
          },
        ],
        rows: classroomList || []
    };
    const { page, totalPage } = pagination;

    return (
        <div className="main">
            <ToastContainer
                autoClose={5000}
                hideProgressBar
                pauseOnHover={false} />
            <div className="main-content">
                {user.role === 'Admin' && (

                    <div className="container-fluid">
                    {ismaintenance ? (
    <div className="text-center">
        <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
        <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
    </div>
)  : (
        <>
            <h3 className="page-title">
                <FormattedMessage id="menu.classroom"/>
            </h3>
            <div className="row">
                <div className="col-md-12">
                    <div className="panel">
                        <div className="panel-header mt-2 mr-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <form >
                                        <div className="form-group">
                                            <label>
                                                <FormattedMessage id="form.classroom.name"/>
                                            </label>
                                            <input type="text" className="form-control" onChange={e => _handleSearch(e.target.value)} />
                                        </div>
                                    </form>
                            </div>
                                <div className="col-md-7 mt-2">
                                    <button 
                                        type="submit" 
                                        className="btn btn-primary"                                                
                                        data-toggle="modal" 
                                        data-target="#addModal"
                                        onClick={() => {
                                            setMode('Add');
                                            reset();
                                        }}
                                        style={{ float: 'right' }}>
                                            <FormattedMessage id="component.add.button"/>
                                    </button>
                                    <button 
                                        className="btn btn-success mr-3" 
                                        data-toggle="modal" data-target="#addModal" 
                                        style={{float : 'right'}}
                                        onClick={() => setMode('Import')}> Import</button>
                                </div>
                        </div>
                    </div>
                    </div>
                    <div className="panel-body">           
                        <Loading visible={loadingClassroom} />
                        <MDBDataTable
                            searching={false}
                            className="table mt-5"
                            data={data}
                            sortable
                            noBottomColumns    
                            entriesOptions={[5, 10, 20, 50]}
                            displayEntries={true}
                            paging={false}
                            noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>}                                                                                                                 
                        />
                        <ReactPaginate
                            // className="d-flex justify-content-center"
                            breakLabel={'...'}
                            pageCount={totalPage}
                            marginPagesDisplayed={1}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            initialPage={page}
                            onPageChange={_handleChangePage}
                        />
                    </div>
                </div>                        
            </div>
        </div>
    </>
    )}
    </div>
                )}
    </div>
        <Modal
            title={renderModalTitle(mode)}
            label={mode}
            form={
                mode === 'Import'
                ? 
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <a href={`${process.env.REACT_APP_URL_SERVICE}/static/classrooms.csv`} download className="btn btn-success" style={{ float: 'left' }}>
                                Download template
                            </a>
                        </div>                                
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <RequiredSelect
                                SelectComponent={BaseSelect}
                                required
                                className="mt-2"
                                value={
                                    teacherList?.length > 0 
                                    ? teacherList.find(item => item.id === payload.teacher_id)
                                    : null
                                }                                    
                                options={teacherList}                                    
                                onChange={(e) => handlePayload(e, 'teacher_id')}
                                ref={selectInputRef}
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="fom-label" className="required">
                                <FormattedMessage id="component.text.choose-file"/>
                            </label>
                            <input 
                                required
                                type="file" 
                                className="form-control" 
                                accept=".csv"
                                onChange={e => handlePayload(e, 'file')}/>
                        </div>                        
                    </div>
                </>
                :
                (
                    <>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="required">
                                    <FormattedMessage id="form.classroom.name"/>
                                </label>
                                <input 
                                    required
                                    type="text" 
                                    value={payload.name}
                                    className="form-control" 
                                    onChange={(e) => handlePayload(e, 'name')} />
                            </div>   
                        </div>

                        <div className="col-md-12">
                            <div className="col-md-12">
                                <label style={{ marginLeft: '-15px'}} className="required">
                                    <FormattedMessage id="form.teacher.name"/>
                                </label>
                            </div>
                            <div className="form-group">
                                <RequiredSelect
                                    SelectComponent={BaseSelect}
                                    required
                                    value={
                                        teacherList?.length > 0 
                                        ? teacherList.find(item => item.id === payload.teacher_id)
                                        : null
                                    }                                    
                                    placeholder={"Choose the teacher"}
                                    options={teacherList}                                    
                                    onChange={(e) => handlePayload(e, 'teacher_id')}
                                    ref={selectInputRef}
                                />
                            </div>                      
                        </div>
                    </>
                )
            }
            onSubmit={(e) => {e.preventDefault();renderModalSubmit(mode);}}
            onClose={() => reset()}
            onDelete={() => deleteClassroom()}
            disabled={loadingClassroom}
        />
        <div className="modal fade" id="listSubject" tabIndex={-1} aria-labelledby="listSubjectLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => reset()}>
                        <span aria-hidden="true">×</span>
                        </button>
                        {showMode === 'list-subject' && (
                            <h5 className="modal-title" id="listSubjectLabel">
                                <FormattedMessage id="form.subject.list"/> Class {payload.name}
                            </h5>
                        )}
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            {showMode === 'list-subject' 
                            ? (
                                <div className="col-md-12">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-8">
                                            <div className="form-group">
                                                    <p style={{textAlign : 'left', fontWeight: '700', fontSize : '15'}}>
                                                        <FormattedMessage id='form.subject.name'/>
                                                    </p>
                                            <div className="form-group">
                                                <Hint options={hintData} allowTabFill>
                                                <input className='input-with-hint form-control'
                                                    value={text} style={{ textTransform :'uppercase'}}/>
                                                </Hint>
                                            </div>
                                            </div>
                                            </div>
                                            <div className="col-md-1 mt-3">
                                                <a 
                                                    className="btn btn-success" 
                                                    data-dismiss="modal" 
                                                    data-toggle="modal" 
                                                    href="#addClass"
                                                    onClick={() => getClassroomInfo(payload, 'subject_list')}>
                                                        <FormattedMessage id="component.add.button"/>
                                                </a>
                                            </div>
                                        </div>                            
                                    </form>
                                </div>
                            )
                            : (
                                <div className="col-md-12">
                                    <p className=""> <FormattedMessage id="component.text.classroom.student.list"/> {payload.name}: </p>
                                </div>
                            )
                            }
                            <div className="row pl-1 pr-1">
                                <div className="col-md-12" >
                                    <div className="table-responsive" style={{height: '400px', maxHeight: '400px', overflow:'scroll'}}>
                                        <table className="table table-bordered mt-3" >
                                            {showMode === 'list-subject'
                                            ? (

                                                <thead>
                                                    <tr>
                                                        <th className="text-center">
                                                            <FormattedMessage id="form.subject.sort" defaultMessage="Sort"/>
                                                        </th>
                                                        <th className="text-center">No</th>
                                                        <th className="text-center">
                                                            <FormattedMessage id="form.subject.name"/>
                                                        </th>
                                                        <th className="text-center">
                                                            <FormattedMessage id="form.teacher.name"/>
                                                        </th>
                                                        <th className="text-center">
                                                            <FormattedMessage id="form.options"/>
                                                        </th>
                                                    </tr>
                                                </thead>    
                                            )
                                            : (
                                                <thead>
                                                    <tr>
                                                        {showMode === 'list-subject' && <th className="text-center"></th>}
                                                        <th className="text-center">No</th>
                                                        <th className="text-center">
                                                            <FormattedMessage id="form.student.name"/>
                                                        </th>
                                                    </tr>
                                                </thead>
                                            )
                                            }
                                            <tbody>
                                                    {showMode === 'list-subject' 
                                                    ?                                                 
                                                        !loadingSubject && classroomSubjectList && classroomSubjectList.length > 0 && classroomSubjectList.map ((subject, i) => (
                                                            <tr>
                                                                <td className="text-center">
                                                                    <button className="btn btn-sm" style={{ backgroundColor: 'transparent' }} disabled={orderLoading} onClick={() => handleSortSubject('up', subject.subject_id)}>
                                                                        <i className="fa fa-arrow-up"></i>
                                                                    </button><br/>
                                                                    <button className="btn btn-sm" style={{ backgroundColor: 'transparent' }} disabled={orderLoading} onClick={() => handleSortSubject('down', subject.subject_id)}>
                                                                        <i className="fa fa-arrow-down"></i>
                                                                    </button>
                                                                </td>
                                                                <td className="text-center">{i + 1}</td>
                                                                <td className="text-center">{subject.name}</td>
                                                                <td className="text-center">{subject.teacher_name}</td>
                                                                <td className="text-center">
                                                                    <button 
                                                                        className="btn btn-danger btn-sm"
                                                                        disabled={loadingSubject}
                                                                        onClick={() => setSubject(subject, 'delete')}
                                                                    > 
                                                                            <i className="lnr lnr-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    
                                                    : studentList.length > 0 && studentList.map((student, i) => (
                                                        <tr> 
                                                            <td className="text-center">{i + 1}</td>
                                                            <td className="text-center">{student.name}</td>
                                                        </tr>
                                                    ))
                                                    }
                                                    
                                            </tbody>
                                    
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Modal */}
        <div className="modal fade" id="addClass" tabIndex={-1} aria-labelledby="addClassLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" data-toggle="modal" href="#listSubject">
                            <span aria-hidden="true">×</span>
                        </button>
                        xxx {showMode}
                        {showMode === 'list-subject' && (
                            <div>
                                <h5 className="modal-title" id="addClassLabel">
                                    <FormattedMessage id="form.subject.setting"/>    
                                </h5>
                            </div>
                        )}
                    </div>
                    <div className="modal-body">
                    <button className="btn btn-secondary btn-sm mb-2"  data-dismiss="modal" data-toggle="modal" href="#listSubject"> <i className="lnr lnr-chevron-left"></i>                                        
                        <FormattedMessage id="component.back.button"/>     
                    </button>
                    <div className="table-responsive" style={{height:'400px', maxHeight:'400px', overflow:'scroll'}}>
                        <table className="table table-bordered mt-3">
                            <thead>
                                <tr>
                                <th className="text-center">No</th>
                                <th className="text-center">
                                    <FormattedMessage id="form.subject.name"/>
                                </th>
                                <th className="text-center">
                                    <FormattedMessage id="form.teacher.name"/>
                                </th>
                                <th className="text-center">
                                    <FormattedMessage id="form.options"/>
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadingSubject && (
                                    <tr><td colspan="4"><Loading visible={loadingSubject}/></td></tr>                                                
                                )}
                                {!loadingSubject && subjectList && subjectList.length > 0 && subjectList.map ((subject, i) => (
                                    <tr>
                                        <td className="text-center">{i + 1}</td>
                                        <td className="text-center">{subject.name}</td>
                                        <td className="text-center">{subject.teacher_name}</td>
                                        <td className="text-center">
                                            <button 
                                                className="btn btn-success btn-sm mr-1"
                                                onClick={() => setSubject(subject, 'add')}
                                                disabled={loadingSubject || renderDisableAddSubject(subject)}> 
                                                    <i className="lnr lnr-plus-circle"></i>
                                                    <FormattedMessage id="component.add.button"/>
                                            </button>  
                                        </td>
                                    </tr>
                                ))}
                            
                            </tbody>
                        </table>

                    </div>
                    </div>
                </div>
            </div>
        </div>
        

    </div>        
    )
}
