import React, { useState, useEffect, useRef, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Helpers, Loading } from '../../components';
import { Subjects, Teachers } from '../../services';
import { MDBDataTable } from 'mdbreact';
import ReactPaginate from 'react-paginate';
import BaseSelect from 'react-select';
import { FormattedMessage } from 'react-intl';
import 'react-toastify/dist/ReactToastify.css';
import RequiredSelect from '../../components/RequiredSelect';
import { MaintenanceContext } from '../../Maintenance';

const subjects = new Subjects();
const teachers = new Teachers();
const helpers = new Helpers();

export default function Subject(props) {
    useEffect(() => {
        (async () => getSubjectList())();
        (async () => getTeacherList())();
    }, []);

    const selectInputRef = useRef();
    const user = JSON.parse(window.localStorage.getItem('user-data'));
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

    const [to, setTo] = useState(0);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        id: '',
        name: '',
        is_curricular: false,
        teacher_id: '',
        created_by: user.username,
        updated_by: user.username,
        file: null
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 5,
        search: '',
        total: 0,
        totalPage: 1
    });
    const [subjectList, setSubjectList] = useState([]);
    const [teacherList, setTeacherList] = useState([]);
    const [mode, setMode] = useState('Add');

    const _handleSearch = (v) => {
        if (to) {
          clearTimeout(to);
        }
        setTo(
          setTimeout(() => {
            pagination.search = v;
            setPagination(pagination);
            getSubjectList();
          }, 500)
        );
      };

    const getSubjectList = () => {
        setLoading(true);
        subjects.list({...pagination, is_curricular: true })
        .then(res => {
            console.log(res);
            pagination.limit = res.limit;
            pagination.total = res.total;
            pagination.totalPage = res.total % res.limit === 0 ? (res.total / res.limit) : Math.floor(res.total / res.limit) + 1
            
            res.result.map( (item, i) => {
                let nums = parseInt(pagination.total - pagination.limit);
                let numRows = (pagination.page - 1) * (pagination.total - nums) + i;
                res.result[i].no = numRows + 1;               
                res.result[i].teacher_name = item.teacher.name;
                res.result[i].created_at = helpers.formatDate(item.created_at, 'LLLL');
                res.result[i].option = 
                    <>
                        <button 
                            className="btn btn-warning btn-sm mr-1" 
                            data-toggle="modal" 
                            data-target="#addModal" 
                            onClick={() => getSubjectInfo(item)}> 
                                <i className="lnr lnr-pencil"></i> 
                                <FormattedMessage id="component.edit.button"/>
                        </button>
                        <button 
                            className="btn btn-danger btn-sm" 
                            data-toggle="modal" 
                            data-target="#deleteModal"
                            onClick={() => getSubjectInfo(item)}> 
                                <i className="lnr lnr-trash"></i> 
                                <FormattedMessage id="component.delete.button"/>
                        </button>                       
                    </>
            })

            setSubjectList(res.result);
            setLoading(false);
        })
        .catch(err => {
            console.error(err);

            if (err.code === 404) {
                setSubjectList([]);
                setPagination({
                    page: 0,
                    limit: 0,
                    search: '',
                    total: 0,
                    totalPage: 1
                  });
            }
            if (err.status === 503) {
                setIsMaintenance(true)
            }
            setLoading(false);
        });
    };
    
    const getTeacherList = () => {
        teachers.list({ limit: 100 })
        .then(res => {
            console.log(res.result);   
            res.result.map( (item, i) => {
                res.result[i].value = item.id;
                res.result[i].label = item.name;
            });                         
            setTeacherList(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setTeacherList([]);
            }
        });
    };

    const handlePayload = (e, state) => {
        if (!e) {
            return false;
        }
        let v = '';
        if (state === 'teacher_id') {
            v = e.id;
        } else if (state === 'file') {
            v = e.target.files[0];
        } else if (state === 'is_curricular') {
            v = !payload.is_curricular;
        } else {
            v = e.target.value;
        }
        setPayload({
            ...payload,
            [state]: v
        });

    }

    const createSubject = () => {
        setLoading(true);
        subjects.create(payload)
        .then(res => {
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                setPayload({
                    ...payload
                });

                getSubjectList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        })
        .finally(reset);
    }
    
    const updateSubject = () => {
        setLoading(true);
        subjects.update(payload)
        .then(res => {
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                setPayload({
                    ...payload
                });

                getSubjectList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }
    
    const deleteSubject = () => {
        setLoading(true);
        subjects.delete(payload.id)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                setPayload({
                    ...payload
                });

                getSubjectList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }

    const getSubjectInfo = (subjectInfo) => {
        payload.id = subjectInfo.id;
        payload.teacher_id = subjectInfo.teacher_id;
        payload.name = subjectInfo.name;
        
        setPayload(payload);
        setMode('Edit');
    }
    

    const reset = () => {
        const user = JSON.parse(window.localStorage.getItem('user-data'));
        selectInputRef.current.selectRef.select.clearValue();
        setPayload({
            ...payload,
            id: '',
            name: '',
            is_curricular: false,
            teacher_id: '',
            created_by: user.username,
            updated_by: user.username
        });
    }

    // handle pagination
    const _handleChangePage = e => {
        const { selected } = e;
        pagination.page = selected + 1;
        
        setPagination(pagination);
        getSubjectList();

    }
    const renderModalTitle = mode => {
        switch(mode) {
            case 'Add':
                return <FormattedMessage id="component.add.button"/>
            case 'Edit':
                return <FormattedMessage id="component.edit.button"/>
            default:
                return 'Import'
        }
    }

    const renderModalSubmit = mode => {
        switch(mode) {
            case 'Add':
                createSubject()
                break;
            case 'Edit':
                updateSubject()
                break;
            default:
                importFile()
                break;
        }
    }

    const importFile = () => {
        setLoading(true);
        const formData = new FormData();

        formData.append('file', payload.file);
        formData.append('teacher_id', payload.teacher_id);
        formData.append('created_by', payload.created_by);

        subjects.xxx(formData)
        .then(res => {
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                reset();
                getSubjectList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading(false);
        });
    }
    const data = {
        columns: [
          {
            label: <FormattedMessage id='form.number' defaultMessage='No'/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: <FormattedMessage id="form.subject.name"/>,
            field: 'name',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id="form.teacher.name"/>,
            field: 'teacher_name',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id="form.created_at"/>,
            field: 'created_at',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id="form.options"/>,
            field: 'option',
            sort: 'asc',
            width: 100
          },
        ],
        rows: subjectList || []
    };
    const { totalPage, page } = pagination;

    return (
        <div className="main">
            <ToastContainer
                autoClose={5000}
                hideProgressBar
                pauseOnHover={false} />    
            <div className="main-content">
                {user.role === 'Admin' && (
                    <div className="container-fluid">
                        {ismaintenance ? (
    <div className="text-center">
        <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
        <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
    </div>
)  : (
                            <>
                        <h3 className="page-title">
                            <FormattedMessage id="menu.subject"/>
                        </h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-header mt-2 mr-2">                                    
                                        <div className="row">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">
                                                <button 
                                                    className="btn btn-primary pr-3" 
                                                    data-toggle="modal" 
                                                    data-target="#addModal" 
                                                    style={{float : 'right'}}
                                                    onClick={() => {
                                                        setMode('Add');
                                                        reset();
                                                    }}> 
                                                    <FormattedMessage id="component.add.button"/>
                                                </button>
                                                <button 
                                                    className="btn btn-success mr-2" 
                                                    data-toggle="modal" data-target="#addModal" 
                                                    style={{float : 'right'}}
                                                    onClick={() => setMode('Import')}> Import </button>
                                            </div>
                                        </div>       
                                    </div>
                                    <div className="panel-body">
                                        <Loading visible={loading}/>
                                        <div className="row mt-4">
                                            <div className="col-md-3" style={{ float: "right" }}>
                                                Search:{" "}
                                                <input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => _handleSearch(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <MDBDataTable
                                            searching={false}
                                            className="table mt-5"
                                            data={data}
                                            sortable
                                            noBottomColumns    
                                            entriesOptions={[5, 10, 20, 50]}
                                            displayEntries={true}
                                            paging={false}
                                            noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>}                                                                                                                
                                        />
                                        <ReactPaginate
                                            // className="d-flex justify-content-center"
                                            breakLabel={'...'}
                                            pageCount={totalPage}
                                            marginPagesDisplayed={1}
                                            containerClassName={'pagination'}
                                            activeClassName={'active'}
                                            initialPage={page}
                                            onPageChange={_handleChangePage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </> 
                        )}
                    </div>
                )}
            </div>
            <Modal
                title={renderModalTitle(mode)}
                label={mode}
                form={
                    mode === 'Import'
                    ? 
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <a href={`${process.env.REACT_APP_URL_SERVICE}/static/subjects.csv`} download className="btn btn-success" style={{ float: 'left' }}>
                                    Download template
                                </a>
                            </div>                                
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <RequiredSelect
                                    SelectComponent={BaseSelect}
                                    required
                                    className="mt-2"
                                    value={
                                        teacherList?.length > 0 
                                        ? teacherList.find(item => item.id === payload.teacher_id)
                                        : null
                                    }                                    
                                    placeholder={"Choose the teacher"}
                                    options={teacherList}                                    
                                    onChange={(e) => handlePayload(e, 'teacher_id')}
                                    ref={selectInputRef}
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="fom-label">
                                    <FormattedMessage id="component.text.choose-file"/>
                                </label>
                                <input 
                                    required
                                    type="file" 
                                    className="form-control" 
                                    accept=".csv"
                                    onChange={e => handlePayload(e, 'file')}/>
                            </div>
                        </div>
                    </>
                    :
                    (
                        <>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="required">
                                        <FormattedMessage id="form.subject.name"/>
                                    </label>
                                    <input 
                                        type="text" 
                                        value={payload.name}
                                        className="form-control"
                                        required
                                        onChange={(e) => handlePayload(e, 'name')} />
                                </div>   
                            </div>

                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <label style={{ marginLeft: '-15px'}} className="required">
                                        <FormattedMessage id="form.teacher.name"/>
                                    </label>
                                </div>
                                <div className="form-group">
                                    <RequiredSelect
                                        SelectComponent={BaseSelect}
                                        value={
                                            teacherList?.length > 0 
                                            ? teacherList.find(item => item.id === payload.teacher_id)
                                            : null
                                        }                                 
                                        options={teacherList}                                    
                                        onChange={(e) => handlePayload(e, 'teacher_id')}
                                        required
                                        ref={selectInputRef}
                                    />
                                </div>                      
                            </div>
                            
                            <div className="col-md-6">
                                <div className="custom-control custom-switch">
                                    <input 
                                        type="checkbox"
                                        className="checkbox-preview"
                                        id="customSwitches"
                                        checked={payload.is_curricular}
                                        onClick={(e) => handlePayload(e, 'is_curricular')}
                                    />
                                    <label 
                                        className="checkbox-preview-label" 
                                        htmlFor="customSwitches"
                                        style={{
                                            paddingLeft: 10,
                                            paddingTop: 3
                                        }}
                                    >
                                        Curricular
                                    </label>

                                </div>
                            </div>
                        </>
                    )
                }
                onSubmit={(e) => {e.preventDefault();renderModalSubmit(mode);}}
                onClose={() => reset()}
                onDelete={() => deleteSubject()}
                disabled={loading}
            />
        </div>     

    )
}
