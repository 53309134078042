import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Students, ReportHistory } from '../../services';
import { Loading } from '../../components';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FormattedMessage } from "react-intl";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
  } 
from "@react-pdf/renderer";
import { MaintenanceContext } from '../../Maintenance';


import ReactPDF from '@react-pdf/renderer';
import '../../Preview.css';
const students = new Students();
const reportHistory = new ReportHistory();

const ref = React.createRef();
export default function PersonalityReport(props) {
  const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

    const styles = StyleSheet.create({
        page: {
          backgroundColor: "#ffffff"
        },
        container: {
          backgroundColor: "#f6f6f5",
          display: "flex",
          padding: 3
        },

        table: { 
          display: "block", 
          width: 590, 
          border: "1px solid #ccc", 
          borderWidth: 1, 
        }, 
        tableRowCustom: { 
          margin: "auto", 
          flexDirection: "row",
          backgroundColor:"#ccc",
          
        }, 
        tableColCustom: { 
          width: 295, 
          border: "1px solid white", 
          borderWidth: 1, 
          borderLeftWidth: 0, 
          borderTopWidth: 0, 
          borderBottomWidth: 0, 
          padding:3 
        }, 
        tableCellCustom: { 
          margin: "auto", 
          marginTop: 3, 
          padding:3, 
          fontSize: 8 ,
          fontWeight:800,
          color:'grey'
          
        },
        tableRow: { 
          margin: "auto", 
          flexDirection: "row",
          
        }, 
        tableCol: { 
          width: 295, 
          border: "1px solid #ccc", 
          borderWidth: 1, 
          borderLeftWidth: 0, 
          borderTopWidth: 0, 
          borderBottomWidth: 1, 
          padding:3 
        }, 
        tableCell: { 
          margin: "auto", 
          marginTop: 3, 
          padding:3, 
          fontSize: 8 ,
          color:'grey'
        },
        Label1: { 
          display: "block",
          flexDirection: "row",
        }, 
        SubLabel1: { 
          marginTop:10,
          width:600,
          padding:3 ,
          backgroundColor:'#ccc',
          color:'grey'
        }, 
        NameLabel1: { 
          margin: "auto", 
          padding:3, 
          fontSize: 9 ,
          fontWeight:800,
         
        },
        Label2: { 
          marginTop:5,
          display: "block",
          flexDirection: "row",
        }, 
        SubLabel2: { 
          margin:'auto',
          marginTop:5,
          width:500,
          padding:3 ,
          backgroundColor:'#ccc',
          color:'grey'
        }, 
        NameLabel2: { 
          margin: "auto", 
          padding:3, 
          fontSize: 9 ,
          fontWeight:800,
         
        },
        table2: { 
          margin:'auto',
          marginTop:2,
          display: "block", 
          width: 500, 
          border: "1px solid #ccc", 
          borderWidth: 1, 
        }, 
        tableRowCustom2: { 
          margin: "auto", 
          flexDirection: "row",
          
        }, 
        tableColCustom2: { 
          width: 125, 
          border: "1px solid #ccc", 
          borderWidth: 1, 
          borderLeftWidth: 0, 
          borderTopWidth: 0, 
          borderBottomWidth: 0, 
          padding:3 
        }, 
        tableColCustom3: { 
          width: 250, 
          border: "1px solid #ccc", 
          borderWidth: 1, 
          borderLeftWidth: 0, 
          borderTopWidth: 0, 
          borderBottomWidth: 0, 
          padding:3 
        }, 
        tableCellCustom2: { 
          margin: "auto", 
          marginTop: 2, 
          padding:2, 
          fontSize: 8,
          fontWeight:800,
          color:'#8b8c95'
          
        },
        tableRow2: { 
          margin: "auto", 
          flexDirection: "row",
          
        }, 
        tableCol2: { 
          width: 125, 
          border: "1px solid #ccc", 
          borderWidth: 1, 
          borderLeftWidth: 0, 
          borderBottomWidth: 0, 
          padding:2 
        }, 
        tableCol3: { 
          width: 250, 
          border: "1px solid #ccc", 
          borderWidth: 1, 
          borderLeftWidth: 0, 
          borderBottomWidth: 0, 
          padding:2 
        }, 
        tableCell2: { 
          margin: "auto", 
          marginTop: 2, 
          padding:2, 
          fontSize:8 ,
          color:'#8b8c95'
        },

      });

    const { studentId, term } = useParams();
    const [notAllowed, setNotAllowed] = useState(false);
    
    const [loading, setLoading] = useState(false);
    const [personalityList, setPersonalityList] = useState([]);

    useEffect(() => {      
        (async () => {
          const params = new URLSearchParams(window.location.search)
          const historyID = params.get("history");
          const sessionId = params.get("session");

          if (historyID) {
            getDetailHistoryReport(historyID)
          } else if (sessionId) {
            getHistoryReportBySession(sessionId);
          }else {            
            getStudentReportData();
          }
        })();
    }, []);

    const getDetailHistoryReport = (historyID) => {
      setLoading(true);
      reportHistory.detail(historyID)
      .then(async res => {
          console.log(res);

          const pm = res.result.report_data.personality_marks.filter(item => item.term === parseInt(term));
          setPersonalityList(pm)
          setLoading(false);
      })
      .catch(err => {
          console.error(err);
          setLoading(false);
      })
  }

    const getHistoryReportBySession = (sessionId) => {
      setLoading(true);
      reportHistory
        .studentList(studentId,sessionId)
        .then(async (res) => {
          console.log(res);
          const pm = res.result.report_data.personality_marks.filter(item => item.term === parseInt(term));
          setPersonalityList(pm)
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    };

    const getStudentReportData = () => {
        setLoading(true);
        students.getStudentReportData(studentId)
        .then(async res => {
            console.log(res);
            const pm = res.result.personality_marks.filter(item => item.term === parseInt(term));

            setPersonalityList(pm);
            setLoading(false);
        })
        .catch(err => {
            console.error(err);
            if (err.status === 401) {
              setNotAllowed(true);
          }
            setLoading(false);
        })
    }

    const MyDocument = () => (
      <Document>
        <Page style={styles.container}>
          <View style={styles.table}>
            <View style={styles.tableRowCustom}>
              <View style={styles.tableColCustom}>
                <Text style={styles.tableCellCustom}>
                  Student Personal Quality
                </Text>
              </View>
              <View style={styles.tableColCustom}>
                <Text style={styles.tableCellCustom}>
                  Remark by Class Teacher
                </Text>
              </View>
            </View>
            {personalityList &&
              personalityList.length > 0 &&
              personalityList.map((item, i) => {
                return (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {item.personality.label}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{item.remarks}</Text>
                    </View>
                  </View>
                );
              })}
          </View>

          <View style={styles.label1}>
            <View style={styles.SubLabel1}>
              <Text style={styles.NameLabel1}>
                Remark Options : I Always, II Most of the Time, III Sometimes,
                IV Never/Rarely{" "}
              </Text>
            </View>
          </View>
          <View style={styles.label2}>
            <View style={styles.SubLabel2}>
              <Text style={styles.NameLabel2}>
                The Levels of Performance Indicated by Different Grades{" "}
              </Text>
            </View>
          </View>

          <View style={styles.table2}>
            <View style={styles.tableRowCustom2}>
              <View style={styles.tableColCustom2}>
                <Text style={styles.tableCellCustom2}>GRADE</Text>
              </View>
              <View style={styles.tableColCustom3}>
                <Text style={styles.tableCellCustom2}>PERFORMANCE LEVEL</Text>
              </View>
              <View style={styles.tableColCustom2}>
                <Text style={styles.tableCellCustom2}>%</Text>
              </View>
            </View>
            <View style={styles.tableRow2}>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>A+</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell2}>Excellent</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>90-100</Text>
              </View>
            </View>
            <View style={styles.tableRow2}>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>A</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell2}>Very Good</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>80-89</Text>
              </View>
            </View>
            <View style={styles.tableRow2}>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>B</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell2}>Good</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>70-79</Text>
              </View>
            </View>
            <View style={styles.tableRow2}>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>C</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell2}>Average</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>60-69</Text>
              </View>
            </View>
            <View style={styles.tableRow2}>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>D</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell2}>Fair</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>50-59</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
    return (
      <div className="main">
        <div className="main-content">
          <Loading visible={loading} />
          {!loading && notAllowed && (
            <div className="container">
              {ismaintenance ? (
                <div className="text-center">
                  <img
                    src="assets/img/maintenance.png"
                    className="img-responsive"
                    style={{ width: "400px", margin: "auto" }}
                  />
                  <h1 className="text-center" style={{ marginTop: "-20px" }}>
                    Under Maintenance
                  </h1>
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-12 mt-5">
                      <div className="text-center">
                        <img
                          src="assets/img/no-data.svg"
                          className="img-fluid mb-5"
                          style={{ width: "500px" }}
                        />
                        <h3 className="page-title">
                          <FormattedMessage id="component.text.cannot-print" />
                        </h3>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {!loading && !notAllowed && (
            <div className="container-fluid" ref={ref}>
              {ismaintenance ? (
                <div className="text-center">
                  <img
                    src="assets/img/maintenance.png"
                    className="img-responsive"
                    style={{ width: "400px", margin: "auto" }}
                  />
                  <h1 className="text-center" style={{ marginTop: "-20px" }}>
                    Under Maintenance
                  </h1>
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="row">
                        <PDFDownloadLink
                          document={<MyDocument />}
                          fileName="personality.pdf"
                          style={{
                            textDecoration: "none",
                            padding: "10px",
                            color: "#4a4a4a",
                            backgroundColor: "#f2f2f2",
                            border: "1px solid #4a4a4a",
                          }}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : "Download Pdf"
                          }
                        </PDFDownloadLink>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <table className="table table-bordered">
                            <thead style={{ backgroundColor: "#ccc" }}>
                              <tr>
                                <td className="text-center">
                                  Student Personal Quality
                                </td>
                                <td className="text-center">
                                  Remarks by Class Teacher
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {personalityList &&
                                personalityList.length > 0 &&
                                personalityList.map((item, i) => {
                                  return (
                                    <tr>
                                      <td className="text-center">
                                        {item.personality.label}
                                      </td>
                                      <td className="text-center">
                                        {item.remarks}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-12">
                          <div
                            className="text-center"
                            style={{ backgroundColor: "#ccc", padding: "6px" }}
                          >
                            <span className="text-center">
                              Remark Options : I Always, II Most of the Time,
                              III Sometimes, IV Never/Rarely{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <div
                            className="text-center"
                            style={{ backgroundColor: "#ccc", padding: "6px" }}
                          >
                            <span className="text-center">
                              The Levels of Performance Indicated by Different
                              Grades
                            </span>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <td className="text-center">GRADE</td>
                                <td className="text-center">
                                  PERFORMANCE LEVEL
                                </td>
                                <td className="text-center">%</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center">A+</td>
                                <td className="text-center">Excellent</td>
                                <td className="text-center">90-100</td>
                              </tr>
                              <tr>
                                <td className="text-center">A</td>
                                <td className="text-center">Very Good</td>
                                <td className="text-center">80-89</td>
                              </tr>
                              <tr>
                                <td className="text-center">B</td>
                                <td className="text-center">Good</td>
                                <td className="text-center">70-79</td>
                              </tr>
                              <tr>
                                <td className="text-center">C</td>
                                <td className="text-center">Average</td>
                                <td className="text-center">60-69</td>
                              </tr>
                              <tr>
                                <td className="text-center">D</td>
                                <td className="text-center">Fair</td>
                                <td className="text-center">50-59</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
}

