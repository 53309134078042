import React, { useState, useEffect, useRef, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Helpers, Loading } from '../../components';
import { Students, Classrooms, Religions } from '../../services';
import { MDBDataTable } from 'mdbreact';
import ReactPaginate from 'react-paginate';
import BaseSelect from 'react-select';
import { FormattedMessage } from "react-intl";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import RequiredSelect from '../../components/RequiredSelect';
import { MaintenanceContext } from '../../Maintenance';

const students = new Students();
const classrooms = new Classrooms();
const helpers = new Helpers();
const religions = new Religions();

export default function Student(props) {
    useEffect(() => {
        (async () => getClassroomList())();
        (async () => getStudentList())();
        (async () => getReligionList())();
    }, []);

    const classroomSelect = useRef();
    const religionSelect = useRef();
    const user = JSON.parse(window.localStorage.getItem('user-data'));
    const [loading, setLoading] = useState({
        classroom: false,
        student: false,
        religion: false
    });
    const {ismaintenance, setIsMaintenance} = useContext(MaintenanceContext);

    const [loadingReportView, setLoadingReportView] = useState(false);
    const [allowAllReport, setAllowAllReport] = useState(false);
    const [payload, setPayload] = useState({
        id: '',
        classroom_id: '',
        name: '',
        address: '',
        // new
        roll_no: 0,
        gender: 'M',
        date_of_birth: new Date(),
        telp_mob: '',
        telp_res: '',
        house: '',
        parent_name: '',
        religion: '',
        
        created_by: user.username,
        updated_by: user.username,
        file: null
    });
    
    const [to, setTo] = useState(0);
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 5,
        search: '',
        total: 0,
        totalPage: 1
    });
    const [studentList, setStudentList] = useState([]);
    const [classroomList, setClassroomList] = useState([]);
    const [religionList, setReligionList] = useState([]);
    const [mode, setMode] = useState('Add');    

    const _handleSearch = (v) => {
        if (to) {
          clearTimeout(to);
        }
        setTo(
          setTimeout(() => {
            pagination.search = v;
            setPagination(pagination);
            getStudentList();
          }, 500)
        );
      };

    const getStudentList = () => {
        setLoading({ ...loading, student: true });
        students.list(pagination)
        .then(res => {
            console.log(res);
            pagination.limit = res.limit;
            pagination.total = res.total;
            pagination.totalPage = res.total % res.limit === 0 ? (res.total / res.limit) : Math.floor(res.total / res.limit) + 1

            res.result.map( (item, i) => {
                let nums = parseInt(pagination.total - pagination.limit);
                let numRows = (pagination.page - 1) * (pagination.total - nums) + i;

                res.result[i].no = numRows + 1;                
                res.result[i].classroom_name = item.classroom.name;
                res.result[i].date_of_birth = helpers.formatDate(item.date_of_birth, 'LL');
                res.result[i].religion_name = item.religion.name;                
                res.result[i].created_at = helpers.formatDate(item.created_at);
                res.result[i].option = 
                    <>
                        <button 
                            className="btn btn-warning btn-sm mr-1 mb-1" 
                            data-toggle="modal" 
                            data-target="#addModal" 
                            onClick={() => getStudentInfo(item)}> 
                                <i className="lnr lnr-pencil"></i> 
                                <FormattedMessage id="component.edit.button"/>
                        </button>
                        <button 
                            className="btn btn-danger btn-sm mb-1" 
                            data-toggle="modal" 
                            data-target="#deleteModal"
                            onClick={() => getStudentInfo(item)}> 
                                <i className="lnr lnr-trash"></i>
                                <FormattedMessage id="component.delete.button"/>
                        </button>                         
                    </>
                res.result[i].allow_report = 
                    <>
                        <input type="checkbox" checked={item.allow_report_view ? true : false} onClick={e => allowReportView(item.id, !item.allow_report_view)}/>
                    </>
            });

            setPagination(pagination);
            setStudentList(res.result);
            setLoading({ ...loading, student: false });
        })
        .catch(err => {
            console.log('err: ', err)
            console.error(err);

            if (err.code === 404) {
                setStudentList([]);
                setPagination({
                    page: 0,
                    limit: 0,
                    search: '',
                    total: 0,
                    totalPage: 1
                })
            }

            if (err.status === 503) {
                setIsMaintenance(true)
            }
            setLoading({ ...loading, student: false });
        });
    };
    
    const getClassroomList = () => {
        setLoading({ ...loading, classroom: true });

        classrooms.list({ limit: 100 })
        .then(res => {
            console.log('class: ', res.result);  
            res.result.map( (item, i) => {
                res.result[i].value = item.id;
                res.result[i].label = item.name;
            })     
            setClassroomList(res.result);
            setLoading({ ...loading, classroom: false });
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setClassroomList([]);
            }
            setLoading({ ...loading, classroom: false });
        });
    };

    const getReligionList = () => {
        setLoading({ ...loading, religion: true });

        religions.list({ limit: 100 })
        .then(res => {
            console.log('religions: ', res.result);  
            res.result.map( (item, i) => {
                res.result[i].value = item.id;
                res.result[i].label = item.name;
            })     
            setReligionList(res.result);
            setLoading({ ...loading, religion: false });
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setReligionList([]);
            }
            setLoading({ ...loading, religion: false });
        });
    };

    const handlePayload = (e, state) => {
        if (!e) {
            return false;
        }
        let v = '';
        if (state === 'classroom_id' || state === 'religion') {
            v = e.id;
        } else if (state === 'file') {
            v = e.target.files[0];
        } else if (state === 'date_of_birth') {
            v = moment(e._d).format('YYYY-MM-DD');
        } else {
            v = e.target.value;
        }
        setPayload({
            ...payload,
            [state]: v
        });

    }

    const createStudent = () => {
        setLoading({ ...loading, student: true });
        students.create(payload)
        .then(async res => {
            if (res.code === 201) {
                
                // classroomSelect.current.select.clearValue();
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                reset();
                getStudentList();
                console.log('payload', payload);
                // console.log(classroomList.find(item => item.id === payload.classroom_id))
                setLoading({ ...loading, student: false });
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.add-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading({ ...loading, student: false });
        });
    }
    
    const updateStudent = () => {
        setLoading({ ...loading, student: true });
        students.update(payload)
        .then(res => {
            if (res.status.code === 200) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);

                reset();

                getStudentList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading({ ...loading, student: false });
        });
    }
    
    const deleteStudent = () => {
        setLoading({ ...loading, student: true });
        students.delete(payload.id)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                document.getElementById('deleteModal').click();
                toast.success(<FormattedMessage id='component.text.delete-successful'/>);

                setPayload({
                    ...payload
                });

                getStudentList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.delete-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading({ ...loading, student: false });
        });
    }

    const getStudentInfo = (studentInfo) => {      
        payload.id = studentInfo.id;
        payload.classroom_id = studentInfo.classroom.id;
        payload.name = studentInfo.name;
        payload.parent_name = studentInfo.parent_name;
        payload.address =  studentInfo.address;
        payload.updated_by = studentInfo.created_by;
        payload.roll_no = studentInfo.roll_no;
        payload.gender = studentInfo.gender;
        payload.date_of_birth = studentInfo.date_of_birth;
        payload.telp_mob = studentInfo.telp_mob;
        payload.telp_res = studentInfo.telp_res;
        payload.house = studentInfo.house;
        payload.religion = studentInfo.religion;
        
        setPayload(payload);
        setMode('Edit');
    }
    
    const reset = () => {
        payload.id = '';
        payload.classroom_id = '';
        payload.name = '';
        payload.address = '';
        payload.roll_no = 0;
        payload.gender = '';
        payload.date_of_birth = '';
        payload.telp_mob = '';
        payload.telp_res = '';
        payload.house = '';
        payload.religion = '';
        payload.file = null;

        setPayload({ ...payload });
    }

     // handle pagination
     const _handleChangePage = e => {
        const { selected } = e;
        pagination.page = selected + 1;
        
        setPagination(pagination);
        getStudentList();

    }

    const renderModalTitle = mode => {
        switch(mode) {
            case 'Add':
                return <FormattedMessage id="component.add.button"/>;
            case 'Edit':
                return <FormattedMessage id="component.edit.button"/>
            case 'Access':
                return 'Access'
            default:
                return 'Import'
        }
    }

    const renderModalSubmit = mode => {
        switch(mode) {
            case 'Add':
                createStudent()
                break;
            case 'Edit':
                updateStudent()
                break;
            case 'Access':
                allowReportView()
                break;
            default:
                importFile()
                break;
        }
    }

    const importFile = () => {
        setLoading({ ...loading, student: true });
        const formData = new FormData();

        formData.append('file', payload.file);
        formData.append('classroom_id', payload.classroom_id);
        formData.append('religion', payload.religion);
        formData.append('created_by', payload.created_by);

        students.xxx(formData)
        .then(res => {
            if (res.code === 201) {
                document.getElementById('addModal').click();
                toast.success(<FormattedMessage id='component.text.add-successful'/>);

                reset();
                getStudentList();
            }
        })
        .catch(err => {
            toast.error(<div>{<FormattedMessage id='component.text.edit-failed'/>}<br/><br/>{JSON.stringify(err)}</div>)
            setLoading({ ...loading, student: false });
        });
    }    

    const allowReportView = (studentId, checked) => {
        setLoadingReportView(true);
        const payload = {
            allow_report_view: checked,
            updated_by: user.username
        }
        students.allowReportView(studentId, payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);
                getStudentList();
                setLoadingReportView(false);
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<FormattedMessage id='component.text.edit-failed'/>);

            setLoadingReportView(false);
        })
    }

    const allowAllStudentReportView = (checked) => {
        setAllowAllReport(checked);
        setLoadingReportView(true);
        const payload = {
            allow_report_view: checked,
            updated_by: user.username
        }
        students.allowAllReportView(payload)
        .then(res => {
            console.log(res);
            if (res.status.code === 200) {
                toast.success(<FormattedMessage id='component.text.edit-successful'/>);
                getStudentList();
                setLoadingReportView(false);
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(<FormattedMessage id='component.text.edit-failed'/>);

            setLoadingReportView(false);
        })
    };
    const data = {
        columns: [
          {
            label: <FormattedMessage id='form.number' defaultMessage='No'/>,
            field: 'no',
            sort: 'asc',
            width: 150
          },
          {
            label: <FormattedMessage id='form.student.name'/>,
            field: 'name',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id='form.student.address'/>,
            field: 'address',
            sort: 'asc',
            width: 270
          },
          {
            label: <FormattedMessage id='form.classroom.name'/>,
            field: 'classroom_name',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id='form.religion.name'/>,
            field: 'religion_name',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id='form.classroom.code' defaultMessage="Student Code"/>,
            field: 'code',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id='form.parent.code' defaultMessage="Parent Code"/>,
            field: 'parent_code',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id='form.parent.name' defaultMessage="Parent Name"/>,
            field: 'parent_name',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id="form.teacher.date_of_birth"/>,
            field: 'date_of_birth',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id='form.created_at'/>,
            field: 'created_at',
            sort: 'asc',
            width: 200
          },
          {
            label: <FormattedMessage id='form.options'/>,
            field: 'option',
            sort: 'asc',
            width: 100
          },
          {
            label:
                <div className='row'>
                    <div className='col-md-6'>                
                        <FormattedMessage id='form.allow-all' defaultMessage="Allow All"/>
                        <input type="checkbox" onClick={(e) => allowAllStudentReportView(!allowAllReport)}/>
                    </div>
                </div>,
            field: 'allow_report',
            sort: 'asc',
            width: 100
          },
        ],
        rows: studentList || []
    };

    const { totalPage, page } = pagination;
    return (
        <div className="main">
            <ToastContainer
                autoClose={5000}
                hideProgressBar
                pauseOnHover={false} />
            <div className="main-content">
                {user.role === 'Admin' && (

                    <div className="container-fluid">
                        {ismaintenance ? (
    <div className="text-center">
        <img src="assets/img/maintenance.png" className="img-responsive" style={{width:'400px', margin: 'auto'}}/>
        <h1 className="text-center" style={{marginTop:'-20px'}}>Under Maintenance</h1>
    </div>
)  : (
                         <>
                        <h3 className="page-title">
                            <FormattedMessage id="menu.student"/>
                        </h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-header mt-2 mr-2">
                                        <div className="row">
                                            <div className="col-md-6"></div>                                        
                                            <div className="col-md-6">
                                                <button 
                                                    className="btn btn-primary pr-3" 
                                                    data-toggle="modal" data-target="#addModal" 
                                                    style={{float : 'right'}}
                                                    onClick={() => setMode('Add')}> 
                                                        <FormattedMessage id="component.add.button"/>
                                                </button>
                                                <button 
                                                    className="btn btn-success mr-2" 
                                                    data-toggle="modal" data-target="#addModal" 
                                                    style={{float : 'right'}}
                                                    onClick={() => setMode('Import')}> Import </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-body">                             
                                        <Loading visible={loading.student} />
                                        <div className="row mt-4">
                                            <div className="col-md-3" style={{ float: "right" }}>
                                                Search:{" "}
                                                <input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => _handleSearch(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <MDBDataTable
                                            searching={false}
                                            className="table mt-5"
                                            data={data}
                                            sortable
                                            noBottomColumns    
                                            entriesOptions={[5, 10, 20, 50]}
                                            displayEntries={true}
                                            paging={false}
                                            noRecordsFoundLabel={<FormattedMessage id="component.text.no-records"/>}                                                                                                                
                                        />
                                        <ReactPaginate
                                            // className="d-flex justify-content-center"
                                            breakLabel={'...'}
                                            pageCount={totalPage}
                                            marginPagesDisplayed={1}
                                            containerClassName={'pagination'}
                                            activeClassName={'active'}
                                            initialPage={page}
                                            onPageChange={_handleChangePage}
                                        />
                                    </div>
                                </div>   
                            </div>
                        </div>
                        </> 
                        )}
                    </div>
                )}
            </div>
            <Modal
                title={renderModalTitle(mode)}
                label={mode}
                form={
                    (                        
                        mode === 'Import'
                        ? 
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <a href={`${process.env.REACT_APP_URL_SERVICE}/static/students.csv`} download className="btn btn-success" style={{ float: 'left' }}>
                                        Download template
                                    </a>
                                </div>                                
                            </div>
                            <div className="row">

                                <div className="col-md-12 mt-2">
                                    <label className="required">
                                        <label><FormattedMessage id="form.classroom.name"/></label>
                                    </label>
                                </div>
                                <div className="col-md-12">
                                    <RequiredSelect
                                        SelectComponent={BaseSelect}
                                        required
                                        value={
                                            classroomList?.length > 0 
                                            ? classroomList.find(item => item.id === payload.classroom_id) || null
                                            : null
                                        }                                   
                                        options={classroomList}                                    
                                        onChange={(e) => handlePayload(e, 'classroom_id')}
                                        ref={classroomSelect}
                                    />
                                </div>
                                
                                <div className="col-md-12">
                                    <label className="required">
                                        <FormattedMessage id="form.religion.name"/>
                                    </label>
                                </div>
                                <div className="col-md-12">
                                    <RequiredSelect
                                        SelectComponent={BaseSelect}
                                        required
                                        value={ 
                                            religionList?.length > 0 
                                            ? religionList.find(item => item.id === payload.religion)
                                            : null
                                        }                     
                                        options={religionList}                                    
                                        onChange={(e) => handlePayload(e, 'religion')}
                                        ref={religionSelect}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="fom-label">
                                        <FormattedMessage id="component.text.choose-file"/>
                                    </label>
                                    <input 
                                        required
                                        type="file" 
                                        className="form-control" 
                                        accept=".csv"
                                        onChange={e => handlePayload(e, 'file')}/>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="required"><FormattedMessage id="form.student.name"/></label>
                                    <input 
                                        required
                                        type="text" 
                                        value={payload.name}
                                        className="form-control" 
                                        onChange={(e) => handlePayload(e, 'name')} />
                                </div>   
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="required"><FormattedMessage id="form.student.address"/></label>
                                    <input
                                        required
                                        type="text" 
                                        value={payload.address}
                                        className="form-control required" 
                                        onChange={(e) => handlePayload(e, 'address')} />
                                </div>   
                            </div>

                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <label style={{ marginLeft: '-15px'}} className="required">
                                        <FormattedMessage id="form.student.classroom"/>
                                    </label>
                                </div>
                                <div className="form-group">
                                    <RequiredSelect
                                        SelectComponent={BaseSelect}
                                        required
                                        value={
                                            mode === 'Add' && !payload.classroom_id
                                            ? null
                                            :
                                                classroomList?.length > 0 
                                                ? classroomList.find(item => item.id === payload.classroom_id)
                                                : null
                                        }                     
                                        options={classroomList}                                    
                                        onChange={(e) => handlePayload(e, 'classroom_id')}
                                        ref={classroomSelect}
                                    />
                                </div>                           
                            </div>


                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <label style={{ marginLeft: '-15px'}} className="required">
                                        <FormattedMessage id="form.religion.name"/>
                                    </label>
                                </div>
                                <div className="form-group">
                                    <RequiredSelect
                                        SelectComponent={BaseSelect}
                                        required
                                        value={
                                            mode === 'Add' && !payload.religion
                                            ? null
                                            :
                                                religionList?.length > 0 
                                                ? religionList.find(item => item.id === payload.religion)
                                                : null
                                        }                     
                                        options={religionList}                                    
                                        onChange={(e) => handlePayload(e, 'religion')}
                                    />
                                </div>                           
                            </div>

                            <div className="col-md-6">
                                <div className="col-md-6">
                                    <label style={{ marginLeft: '-15px'}}>
                                        <FormattedMessage id="form.student.roll_no"/>
                                    </label>
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        value={payload.roll_no}
                                        className="form-control"
                                        onChange={(e) => handlePayload(e, 'roll_no')}
                                    />
                                </div>                           
                            </div>

                            <div className="col-md-6">
                                <div className="col-md-6">
                                    <label style={{ marginLeft: '-15px'}}>
                                        <FormattedMessage id="form.student.gender" className="required"/>
                                    </label>
                                </div>
                                <div className="col-md-12">
                                    <input 
                                        type="radio"
                                        value="M"
                                        checked={payload.gender === 'M'}
                                        onChange={(e) => handlePayload(e, 'gender')}
                                    /> <FormattedMessage id="form.student.gender.male"/>
                                    <input 
                                        style={{ marginLeft: 10 }}
                                        type="radio"
                                        value="F"
                                        checked={payload.gender === 'F'}
                                        onChange={(e) => handlePayload(e, 'gender')}
                                    /> <FormattedMessage id="form.student.gender.female"/>
                                </div>                           
                            </div>
                            
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="required"><FormattedMessage id="form.teacher.date_of_birth" /></label>
                                    <MuiPickersUtilsProvider 
                                        utils={MomentUtils}
                                        libInstance={moment} 
                                        className="form-control">

                                        <DatePicker
                                            required
                                            fullWidth
                                            disableFuture={true}
                                            clearable={true}
                                            inputVariant="outlined"
                                            value={payload.date_of_birth}
                                            autoOk={true}
                                            format="Do MMM yyyy"
                                            onChange={e => handlePayload(e, 'date_of_birth')}
                                        />
                                    </MuiPickersUtilsProvider>                                                            
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="col-md-6">
                                    <label style={{ marginLeft: '-15px'}}>
                                        <FormattedMessage id="form.student.telp_mob"/>
                                    </label>
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        value={payload.telp_mob}
                                        className="form-control"
                                        onChange={(e) => handlePayload(e, 'telp_mob')}
                                    />
                                </div>                           
                            </div>

                            <div className="col-md-6">
                                <div className="col-md-6">
                                    <label style={{ marginLeft: '-15px'}}>
                                        <FormattedMessage id="form.student.telp_res"/>
                                    </label>
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        value={payload.telp_res}
                                        className="form-control"
                                        onChange={(e) => handlePayload(e, 'telp_res')}
                                    />
                                </div>                           
                            </div>

                            <div className="col-md-12">
                                <div className="col-md-6">
                                    <label style={{ marginLeft: '-15px'}}>
                                        <FormattedMessage id="form.parent.name"/>
                                    </label>
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        value={payload.parent_name}
                                        className="form-control"
                                        onChange={(e) => handlePayload(e, 'parent_name')}
                                    />
                                </div>                           
                            </div>
                            
                            <div className="col-md-12">
                                <div className="col-md-6">
                                    <label style={{ marginLeft: '-15px'}}>
                                        <FormattedMessage id="form.student.house"/>
                                    </label>
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        value={payload.house}
                                        className="form-control"
                                        onChange={(e) => handlePayload(e, 'house')}
                                    />
                                </div>                           
                            </div>
                        </>
                    )
                }
                onSubmit={(e) => {e.preventDefault();renderModalSubmit(mode);}}
                onClose={() => reset()}
                onDelete={() => deleteStudent()}
                disabled={mode === 'Access' ? loadingReportView :  loading.student}
            />
        </div>
    )
}
